export default {
  popper: {
    position: "absolute",
    zIndex: 1201,
  },
  paper: ({ borders }) => ({
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    width: "100%",
    minWidth: "250px",
    maxWidth: "350px",
    borderRadius: borders.borderRadius.xl,
  }),
  topItemContainer: ({ palette, borders }) => ({
    borderTopLeftRadius: borders.borderRadius.xl,
    borderTopRightRadius: borders.borderRadius.xl,
    display: "flex",
    justifyContent: "start",
    px: 1.5,
    py: 3,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
    color: "#FFF",
  }),
  itemContainer: ({ palette }) => ({
    display: "flex",
    justifyContent: "start",
    px: 1.5,
    py: 3,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
    color: "#FFF",
  }),
  item: () => ({
    alignCenter: "left",
    color: "#000",
    textAlign: "start",
    marginLeft: "10px",
  }),
  bottomItemContainer: ({ palette, borders }) => ({
    borderBottomLeftRadius: borders.borderRadius.xl,
    borderBottomRightRadius: borders.borderRadius.xl,
    display: "flex",
    justifyContent: "start",
    px: 1.5,
    py: 3,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
    color: "#FFF",
  }),
  itemErrorColor: ({ palette }) => ({
    color: palette.error.light,
    marginLeft: "10px",
  }),
  divider: ({ palette }) => ({
    my: 0,
    py: 0,
    backgroundColor: palette.grey[300],
  }),
};
