export default {
  notification: ({ isAddFriend, isBlockFriend }) => ({
    with: "100%",
    // eslint-disable-next-line no-nested-ternary
    background: isAddFriend
      ? "linear-gradient(90deg, #009ACB 0%, #3176DE 100%)"
      : isBlockFriend
      ? "linear-gradient(90deg, #920000 0%, #DE3131 100%)"
      : "",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    py: 1,
    px: { xs: 2, sm: 3 },
  }),
  boxAdd1: {
    display: "flex",
    opacity: 0.8,
    mr: 1.5,
  },
  nameAdd: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    color: "#ffff",
    fontWeight: typography.fontWeightBold,
  }),
  title: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    color: "#ffff",
    ml: 0.5,
    fontWeight: typography.fontWeightRegular,
  }),
  buttonAdd: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    color: "#ffff",
    flexShrink: 0,
    fontWeight: 600,
    fontSize: "13.5px",
    borderRadius: 6,
    border: "1px solid #ffff",
    "&:hover": {
      borderColor: "#ffff",
    },
  }),
};
