import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
} from "@mui/material";
import styles from "assets/styles/pages/Chat/component/ConfirmLeaveAndDeleteChatDialog";
import { useTranslation } from "react-i18next";

function ConfirmLeaveAndDeleteChatDialog({ open, handleDeleteChat, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth sx={styles.dialog}>
      <Box sx={{ textAlign: "center", marginTop: "10px", mb: 1 }}>
        <Typography variant="h5" sx={styles.titleText}>
          {t("remove_group.notification")}
        </Typography>
      </Box>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          <Typography variant="h7" sx={{ color: "#000", marginLeft: "20px", marginRight: "20px" }}>
            {t("remove_group.are_you_sure_you_want_to_leave_and_delete_this_conversation")}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginBottom: "20px" }}>
        <Box
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "25px",
            marginBottom: "10px",
          }}
        >
          <Button size="medium" sx={styles.buttonCancel} onClick={handleClose}>
            {t("remove_group.cancel")}
          </Button>
          <Button
            size="medium"
            sx={styles.buttonConfirm}
            onClick={() => {
              handleDeleteChat();
            }}
          >
            {t("remove_group.confirm")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmLeaveAndDeleteChatDialog;
