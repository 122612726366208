import { SEND_OTP_TOKEN } from "config/keys";
import axiosInstance from "middleware/axios";

const sendOTP = (params) =>
  // axiosInstance.defaults.headers.Authorization = SEND_OTP_TOKEN;
  axiosInstance.get("/api/other_services/get_otp_verified", {
    params,
    headers: { token: SEND_OTP_TOKEN },
  });
const verifyOTP = (params) =>
  axiosInstance.get("/api/other_services/otp_verified", {
    params,
    headers: { token: SEND_OTP_TOKEN },
  });

const getUserInformation = (params) => axiosInstance.get("/api/account/token_demo", { params });

export { sendOTP, verifyOTP, getUserInformation };
