import {
  informationWidth,
  lgInformationWidth,
  xlInformationWidth,
  xxlInformationWidth,
} from "constant/chat";

export default {
  drawer: ({ transitions, breakpoints }, { isOpenMultimediaDialog }) => ({
    ...(!isOpenMultimediaDialog && {
      width: 0,
      "& .MuiDrawer-paper": {
        width: 0,
      },
      transition: transitions.create("width", {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
    }),

    ...(isOpenMultimediaDialog && {
      width: informationWidth,
      "& .MuiDrawer-paper": {
        width: informationWidth,
      },
      transition: transitions.create(["margin", "width"], {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
      [breakpoints.down("xxl")]: {
        width: xxlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xxlInformationWidth,
        },
      },
      [breakpoints.down("xl")]: {
        width: xlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xlInformationWidth,
        },
      },
      [breakpoints.down("lg")]: {
        width: lgInformationWidth,
        "& .MuiDrawer-paper": {
          width: lgInformationWidth,
        },
      },
      [breakpoints.down("md")]: {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    }),
    height: "100%",
  }),
  dialog: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "100%",
        // maxHeight: "600px",
        maxWidth: "450px",
        [breakpoints.down("lg")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  dFlexNotJust: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
  },
  dFlexNotJust1: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: "45px",
    // marginTop: "5px",
  },
  boxUser: {
    // justifyContent: "space-between",
    "&:hover": {
      background: "#E5F0FF",
      borderRadius: "10px",
    },
  },
  boxUserSelect: {
    // justifyContent: "space-between",
    background: "#E5F0FF",
    borderRadius: "10px",
  },
  fLexCol: { display: "flex", flexDirection: "column", height: "73px" },
  arrowIcon: { fill: "#090710", height: "14px" },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    m: 2,
  },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    marginLeft: "auto",
    marginRight: "auto",
  }),
  overflowY: ({ breakpoints }) => ({
    pl: 0.5,
    ml: 2,
    marginRight: "15px",
    [breakpoints.between("md", "lg")]: {
      ml: 0,
    },
    [breakpoints.down("sm")]: {
      ml: 0,
    },
  }),
  avatarImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    "&>img": {
      height: "44px",
    },
    border: "0.1px solid lightgray",
  },
  divider: {
    my: 0,
    backgroundColor: "#707070",
  },
  contentContainer: {
    height: "500px",
    maxHeight: "80%",
    minHeight: "20%",
    flexGrow: 1,
    display: "flex",
    borderRadius: 0,
    overflowY: "auto",
    // mb: 2,
    // ml: 2,
    mt: 1,
    flexDirection: "column",
  },
  tag: ({ borders }) => ({
    flexGrow: "0",
    flexShrink: "0",
    borderRadius: borders.borderRadius.round,
    padding: "4px 0px",
    display: "none",
  }),
  fullWidth: {
    width: "100%",
    height: "100%",
    overflow: "scroll",
  },
  mediaTab: ({ palette }) => ({
    p: 0,
    width: "100%",
    backgroundColor: palette.background.paper,
  }),
  tabList: ({ palette }) => ({
    p: 0,
    backgroundColor: palette.background.paper,
    borderRadius: 0,
    borderBottom: 1,
    borderColor: "#DDDDDD",
    "& .MuiButtonBase-root": {
      borderRadius: 0,
    },
    "& .MuiTabs-indicator": {
      borderRadius: 0,
      boxShadow: "none",
      borderBottom: "2px solid #406EE2",
      "&:hover": {
        background: "#406EE2",
      },
    },
  }),
  textMedia: ({ palette, typography }, { isBlack, isBold }) => ({
    fontFamily: typography.fontFamily2,
    background: isBlack ? palette.black.main : "#406EE2",
    color: "#3D3D3D",
    fontSize: "15px",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: isBold ? typography.fontWeightBold : typography.fontWeightRegular,
  }),
  tab: {
    px: 1.5,
    pt: 2,
    pb: 1.5,
  },
  tabPanel: {
    p: 1,
  },
  imgMedia: () => ({
    cursor: "pointer",
    width: "50%",
    objectFit: "cover",
    height: "80px",
  }),
  imgMediaVideo: {
    cursor: "pointer",
    width: "85px",
    objectFit: "cover",
    height: "85px",
    background: "black",
    borderRadius: "15px",
    marginLeft: "15px",
    border: "0.01px solid #D4D4D4",
  },
  imgPlayVideo: {
    width: "40px",
    height: "40px",
    position: "absolute",
    cursor: "pointer",
    objectFit: "cover",
  },
  nameFile: ({ typography }) => ({
    pt: 0.25,
    fontSize: "14px",
    fontFamily: typography.fontFamily2,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    wordBreak: "break-all",
  }),
  vga: ({ typography }) => ({
    fontFamily: typography.fontFamily2,
    pb: 1.5,
    color: "#000",
    fontSize: "12px",
  }),
  itemContainerNotification: {
    display: "flex",
    pl: 2,
    pr: 1,
    height: "56px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
  },
  iconContainerLinkMessage: {
    width: "80px",
    flexShrink: 0,
  },
  informationContainer: {
    ml: 2.5,
  },
  messageLinkImage: {
    width: "100%",
    maxHeight: "25vh",
  },
  linkNoImageMessage: {
    width: "80px",
    flexShrink: 0,
    height: "40px",
    background: "#d9d9d9",
  },
  iconContainer: {
    width: "33px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexShrink: 0,
  },
  itemContainer: {
    display: "flex",
    px: 2,
    justifyContent: "flex-start",
    height: "56px",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
    width: "100%",
  },
  nameLinkMessage: ({ typography }) => ({
    pt: 0.25,
    fontFamily: typography.fontFamily2,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    overflow: "hidden",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: "14px",
  }),
};
