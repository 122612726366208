import colors from "assets/theme/base/colors";
import { chatWidth, lgChatWidth, xlChatWidth, xxlChatWidth } from "constant/chat";

const { initial } = colors;
export default {
  ScoreBox: ({ borders }) => ({
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.xl,
        overflow: "hidden",
      },
    },
    height: "100%",
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
  }),
  paper: {
    boxShadow: "none",
  },
  dFlex: {
    display: "flex",
  },
  selectMulti: { padding: "0px 17px 10px", display: "flex", alignItems: "center" },
  flexGrow: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },

  fillColorIconLeft: {
    fill: "#00ABA7",
  },

  popperPoll: {
    height: "470px",
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.15)",
  },

  popper: ({ breakpoints }) => ({
    zIndex: "9999",
    width: "100%",
    [breakpoints.up("md")]: {
      maxWidth: `calc(100% - ${lgChatWidth})`,
    },
    [breakpoints.up("sm")]: {
      width: "100%",
    },
    [breakpoints.up("xxl")]: {
      maxWidth: `calc(100% - ${chatWidth})`,
    },
    [breakpoints.up("xl")]: {
      maxWidth: `calc(100% - ${xxlChatWidth})`,
    },
    [breakpoints.up("lg")]: {
      maxWidth: `calc(100% - ${xlChatWidth})`,
    },
  }),

  boxHeader: {
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #F0F0F0",
  },
  boxTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  textTitle: ({ typography }) => ({
    flexGrow: 1,
    py: 2.5,
    fontFamily: typography.fontFamily2,
    fontWeight: "700",
    textAlign: "center",
    fontSize: "26px",
  }),

  icon: { width: "24px", height: "24px" },

  textQuestion: ({ typography }) => ({
    paddingTop: "20px",
    paddingLeft: "20px",
    fontWeight: 700,
    fontSize: "16px",
    width: "100%",
    input: {
      fontFamily: `${typography.fontFamily2} !important`,
      fontSize: "16px",
      marginBottom: "20px",
      color: "#000",
      fontWeight: 500,
    },
    "& .MuiInput-root": {
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottom: 0,
      },
    },
  }),

  textColorBlue: {
    color: "#406EE2",
    fontSize: "12px",
  },

  InputAdornment: ({ typography }) => ({
    marginBottom: "20px",
    fontSize: "16px",
    fontFamily: typography.fontFamily2,
  }),

  boxAnswer: {
    paddingLeft: "17px",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    justifyContent: "start",
    maxHeight: "150px",
    height: "150px",
  },

  inputAnswer: ({ clickBorderText, boxIndex, typography }) => ({
    width: "100%",
    marginRight: "15px",
    border: clickBorderText === boxIndex ? "1px solid var(--button_new, #406EE2)" : "none", // Sửa: So sánh với boxIndex
    "& .MuiInput-root": {
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottom: 0,
      },
    },
    "&:focus": {
      border: clickBorderText === boxIndex ? "1px solid var(--button_new, #406EE2)" : "none",
    },
    input: {
      fontFamily: typography?.fontFamily2,
      fontSize: "16px",
      color: "#000",
      fontWeight: 500,
    },
    borderRadius: "10px",
    padding: "8px 12px",
    fontSize: "16px",
    marginBottom: "10px",
    boxSizing: "border-box",
    backgroundColor: clickBorderText === boxIndex ? "none" : "#F6F6F6",
  }),
  dragIcon: {
    justifyContent: "end",
    pt: "20px",
    position: "relative",
    top: "-18px",
    marginRight: "17px",
  },

  addAnswerIcon: { justifyContent: "start", pt: "0px", color: "#17479E" },

  dFlexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    bottom: 0, // Place the element at the bottom of the viewport
    left: 0,
  },

  buttonCreatePollDefault: {
    mb: "10px",
    backgroundColor: "#c8d3f3",
    borderRadius: "10px",
    border: "none",
    pointerEvents: "none",
    minWidth: "100px",
    maxWidth: "170px",
    height: "50px",
    margin: "30px auto",
    fontWeight: 700,
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
  },

  buttonCreatePollActive: {
    borderRadius: "10px",
    background: "#406EE2",
    minWidth: "100px",
    maxWidth: "170px",
    height: "50px",
    margin: "30px auto",
    fontWeight: 700,
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    "&:disabled": {
      backgroundColor: "#c8d3f3",
      color: "#FFFF",
    },
    "&:hover": {
      background: "#406EE2",
      boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    },
  },

  textButtonDefault: ({ typography }) => ({
    fontFamily: `${typography.fontFamily2} !important`,
    color: "#ffff",
  }),

  textButtonActive: ({ palette, typography }) => ({
    fontFamily: `${typography.fontFamily2} !important`,
    color: palette.white.main,
  }),

  textColorBlack: { color: initial.main },
};
