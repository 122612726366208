import { Button, Dialog, DialogContent, Box, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/NotificationPhoneBook";
import { useTranslation } from "react-i18next";

function NotificationPhoneBook({ open, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth sx={styles.dialog}>
      <Typography variant="h5" sx={styles.titleText}>
        {t("remove_group.notification")}
      </Typography>

      <DialogContent sx={{ textAlign: "center", marginTop: "10px", mb: 1 }}>
        <Typography sx={{ color: "#000", marginLeft: "auto", marginRight: "auto" }} variant="h7">
          {t("notification.account")}
        </Typography>
      </DialogContent>
      <Box sx={{ textAlign: "center", marginTop: "10px", mb: 3 }}>
        <Button size="medium" sx={styles.buttonConfirm} onClick={handleClose}>
          {t("notification.close")}
        </Button>
      </Box>
    </Dialog>
  );
}

export default NotificationPhoneBook;
