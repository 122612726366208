const numberWithCommas = (x) => {
  // eslint-disable-next-line no-param-reassign
  x = x.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) {
    // eslint-disable-next-line no-param-reassign
    x = x.replace(pattern, "$1,$2");
  }
  return x;
};

export { numberWithCommas };
