import { Route } from "react-router-dom";

const generateRoutes = (routes) =>
  routes.map((route) => {
    if (route.collapse) return generateRoutes(route.collapse);
    if (route.route)
      return (
        <Route path={route.route} element={route.component} key={route.route} exact={route.exact} />
      );
    return null;
  });

export { generateRoutes };
