export const toHex = (char = "") => char.charCodeAt(0).toString(16);

export const encodeID = (str = "") => str.toString().split("").map(toHex).join("");

export const decodeID = (hex = "") => {
  const result = [];
  for (let i = 0; i < hex.length; i += 2) {
    result.push(String.fromCharCode(parseInt(hex.substr(i, 2), 16)));
  }
  return result.join("");
};
