import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "assets/styles/pages/Chat/component/PinMessageDialog";
import List from "@mui/material/List";
import VideoPoster from "assets/images/video_poster.png";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconCLose } from "assets/svg/icon/icon_close.svg";
import { ReactComponent as DragIcon } from "assets/svg/icon/drag_icon.svg";
import { ReactComponent as MessagePin } from "assets/svg/icon/pin_message.svg";
import { ReactComponent as PinIcon } from "assets/svg/icon/pin_icon.svg";
import { ReactComponent as EditPinIcon } from "assets/svg/icon/edit_pin.svg";
import { ReactComponent as AudioIcon } from "assets/svg/icon/audio_icon.svg";
import { ReactComponent as AttachFileActiveIcon } from "assets/svg/icon/attach_icon_active.svg";
import { ReactComponent as BarChartActiveIcon } from "assets/svg/icon/poll_icon_active.svg";
import { ReactSortable } from "react-sortablejs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stickers } from "assets/stickers";
import { tagManyNameRegex } from "constant/regex";

export default function PinMessageDialog({
  openDialogPinMessage,
  handleDialogPinMessage,
  handleOpenDeletePinMessage,
  listPin,
  handleScrollPin,
}) {
  if (!listPin) return;
  const { t } = useTranslation();
  const [, setListPin] = useState(listPin);
  const [isEditPin, setEditPin] = useState(false);
  const handleEditPin = () => {
    setEditPin((prevOpen) => !prevOpen);
  };
  return (
    <Box>
      <Dialog open={openDialogPinMessage} onClose={handleDialogPinMessage} sx={styles.dialog}>
        <Box textAlign="center">
          <Box />
          <Box sx={styles.iconPin}>
            <PinIcon />
          </Box>
          <DialogTitle sx={styles.DialogTittle}>
            <Box sx={styles.boxLeft} />
            <Typography sx={styles.titleText}> {t("chat.header.pin.list_pin")} </Typography>
            {isEditPin ? (
              <Typography onClick={handleEditPin} sx={styles.saveEdit}>
                {t("chat.header.pin.save")}
              </Typography>
            ) : (
              <IconButton sx={styles.IconButtonEdit} onClick={handleEditPin}>
                <EditPinIcon />
              </IconButton>
            )}
          </DialogTitle>
          <Divider sx={styles.DividerT} />
        </Box>
        <List sx={styles.listPin}>
          <ReactSortable
            filter=".add_option .create_poll"
            list={listPin}
            setList={setListPin}
            dragClass="sortableDrag"
            animation="200"
            easing="ease-out"
            handle="#dragIcon"
          >
            {listPin?.map((item, index) => (
              <Box key={index} sx={() => styles.boxMessage({ index })}>
                <Box onClick={() => handleScrollPin(item)} sx={styles.content}>
                  {isEditPin && (
                    <Box>
                      <IconButton id="dragIcon" sx={styles.icon}>
                        <DragIcon />
                      </IconButton>
                    </Box>
                  )}
                  {item.kind === "text" && (
                    <Box sx={styles.fileIcon}>
                      <IconButton>
                        <MessagePin />
                      </IconButton>
                    </Box>
                  )}
                  {item.kind === "file" && (
                    <Box sx={styles.fileIcon}>
                      <IconButton>
                        <AttachFileActiveIcon />
                      </IconButton>
                    </Box>
                  )}
                  {item.kind === "audio" && (
                    <Box sx={styles.fileIcon}>
                      <IconButton>
                        <AudioIcon />
                      </IconButton>
                    </Box>
                  )}
                  {item.kind === "image" && (
                    <Box sx={styles.boxImage}>
                      <Box component="img" src={item?.image[0]?.url} sx={styles.image} />
                      {item?.image?.length > 1 && (
                        <>
                          <Box sx={styles.imageBg} />
                          <Typography sx={styles.numberImagePin}>
                            +{item.image.length - 1}
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                  {item.kind === "vote" && (
                    <Box sx={styles.fileIcon}>
                      <IconButton>
                        <BarChartActiveIcon />
                      </IconButton>
                    </Box>
                  )}
                  {item.kind === "video" && (
                    <Box sx={styles.boxImage}>
                      <Box
                        component="img"
                        src={item?.video?.url_thumb || VideoPoster}
                        sx={styles.image}
                      />
                    </Box>
                  )}
                  {item.kind === "emoji" && (
                    <Box sx={styles.boxImage}>
                      <Box
                        component="img"
                        src={Stickers[item?.emoji?.stickerIndex]?.images[item?.emoji?.index]}
                        sx={styles.imageSticker}
                      />
                    </Box>
                  )}
                  <Box sx={styles.messagePin}>
                    <Typography sx={styles.textMessage}>
                      {item.kind === "text" && tagManyNameRegex.exec(item?.text)
                        ? item?.text.replace(tagManyNameRegex, `$1`)
                        : item?.text}
                      {item.kind === "image" && t("chat.header.pin.photo_messages")}
                      {item.kind === "audio" && t("chat.header.pin.voice_messages")}
                      {item.kind === "video" && t("chat.header.pin.video_messages")}
                      {item.kind === "file" && t("chat.header.pin.file_messages")}
                      {item.kind === "emoji" && t("chat.header.pin.sticker_messages")}
                      {item.kind === "vote" && item?.vote?.content}
                    </Typography>
                    <Box sx={styles.textPin}>
                      <Typography sx={styles.textPin1}>{t("chat.header.pin.pin_by")}</Typography>
                      <Typography sx={styles.textPin2}>{item.pin_user.username}</Typography>
                    </Box>
                  </Box>
                </Box>
                {isEditPin && (
                  <IconButton
                    onClick={() => handleOpenDeletePinMessage(item)}
                    sx={styles.IconClose}
                  >
                    <IconCLose />
                  </IconButton>
                )}
              </Box>
            ))}
          </ReactSortable>
        </List>
      </Dialog>
    </Box>
  );
}
