import PropTypes from "prop-types";
import { AES, enc } from "crypto-js";
import { PRIVATE_KEY } from "config/keys";

function encrypt(data) {
  const encode = AES.encrypt(JSON.stringify(data), PRIVATE_KEY);
  return encode.toString();
}

function decrypt(cipherText) {
  try {
    const bytes = AES.decrypt(cipherText, PRIVATE_KEY);
    return JSON.parse(bytes.toString(enc.Utf8));
  } catch (error) {
    return cipherText;
  }
}

encrypt.propTypes = {
  data: PropTypes.object.isRequired,
};

decrypt.propTypes = {
  cipherText: PropTypes.string.isRequired,
};

export { encrypt, decrypt };
