import { Box, Dialog, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import Cropper from "react-cropper";
import { useRef, useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close.svg";
import "cropperjs/dist/cropper.css";
import { isEmpty } from "utils/validate";
import styles from "assets/styles/pages/Chat/component/CropGroupAvatar";
import { useTranslation } from "react-i18next";

export default function CropGroupImageBackGroundUpdate({ setting }) {
  const cropperRef = useRef(null);
  const uploadRef = useRef();
  const [image, setImage] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (setting.isOpen) {
      setTimeout(() => {
        if (setting.imageBackGround) setImage(setting.imageBackGround);
        else uploadRef.current.click();
      }, 0);
    }
  }, [setting.isOpen]);

  const onChange = async (e) => {
    e.preventDefault();
    if (!isEmpty(e.target.files)) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setting.setOpenCropBackGround(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleClose = () => {
    setting.onClose();
    setImage(null);
  };

  const checkIt = () => {
    setTimeout(() => {
      if (!uploadRef.current.value.length && isEmpty(image)) handleClose();
      document.body.onfocus = null;
    }, 100);
  };

  const onCrop = async () => {
    try {
      const cropper = cropperRef.current?.cropper;
      setting.seImageBackGround(cropper.getCroppedCanvas().toDataURL("image/png"));
      await setting.updateImgBackground(cropper.getCroppedCanvas().toDataURL("image/png"));
      handleClose();
    } catch (error) {
      console.error("Lỗi khi cập nhật ảnh:", error);
    }
  };
  const initialize = () => {
    if (image) {
      document.body.onfocus = checkIt;
    }
  };
  return (
    <Dialog open={setting.isOpen} onClose={handleClose} sx={styles.dialog}>
      <DialogTitle sx={styles.dialogTitle}>
        <Typography variant="body1" sx={styles.title}>
          {t("chat.user_list.crop_avatar.title")}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={styles.divider} />
      <Box sx={styles.cropperContainer}>
        <Cropper
          src={image}
          style={styles.cropper}
          viewMode={1}
          ref={cropperRef}
          responsive
          dragMode="none"
          aspectRatio={1}
          zoomable={false}
          guides={false}
          cropBoxMovable
          cropBoxResizable
          scalable={false}
          highlight={false}
          background={false}
          center={false}
          autoCropArea={1}
        />
      </Box>
      <Box sx={styles.buttonContainer}>
        <Box component="label" onChange={onChange} onClick={initialize} sx={styles.selectButton}>
          <input ref={uploadRef} hidden accept="image/*" type="file" />
          <Typography sx={styles.select}>+ {t("chat.user_list.crop_avatar.select")}</Typography>
        </Box>
        <Box onClick={onCrop} variant="contained" sx={styles.doneButton}>
          <Typography sx={styles.done} color="white.main">
            {t("chat.user_list.crop_avatar.done")}
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
}
