import { memo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/ListMemberAction";
import { getUserToken } from "utils/storage";
import { useTranslation } from "react-i18next";
import NotificationAdminDialog from "./NotificationAdminDialog";

function ListMemberAction({
  openPopperListMember,
  anchor,
  handleClose,
  member,
  group,
  handleChangePermissionGroup,
  handleOpenNotificationAdminDialog,
  handleRemoveUser,
  isAdmin,
  openNotificationAdminDialog,
  handleCloseNotificationAdminDialog,
  reporterIds,
  handleAddAndRemoveReporter,
}) {
  const { t } = useTranslation();
  const userInformation = getUserToken();

  const textExitGroup = (admins, currentUser) => {
    if (userInformation?.id === member?.id) return t("chat.list_member_action.leave_from_chat");

    if (admins?.includes(currentUser)) {
      return t("chat.list_member_action.remove_from_chat");
    }
  };

  return (
    <Popper
      placement="bottom-end"
      sx={styles.popper}
      open={openPopperListMember}
      anchorEl={anchor}
      transition
      disablePortal
      popperOptions={{ modifiers: [{ name: "offset", options: { offset: [-20, -10] } }] }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener disableReactTree onClickAway={handleClose}>
              <Box>
                {!group?.admin_ids?.includes(userInformation?.id) ||
                  (userInformation?.id !== member?.id && (
                    <Box>
                      <Box
                        onClick={() => handleChangePermissionGroup(member?.id, group?.id)}
                        sx={styles.topItemContainer}
                      >
                        <Typography variant="body1" sx={styles.item}>
                          {group?.admin_ids?.includes(member?.id)
                            ? t("chat.list_member_action.remove_as_admin")
                            : t("chat.list_member_action.add_as_admin")}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                {!group?.admin_ids?.includes(userInformation?.id) ||
                  (userInformation?.id !== member?.id && group?.channel === "livechat" && (
                    <Box>
                      <Box
                        onClick={() => handleAddAndRemoveReporter(member?.id)}
                        sx={styles.topItemContainerReporter}
                      >
                        <Typography variant="body1" sx={styles.item}>
                          {reporterIds?.includes(member?.id?.toString())
                            ? t("chat.list_member_action.remove_as_reporter")
                            : t("chat.list_member_action.add_as_reporter")}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                <Box
                  onClick={() => {
                    if (
                      isAdmin &&
                      JSON.parse(group?.admin_ids).length === 1 &&
                      userInformation?.id === member?.id
                    ) {
                      handleOpenNotificationAdminDialog();
                    } else {
                      handleRemoveUser(member?.id, group?.id);
                    }
                  }}
                  sx={styles.bottomItemContainer}
                >
                  <Typography variant="body1" sx={styles.itemErrorColor}>
                    {textExitGroup(group?.admin_ids, member?.id)}
                  </Typography>
                </Box>
              </Box>
            </ClickAwayListener>
            <NotificationAdminDialog
              listMember={group?.users}
              group={group}
              openNotificationAdminDialog={openNotificationAdminDialog}
              handleChangePermissionGroup={handleChangePermissionGroup}
              handleCloseNotificationAdminDialog={handleCloseNotificationAdminDialog}
              handleRemoveUser={handleRemoveUser}
            />
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

ListMemberAction.defaultProps = {
  openSetting: false,
  anchor: "",
};

ListMemberAction.propTypes = {
  openSetting: PropTypes.bool,
  anchor: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
};

export default memo(ListMemberAction);
