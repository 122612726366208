export default {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // px: 2,
    // py: 1,
    // borderTop: "0.1px solid #D5D5D5",
    // borderBottom: "0.1px solid #D5D5D5",
    background: "#F0F6FF",
    width: "96%",
    height: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-8px",
    borderRadius: "5px",
  },
  content: { flexGrow: 1 },
  infoUserReply: {
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
  },
  reply: ({ typography }) => ({
    color: "var(--button_new, #406EE2)",
    leadingTrim: "both",
    fontFamily: typography.fontFamily3,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    marginRight: "5px",
  }),
  replyName: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xs,
    color: "#090710",
    fontWeight: typography.fontWeightBold,
    // background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    background: "#090710",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
  vID: ({ typography }) => ({
    px: 1,

    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xs,
    fontWeight: typography.fontWeightBold,
    // background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    background: "#406EE2",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
  contentImageOrFile: {
    flexGrow: 1,
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "4px",
  },
  boxImage: {
    width: "40px",
    height: "40px",
    // mr: 1,
    marginLeft: "5px",
    marginRight: "5px",
    position: "relative",
  },
  boxImageEmoji: {
    width: "45px",
    height: "45px",
    marginLeft: "5px",
    marginRight: "5px",
    position: "relative",
  },
  fillColorIconRight: ({ isChangeText }) => ({
    // background: "linear-gradient(124.77deg, #26BBFE 13.59%, #1BC88B 91.04%);",
    background: "#406EE2",
    width: "30px",
    height: "30px",
    marginBottom: "5px",
    borderRadius: "50%",
    marginRight: "5px",
    lineHeight: "50px",
    float: "right",
    marginLeft: "5px",
    opacity: isChangeText ? 0.5 : 1,
    "&:hover": {
      background: "#406EE2",
    },
  }),
  fillColorWhite: {
    fill: "white",
    width: "20px",
    height: "20px",
  },
  downloadIcon: {
    width: "30px",
  },
  imageBg: ({ borders }) => ({
    background: "black",
    opacity: 0.5,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    height: "40px",
    borderRadius: borders.borderRadius.md,
  }),
  imageVideoBg: ({ borders }) => ({
    background: "black",
    opacity: 0.5,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    height: "40px",
    borderRadius: borders.borderRadius.md,
  }),
  imagePlayBg: ({ borders }) => ({
    width: "30px",
    opacity: 1,
    position: "absolute",
    top: 5,
    left: 5,
    height: "30px",
    borderRadius: borders.borderRadius.md,
  }),
  numberImagePin: ({ typography }) => ({
    position: "absolute",
    top: "30%",
    left: "30%",
    color: "#ffff",
    fontSize: "14px",
    fontFamily: typography.fontFamily3,
  }),
  image: ({ borders }) => ({
    flexShrink: 0,
    border: "1px solid #D5D5D5",
    width: "40px",
    objectFit: "cover",
    height: "40px",
    borderRadius: borders.borderRadius.md,
    mr: 1.5,
  }),
  fileIcon: { flexShrink: 0, mr: 1.5 },
  fillColorFileIcon: {
    background: "linear-gradient(124.77deg, #26BBFE 13.59%, #1BC88B 91.04%);",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },

  boxContentImage: { flexGrow: 1, marginLeft: "5px" },
  text: ({ typography }) => ({
    marginLeft: "5px",
    marginRight: "5px",
    fontFamily: typography.fontFamily3,
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.5)",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    width: "90%",
  }),
  textImage: ({ typography }) => ({
    // fontStyle: "italic",
    marginLeft: "5px",
    fontFamily: typography.fontFamily3,
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.5)",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    width: "90%",
  }),
  IconClose: { flexShrink: 0 },
};
