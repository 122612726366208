import moment from "moment/moment";
import { v4 } from "uuid";

export function convertNumber(num) {
  if (num < 1000) {
    return num; // Trả về giá trị không thay đổi nếu nhỏ hơn 1000
  }
  if (num >= 1000 && num < 1000000) {
    const val = `${(num / 1000).toFixed().replace(".", ",")}`;
    return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}k`; // Chuyển thành dạng x,yyk nếu trong khoảng từ 1000 đến 999999
  }
  if (num >= 1000000) {
    const val = `${(num / 1000000).toFixed().replace(".", ",")}`;
    return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}m`; // Chuyển thành dạng x,yym nếu lớn hơn hoặc bằng 1000000
  }
}
export function formatNumber(value) {
  const val = (value / 1).toFixed().replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function convertArrayUnique(value) {
  if (value.length > 0) {
    return value.filter((currentValue, index, arr) => arr.indexOf(currentValue) === index);
  }
  return [];
}

export const genUniqueName = (oldName) => {
  const lastIndex = oldName.lastIndexOf(".");
  return v4() + oldName.substring(lastIndex);
};

export function convertToMinutesAndSeconds(timeInSeconds) {
  let minutes = Math.floor(timeInSeconds / 60);
  let seconds = timeInSeconds % 60;
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${minutes}:${seconds}`;
}

export function formatDate(date, format = "HH:mm:ss DD-MM-YYYY") {
  return moment(date).format(format);
}
