import { Box, CircularProgress, Fade, Grow, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/section/ContentChat";
import CircularLoading from "components/Loading/CircularLoading";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import { isJSON, overrideDefault } from "utils/utils";
import MessageActionSheet from "../component/MessageActionSheet";
import Unread from "../component/Unread";
import ViewerDialog from "./ViewerDialog";
import VotingDialogs from "./voterInfomation";
import MessageFile from "../component/MessageFile";
import MessageImage from "../component/MessageImage";
import ForwardDialog from "../component/ForwardDialog";
import MessageAudio from "../component/MessageAudio";
import MessageEmoji from "../component/MessageEmoji";
import MessageNormal from "../component/MessageNormal";
import MessageNotification from "../component/MessageNotification";
import MessageVideo from "../component/MessageVideo";
import MessageVote from "../component/MessageVote";

function ContentChat({
  handleOpenMessagePopper,
  isSelectOwner,
  openMessageSetting,
  handleMessageClose,
  handleDownMediaMessage,
  unreadMessage,
  handleReadAllMessage,
  chatRef,
  handleOpenViewerDialog,
  openViewerDialog,
  openVoteInfoDialog,
  handleViewerClose,
  isPaddingTop,
  isScrollEnd,
  handleOpenEditMessage,
  handleOpenReplyMessage,
  mouseX,
  mouseY,
  isOpenForwardDialog,
  handleCloseForwardDialog,
  handleOpenForwardDialog,
  handleDoneForwardDialog,
  listGroupChat,
  selectForwardIndex,
  setSelectForwardIndex,
  handleOpenPinMessage,
  listMessage,
  userInformation,
  isFetchingNewGroup,
  isFetchingMessage,
  handleDropFiles,
  handleDragEnterFiles,
  isDragOverlay,
  handleDragLeaveFiles,
  handleFeeMessage,
  detailMessage,
  detailGroup,
  handleOpenTipMessage,
  handleDeleteMessage,
  handleChangeVote,
  voteInfo,
  handleOpenVoteInfo,
  handleVotingClose,
  openChanelTv,
  handleScrollPin,
  addGroupChat,
  isOwnerPopup,
  infoMessagePopup,
}) {
  const { t } = useTranslation();
  const [idMediaCurrent, setIdMediaCurrent] = useState("");
  const [indexImg, setIndexImg] = useState(0);

  const handleCloseMedia = (val) => {
    setIdMediaCurrent(val);
  };
  function format(date) {
    const today = dayjs();
    const yesterday = dayjs().subtract(1, "day");
    if (dayjs(date).isSame(today, "day")) return "Hôm nay";
    if (dayjs(date).isSame(yesterday, "day")) return "Hôm qua";
    return dayjs(date).format("D [Tháng] M, YYYY");
  }

  const groupMessByDate = () => {
    const updatedListMessage = listMessage.map((element, index) => {
      const currentSender = element?.message?.user_id;
      const prevSender = index > 0 ? listMessage[index - 1]?.message?.user_id : null;
      const isSameSender = currentSender === prevSender;
      return {
        ...element,
        status: isSameSender,
      };
    });

    return groupBy([...updatedListMessage], (item) => {
      const dateFormat = format(item.createdAt);
      return dateFormat;
    });
  };

  const groupMess = groupMessByDate();

  return (
    <>
      <MessageActionSheet
        indexImg={indexImg}
        mouseX={mouseX}
        mouseY={mouseY}
        handleOpenViewerDialog={handleOpenViewerDialog}
        handleOpenForwardDialog={handleOpenForwardDialog}
        handleDownMediaMessage={handleDownMediaMessage}
        isSelectOwner={isSelectOwner}
        openSetting={openMessageSetting}
        handleClose={handleMessageClose}
        handleOpenEditMessage={handleOpenEditMessage}
        handleOpenReplyMessage={handleOpenReplyMessage}
        handleOpenPinMessage={handleOpenPinMessage}
        handleFeeMessage={handleFeeMessage}
        detailMessage={detailMessage}
        userInformation={userInformation}
        isOwner={userInformation?.id === detailMessage?.user_id}
        handleTipMessage={handleOpenTipMessage}
        handleDeleteMessage={handleDeleteMessage}
      />
      <ViewerDialog
        detailGroup={detailGroup}
        detailMessage={detailMessage}
        onClose={handleViewerClose}
        isOpen={openViewerDialog}
      />
      <VotingDialogs
        detailGroup={detailGroup}
        onClose={handleVotingClose}
        open={openVoteInfoDialog}
        voteInfo={voteInfo}
      />
      <ForwardDialog
        onClose={handleCloseForwardDialog}
        isOpen={isOpenForwardDialog}
        selectForwardIndex={selectForwardIndex}
        setSelectForwardIndex={setSelectForwardIndex}
        handleDoneForwardDialog={handleDoneForwardDialog}
        listGroupChat={listGroupChat}
      />
      <Box
        sx={(e) => styles.container(e)}
        onDragEnter={handleDragEnterFiles}
        onDragLeave={overrideDefault}
        onDrop={overrideDefault}
        onDragOver={overrideDefault}
        onContextMenu={(e) => {
          e.preventDefault();
          handleMessageClose();
        }}
      >
        <Box sx={(e) => styles.overlay(e, { isFetchingNewGroup })}>
          <CircularLoading />
        </Box>
        <Box
          onDragEnter={overrideDefault}
          onDragLeave={handleDragLeaveFiles}
          onDrop={handleDropFiles}
          onDragOver={overrideDefault}
          sx={(e) => styles.dragOverlay(e, { isDragOverlay })}
        >
          <Typography
            onDragEnter={overrideDefault}
            onDragLeave={handleDragLeaveFiles}
            onDrop={handleDropFiles}
            onDragOver={overrideDefault}
            color="white.main"
            variant="h3"
            sx={styles.dragOverlayText}
          >
            {t("system.drop_to_send")}
          </Typography>
        </Box>

        <Box
          ref={chatRef}
          sx={() => styles.listMessage({ isPaddingTop, paddingChanel: openChanelTv ? 47 : 0 })}
        >
          <Box>
            <Grow in={isFetchingMessage} timeout={0}>
              <Box sx={styles.progressContainer}>
                <CircularProgress style={styles.progress} />
              </Box>
            </Grow>
            {Object.keys(groupMess).map((key) => (
              <>
                <Box sx={styles.dayGroup}>{key}</Box>

                <TransitionGroup>
                  {groupMess[key].map((element) => (
                    <Fade key={element._id}>
                      <Box>
                        {element?.message?.kind === "text" && element?.message?.system === 0 && (
                          <MessageNormal
                            isBrand={!!detailGroup?.is_brandname}
                            handleClose={handleMessageClose}
                            handleOpenPopper={handleOpenMessagePopper}
                            isOwnerPopup={isOwnerPopup}
                            infoMessagePopup={infoMessagePopup}
                            handleOpenViewerDialog={handleOpenViewerDialog}
                            isShowAvatar
                            value={element?.message}
                            statusMessage={element?.status}
                            isOwner={userInformation.id === element?.message.user_id}
                            handleScrollPin={handleScrollPin}
                            addGroupChat={addGroupChat}
                            handleOpenReplyMessage={handleOpenReplyMessage}
                            handleOpenForwardDialog={handleOpenForwardDialog}
                          />
                        )}
                        {/* NOTIFICATION */}
                        {element?.message?.kind === "text" &&
                          element?.message?.system === 1 &&
                          !isJSON(element?.message?.text) && (
                            <MessageNotification content={element?.message?.text} />
                          )}
                        {/* IMAGE */}
                        {element?.message?.kind === "image" && (
                          <MessageImage
                            isBrand={!!detailGroup?.is_brandname}
                            setIndexImg={setIndexImg}
                            handleClose={handleMessageClose}
                            handleOpenPopper={handleOpenMessagePopper}
                            isOwnerPopup={isOwnerPopup}
                            infoMessagePopup={infoMessagePopup}
                            handleOpenViewerDialog={handleOpenViewerDialog}
                            isShowAvatar
                            value={element?.message}
                            isOwner={userInformation.id === element?.message.user_id}
                            addGroupChat={addGroupChat}
                            handleOpenReplyMessage={handleOpenReplyMessage}
                            handleOpenForwardDialog={handleOpenForwardDialog}
                            handleScrollPin={handleScrollPin}
                            statusMessage={element?.status}
                          />
                        )}
                        {/* Video */}
                        {element?.message?.kind === "video" && (
                          <MessageVideo
                            isBrand={!!detailGroup?.is_brandname}
                            idMediaCurrent={idMediaCurrent}
                            setIdMediaCurrent={handleCloseMedia}
                            handleClose={handleMessageClose}
                            handleOpenPopper={handleOpenMessagePopper}
                            isOwnerPopup={isOwnerPopup}
                            infoMessagePopup={infoMessagePopup}
                            handleOpenViewerDialog={handleOpenViewerDialog}
                            isShowAvatar
                            value={element?.message}
                            isOwner={userInformation.id === element?.message.user_id}
                            addGroupChat={addGroupChat}
                            handleOpenReplyMessage={handleOpenReplyMessage}
                            handleOpenForwardDialog={handleOpenForwardDialog}
                            handleScrollPin={handleScrollPin}
                            statusMessage={element?.status}
                          />
                        )}
                        {/* File */}
                        {element?.message?.kind === "file" && (
                          <MessageFile
                            isBrand={!!detailGroup?.is_brandname}
                            handleClose={handleMessageClose}
                            handleOpenPopper={handleOpenMessagePopper}
                            isOwnerPopup={isOwnerPopup}
                            infoMessagePopup={infoMessagePopup}
                            handleOpenViewerDialog={handleOpenViewerDialog}
                            isShowAvatar
                            value={element?.message}
                            isOwner={userInformation.id === element?.message.user_id}
                            addGroupChat={addGroupChat}
                            handleOpenReplyMessage={handleOpenReplyMessage}
                            handleOpenForwardDialog={handleOpenForwardDialog}
                            handleScrollPin={handleScrollPin}
                            statusMessage={element?.status}
                          />
                        )}

                        {/* Audio */}
                        {element?.message?.kind === "audio" && (
                          <MessageAudio
                            isBrand={!!detailGroup?.is_brandname}
                            idMediaCurrent={idMediaCurrent}
                            setIdMediaCurrent={handleCloseMedia}
                            handleClose={handleMessageClose}
                            handleOpenPopper={handleOpenMessagePopper}
                            isOwnerPopup={isOwnerPopup}
                            infoMessagePopup={infoMessagePopup}
                            handleOpenViewerDialog={handleOpenViewerDialog}
                            isShowAvatar
                            value={element?.message}
                            isOwner={userInformation.id === element?.message.user_id}
                            addGroupChat={addGroupChat}
                            handleOpenReplyMessage={handleOpenReplyMessage}
                            handleOpenForwardDialog={handleOpenForwardDialog}
                            handleScrollPin={handleScrollPin}
                            statusMessage={element?.status}
                          />
                        )}

                        {/* Emoji */}
                        {element?.message?.kind === "emoji" && (
                          <MessageEmoji
                            isBrand={!!detailGroup?.is_brandname}
                            handleOpenPopper={handleOpenMessagePopper}
                            isOwnerPopup={isOwnerPopup}
                            infoMessagePopup={infoMessagePopup}
                            handleOpenViewerDialog={handleOpenViewerDialog}
                            isShowAvatar
                            value={element?.message}
                            isOwner={userInformation.id === element?.message.user_id}
                            addGroupChat={addGroupChat}
                            handleOpenReplyMessage={handleOpenReplyMessage}
                            handleOpenForwardDialog={handleOpenForwardDialog}
                            handleScrollPin={handleScrollPin}
                            statusMessage={element?.status}
                          />
                        )}
                        {/* Vote */}
                        {element?.message?.kind === "vote" && (
                          <MessageVote
                            handleChangeVote={handleChangeVote}
                            handleOpenPopper={handleOpenMessagePopper}
                            isOwnerPopup={isOwnerPopup}
                            infoMessagePopup={infoMessagePopup}
                            handleOpenViewerDialog={handleOpenViewerDialog}
                            isShowAvatar
                            value={element?.message}
                            userInformation={userInformation}
                            isOwner={userInformation.id === element?.message.user_id}
                            handleOpenVoteInfo={handleOpenVoteInfo}
                            totalMember={detailGroup?.total_member}
                            addGroupChat={addGroupChat}
                            handleOpenReplyMessage={handleOpenReplyMessage}
                            handleOpenForwardDialog={handleOpenForwardDialog}
                            statusMessage={element?.status}
                          />
                        )}
                      </Box>
                    </Fade>
                  ))}
                </TransitionGroup>
              </>
            ))}
          </Box>
        </Box>
        <Unread
          unreadMessage={unreadMessage}
          handleReadAllMessage={handleReadAllMessage}
          isScrollEnd={isScrollEnd}
        />
      </Box>
    </>
  );
}

export default ContentChat;
