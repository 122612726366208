import { Button, Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import styles from "assets/styles/pages/Chat/section/InformationDialog";
import { ReactComponent as CameraIcon } from "assets/svg/icon/camera_3.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import IconButton from "@mui/material/IconButton";
import { ReactComponent as Camera2Icon } from "assets/svg/icon/camera_2.svg";
import { ReactComponent as CloseAddGroupIcon } from "assets/svg/icon/close_add_group.svg";
import { useMutation } from "react-query";
import { useUpdateProfile } from "service/UserService";
import { v4 } from "uuid";
import { uploadResource } from "../../../api/chat";
import { UPLOAD_URL } from "../../../config/keys";
import CropGroupAvatarUpdateGroup from "../component/CropGroupAvatarUpdateGroup";
import CropGroupImageBackGroundUpdate from "../component/CropGroupImageBackGroundUpdate";
import UpdateInformationDialog from "../component/UpdateInformationDialog";

export default function InformationDialog({ onClose, isOpen, userInformation }) {
  const { t } = useTranslation();
  const [openUpdateInformatin, setOpenUpdateInformation] = useState(false);
  const [isOpenCrop, setOpenCrop] = useState(false);
  const [isOpenCropBackGround, setOpenCropBackGround] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [imageBackGround, seImageBackGround] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const { mutateAsync: updateInformationUser } = useUpdateProfile();

  const handleCloseUpdateInformation = () => {
    setOpenUpdateInformation(false);
  };
  const handleOpenUpdateInformation = () => {
    onClose();
    setOpenUpdateInformation(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString("vi-VN", options);
    return formattedDate;
  };
  const handleOpenCropImage = () => setOpenCrop(true);
  const handleOpenCropImageBackGround = () => setOpenCropBackGround(true);
  const handleCloseCropImage = () => setOpenCrop(false);
  const handleCloseCropImageBackGround = () => setOpenCropBackGround(false);

  const uploadImageMutate = useMutation((data) => uploadResource(data));
  const updateAvatarGroup = async (avatarGroup) => {
    const res = await fetch(avatarGroup);
    const img = await res.blob();
    const formData = new FormData();

    formData.append("image", img, `${v4()}.png`);
    const imageURL = await uploadImageMutate.mutateAsync(formData);

    await updateInformationUser({
      vId: userInformation?.id,
      avatar: `${UPLOAD_URL}/${imageURL.path}`,
    });
  };
  const updateImgBackgroundGroup = async (avatarGroup) => {
    const res = await fetch(avatarGroup);
    const img = await res.blob();
    const formData = new FormData();

    formData.append("image", img, `${v4()}.png`);
    const imageURL = await uploadImageMutate.mutateAsync(formData);

    await updateInformationUser({
      vId: userInformation?.id,
      img_background: `${UPLOAD_URL}/${imageURL.path}`,
    });
  };

  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  return (
    <>
      <Dialog sx={styles.dialog} onClose={onClose} open={isOpen}>
        <CropGroupAvatarUpdateGroup
          setting={{
            isOpen: isOpenCrop,
            onClose: handleCloseCropImage,
            updateImage: updateAvatarGroup,
            setAvatar,
            avatar,
            setOpenCrop,
          }}
        />
        <CropGroupImageBackGroundUpdate
          setting={{
            isOpen: isOpenCropBackGround,
            onClose: handleCloseCropImageBackGround,
            updateImgBackground: updateImgBackgroundGroup,
            seImageBackGround,
            imageBackGround,
            setOpenCropBackGround,
          }}
        />
        <DialogTitle sx={styles.dFlexColumn}>
          <Box sx={styles.dialogTitle}>
            <Typography variant="body1" color="initial" sx={styles.title}>
              {t("information.account_information")}
            </Typography>
            <IconButton
              sx={styles.paddingReset}
              onClick={() => {
                onClose();
              }}
            >
              <CloseAddGroupIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={styles.divider} />
        <Box sx={styles.container}>
          <Box sx={styles.cropBackGroundContainer} onClick={handleOpenCropImageBackGround}>
            <Avatar
              alt="Remy Sharp"
              variant="square"
              srcSet={imageBackGround != null ? imageBackGround : userInformation?.img_background}
              sx={{
                width: "100%",
                height: "225px",
              }}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
            />
            <Box sx={styles.overlayBackGround} style={{ display: isHovered ? "none" : "block" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  padding: "8px",
                }}
              >
                <CameraIcon style={styles.updateAvatarIcon} />
                <Typography
                  variant="body1"
                  color="initial"
                  style={{ color: "#FFF", fontSize: "13px" }}
                >
                  Chỉnh sửa ảnh bìa
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.containerImage}>
            <Box sx={styles.cropAvatarContainer}>
              <Avatar
                alt="Remy Sharp"
                srcSet={avatar != null ? avatar : userInformation?.avatar}
                sx={{
                  width: "120px",
                  height: "120px",
                  boxShadow: "0px 0px 0px 3px rgba(255, 255, 255, 225)",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <Box
                style={{ position: "absolute", top: "85px", right: 0, cursor: "pointer" }}
                onClick={handleOpenCropImage}
              >
                <Camera2Icon />
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: "65px", width: "100%" }}>
            <Typography variant="body1" color="initial" sx={styles.username}>
              {userInformation?.fullname}
            </Typography>
            <Typography variant="body1" color="initial" sx={styles.vId}>
              VID: {userInformation?._id}
            </Typography>
          </Box>
          <Box style={{ marginLeft: "30px", marginTop: "15px" }}>
            <Typography variant="body1" color="initial" sx={styles.infor}>
              {t("information.personal_information")}:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {t("information.phone_number")}:
              </Grid>
              <Grid item xs={8}>
                {userInformation?.phone}
              </Grid>
              <Grid item xs={4}>
                {t("information.gender")}:
              </Grid>
              <Grid item xs={8}>
                {userInformation?.gender === 0 ? t("information.male") : t("information.female")}
              </Grid>
              <Grid item xs={4}>
                {t("information.date_of_birth")}:
              </Grid>
              <Grid item xs={8}>
                {userInformation?.birthday && formatDate(userInformation?.birthday)}
              </Grid>
            </Grid>
          </Box>
          <Button
            variant="contained"
            sx={styles.buttonCreateGroup}
            onClick={handleOpenUpdateInformation}
          >
            {t("information.update_information")}
          </Button>
        </Box>
      </Dialog>
      <UpdateInformationDialog
        isOpen={openUpdateInformatin}
        onClose={handleCloseUpdateInformation}
        userInformation={userInformation}
      />
    </>
  );
}
