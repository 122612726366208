import { messageOffline } from "api/chat";
import { useQuery } from "react-query";

export const useGetListMedia = (group_id, media_type, limit = 20) =>
  useQuery(["ListMessageMedia", group_id, media_type, limit], async () => {
    const res = await messageOffline({
      group_id,
      media_type,
      page: 1,
      limit,
    });
    if (res.error)
      return {
        count: 0,
        data: [],
      };
    return {
      count: res?.data?.count || 0,
      data: res?.data?.items.map((value) => ({ ...value, message: value.content })),
    };
  });
