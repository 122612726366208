import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import styles from "assets/styles/pages/Chat/component/ShareChannelTV";
import { Stack } from "@mui/material";
import { useState, useEffect } from "react";
import TabPanel from "components/TabPanel";
// eslint-disable-next-line import/no-extraneous-dependencies
import QRCode from "react-qr-code";
import { Buffer } from "buffer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

function ShareChannelTV({ onClose, isOpen, detailGroup, handleOpenForwardDialog, setSharedLink }) {
  const { t } = useTranslation();
  // state
  const [value] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  /* eslint-disable no-bitwise */
  const deCrypt = (str, key = 9) => {
    let output = "";
    for (let i = 0; i < str.length; i += 1) {
      output += String.fromCharCode(str.charCodeAt(i) ^ key);
    }
    return output;
  };
  const [link, setLink] = useState(null);
  const getLink = () => {
    const group_id = detailGroup?.id;
    const encode = deCrypt(`${group_id}`, 15);
    const toString = Buffer.from(encode);
    const id = `https://i.wghn.net/i/${toString.toString("base64")}`;
    setLink(id);
    // return id
  };

  useEffect(() => {
    getLink();
  }, []);
  const showSnackbar = () => {
    setOpenSnackbar(true);
  };
  const copyLink = () => {
    navigator.clipboard.writeText(link);
    showSnackbar();
  };
  const getlink = () => {
    const LINK = link;
    setSharedLink(LINK);
  };
  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog sx={styles.dialog} onClose={onClose} open={isOpen}>
      <DialogTitle sx={styles.dFlexColumn}>
        <Box sx={styles.dialogTitle}>
          <IconButton onClick={onClose} sx={styles.paddingReset}>
            <Typography variant="body2" sx={styles.doneActive}>
              {t("share_channel_tv.cancel")}
            </Typography>
          </IconButton>
          <Box sx={styles.textCenter}>
            <Typography variant="body1" color="initial" sx={styles.title}>
              {t("share_channel_tv.invite_group_link")}
            </Typography>
          </Box>
          <IconButton onClick={onClose} sx={styles.paddingReset}>
            <Typography
              variant="body2"
              sx={styles.doneActive}
              onClick={() => {
                handleOpenForwardDialog();
                getlink();
              }}
            >
              {t("share_channel_tv.share")}
            </Typography>
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={styles.divider} />
      <Box sx={styles.contentContainer}>
        <TabPanel value={value} sx={styles.tabPanel} index={0}>
          <Stack sx={styles.overflowY}>
            {link && (
              <QRCode
                size={150}
                // value={"https://www.facebook.com/haamyyyy"}
                logo="../../../assets/images/address_icon.png"
                value={link}
              />
            )}
          </Stack>
        </TabPanel>
        <Typography sx={styles.sharelink}>
          {t("share_channel_tv.the_link_has_been_copied")}
        </Typography>
        <Box>
          <Box sx={styles.copylink}>
            <Button variant="outlined" onClick={copyLink}>
              {link && link.slice(8)}
            </Button>
            <Box>
              {showSnackbar && (
                <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={closeSnackbar}>
                  <MuiAlert onClose={closeSnackbar} severity="success" sx={{ width: "100%" }}>
                    {t("share_channel_tv.the_link_has_been_copied")}
                  </MuiAlert>
                </Snackbar>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
export default ShareChannelTV;
