export default {
  dialog: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "85%",
        // maxHeight: "600px",
        // maxWidth: "450px",
        [breakpoints.down("sm")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  dFlexColumn: { display: "flex", flexDirection: "column" },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    mx: 2,
    mt: 3,
    mb: 0,
  },
  textCenter: { textAlign: "center" },
  textDefault: ({ palette }) => ({
    color: palette.grey[400],
  }),
  textActive: ({ palette }) => ({
    color: palette.white.main,
  }),
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
  }),
  subTitle: ({ typography }) => ({
    color: "#B4B4B4",
    py: 1,
    ml: 2,
    fontWeight: typography.fontWeightBold,
  }),
  doneDefault: ({ palette }) => ({
    cursor: "default",
    color: palette.inputBorderColor.main,
  }),
  doneActive: ({ typography }) => ({
    // background: "-webkit-linear-gradient(180deg, #26BBFE 3.27%, #1BC88B 97.45%);",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    fontFamily: `${typography.fontFamily2} !important`,
  }),
  label: ({ typography }) => ({
    flexGrow: 1,
    px: 1.25,
    fontWeight: typography.fontWeightBold,
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  tabList: {
    mx: 2,
    my: 1.5,
    background: "#fff",
    justifyContent: "space-between",
  },
  tab: (value, i) => ({
    p: 0.75,
    borderRadius: "100px",
    textAlign: "center",
    background: value === i ? "linear-gradient(90deg, #26BBFE 0%, #1BC88B 100%);" : "#fff",
  }),
  divider: {
    my: 0,
    backgroundColor: "#D1D1D1",
  },
  boxShowSelected: { height: "70px", mb: 1, pr: 2, pl: 2.5 },
  overflowX: { display: "flex", overflowX: "auto" },
  selectedImage: {
    height: "70px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarImageSelected: { width: "54px", height: "54px", borderRadius: "50%" },
  avatarImage: { width: "44px", height: "44px" },
  closeImageIcon: ({ palette }) => ({
    position: "absolute",
    bottom: "45px",
    right: "4px",
    background: "#8D929F",
    color: palette.white.main,
    width: "22px",
    height: "22px",
    boxShadow: "0px 0px 3.55848px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      background: "#8D929F",
    },
  }),
  countSelectedBox: { display: "flex", justifyContent: "space-between", pr: 4, pl: 2.5 },
  overflowY: { overflowY: "auto", pl: 0.5, ml: 2 },
  boxCheckBox: {
    display: "flex ",
    alignItems: "center",
    borderRadius: "10px",
    width: "100%",
    pl: 2.5,
    "&:hover": {
      background: "#F3F3F3",
    },
  },
  checkBox: {
    py: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  contentContainer: {
    height: "500px",
    maxHeight: "80%",
    minHeight: "20%",
    flexGrow: 1,
    display: "flex",
    borderRadius: 0,
    mt: 1,
    mb: 2.5,
    flexDirection: "column",
  },
  search: ({ palette, borders }) => ({
    marginTop: "30px",
    borderRadius: borders.borderRadius.round,
    mx: 2,
    mb: 2,
    backgroundColor: palette.background.search,
    fieldset: {
      borderRadius: borders.borderRadius.round,
      border: "none",
      height: "50px",
    },
    input: {
      py: 1.5,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
  }),
  dFlexCenter: {
    display: "flex",
    px: 4,
    py: 3,
  },

  buttonDefault: {
    width: "100%",
    height: "44px",
    borderRadius: "10px",
    pointerEvents: "none",
    border: "2px solid",
    borderColor: "linear-gradient(180deg, #26BBFE 3.27%, #1BC88B 97.45%) !important",
  },

  textButtonDefault: ({ typography }) => ({
    background: "-webkit-linear-gradient(180deg, #26BBFE 3.27%, #1BC88B 97.45%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: `${typography.fontFamily2} !important`,
    fontWeight: "600",
  }),
  iconW18: {
    width: "14px",
    fill: "#fff",
  },
  tabPanel: {
    overflowY: "auto",
  },
  paddingReset: {
    p: 0,
  },
};
