/* eslint-disable no-useless-escape */
const numberRegex = /^\d+$/;
const urlRegex =
  /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/;
// (www|http:|https:)+[^\s]+[\w]/;
const prefixUrlRegex = /(www|http:\/\/|https:\/\/)/;

const tagManyNameRegex = /\[(@[^:]+)(:[^\]]+)?\]/gi;

const tagNameRegex = /\[(@[^:]+)(:[^\]]+)?\]/i;
// (www|http:|https:)+[^\s]+[\w]/;

export { numberRegex, urlRegex, prefixUrlRegex, tagManyNameRegex, tagNameRegex };
