import { memo } from "react";
import { Fade, TextField, Typography } from "@mui/material";
import styles from "assets/styles/components/FormTextField";

function FormTextField({ input, meta, ...rest }) {
  return (
    <>
      <TextField {...input} {...rest} />
      <Fade
        in={Boolean(meta.touched && meta.error) || Boolean(meta.submitError)}
        style={{ transformOrigin: "0 0 0" }}
      >
        <Typography sx={styles.helperText} mt={0.5} variant="body2" color="error.main">
          {meta.error || (!meta.modifiedSinceLastSubmit && meta.submitError)}
        </Typography>
      </Fade>
    </>
  );
}

export default memo(FormTextField);
