export default {
  styleOverrides: {
    root: {
      backgroundColor: "transparent",
    },
    paper: {
      backgroundColor: "transparent",
      boxShadow: "none",
      padding: "4px",
      marginTop: "-2px",
      marginLeft: "-2px",
    },
  },
};
