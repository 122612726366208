import { isEmpty } from "utils/validate";

export default {
  container: ({ statusMessage }) => ({
    marginTop: statusMessage ? "4px" : "10px",
    // p: 1,
  }),
  section: ({ isOwner }) => ({
    cursor: "pointer",
    display: "flex",
    alignSelf: isOwner ? "flex-end" : "flex-start",
    flexDirection: isOwner ? "row-reverse" : "row",
  }),
  avatar: {
    mr: 1.5,
    width: "30px",
    height: "30px",
    alignSelf: "flex-start",
  },
  messageSection: ({ breakpoints }) => ({
    maxWidth: "100%",
    [breakpoints.up("xxl")]: {
      maxWidth: "40%",
    },
    [breakpoints.up("xl")]: {
      maxWidth: "50%",
    },
    [breakpoints.up("lg")]: {
      maxWidth: "60%",
    },
    [breakpoints.up("md")]: {
      maxWidth: "70%",
    },
    [breakpoints.up("sm")]: {
      maxWidth: "60%",
    },
    [breakpoints.up("xs")]: {
      maxWidth: "100%",
    },
  }),
  messageContainer: ({ breakpoints, borders }, { isOwner, statusMessage }) => ({
    cursor: "pointer",
    [breakpoints.down("lg")]: {
      minWidth: "180px",
    },
    [breakpoints.down("sm")]: {
      minWidth: "140px",
    },
    background: isOwner ? "#5983EE" : "#FFF",
    py: 1,
    borderTopRightRadius: !isOwner ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner ? borders.borderRadius.xl : 0,
    borderBottomRightRadius: borders.borderRadius.xl,
    borderBottomLeftRadius: borders.borderRadius.xl,
    display: "flex",
    flexDirection: "column",
    marginLeft: !statusMessage ? "0px" : "43px",
  }),
  nameContainer: {
    px: 1,
    mb: 0.5,
  },

  username: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    color: "#406EE2",
    display: "inline",
    "&:hover": {
      textDecoration: "underline", // hoặc các kiểu khác như 'underline', 'solid', 'dashed', etc.
      color: "#406EE2",
      textDecorationThickness: "1.5px",
    },
  }),

  userID: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    // background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    background: "#406EE2",

    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    pl: 0.7,
    display: "inline",
  }),
  messageTimeContainer: ({ isOwner }) => ({
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "center",
    px: 2,
    mt: 1,
    color: isOwner ? "#FFFFFF" : "#5983EE",
  }),
  messageTime: ({ palette, functions }, { isOwner }) => ({
    color: isOwner ? functions.rgba(palette.white.main, 0.5) : palette.grey[300],
    fontSize: "13px",
  }),
  voteContainer: ({ username }) => ({
    pt: isEmpty(username) ? 1 : 0,
  }),
  question: ({ palette }, { isOwner }) => ({
    color: isOwner ? palette.white.main : palette.initial.main,
    mb: isOwner ? 0.5 : 1.5,
    px: 1,
    fontSize: "16px",
  }),
  switchContainer: {
    display: "flex",
    px: 2,
    alignItems: "center",
    mb: 1.5,
  },
  switch: ({ palette }) => ({
    ml: 1,
    width: "fit-content",
    "& .MuiSwitch-thumb": {
      background: `${palette.white.main} !important`,
      backgroundImage: `${palette.white.main} !important`,
      backgroundColor: `${palette.white.main} !important`,
      border: "none",
      marginTop: "6px",
      marginLeft: "6px",
      width: "18px",
      height: "18px",
    },

    "& .MuiSwitch-track": {
      background: `rgba(255, 255, 255, 0.3) !important`,
      backgroundImage: `rgba(255, 255, 255, 0.3) !important`,
      backgroundColor: `rgba(255, 255, 255, 0.3) !important`,
      border: "none",
      height: "24px",
      width: "44px",
    },
    "& .Mui-checked": {
      "& .MuiSwitch-thumb": {
        background: `${palette.white.main} !important`,
        backgroundImage: `${palette.white.main} !important`,
        backgroundColor: `${palette.white.main} !important`,
        border: "none",
      },

      "& .MuiSwitch-track": {
        background: `rgba(255, 255, 255, 0.3) !important`,
        backgroundImage: `rgba(255, 255, 255, 0.3) !important`,
        backgroundColor: `rgba(255, 255, 255, 0.3) !important`,
      },
    },
  }),
  divider: ({ isOwner }) => ({
    my: 0,
    backgroundColor: isOwner ? "#FFFFFF" : "#3C3C4360",
  }),
  selectionContainer: {
    px: 1,
    mt: 2,
  },
  selectionLabel: ({ palette, breakpoints }, { isOwner }) => ({
    color: isOwner ? palette.white.main : "#5983EE",
    position: "absolute",
    left: "40px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [breakpoints.up("xxl")]: {
      maxWidth: "75%",
    },
    [breakpoints.up("xl")]: {
      maxWidth: "80%",
    },
    [breakpoints.up("lg")]: {
      maxWidth: "75%",
    },
    [breakpoints.up("md")]: {
      maxWidth: "70%",
    },
    [breakpoints.up("sm")]: {
      maxWidth: "60%",
    },
    [breakpoints.up("xs")]: {
      maxWidth: "70%",
    },
    fontSize: "15px",
  }),
  progressContainer: ({ breakpoints }, { isOwner }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
    border: isOwner ? `1.5px solid ${"#ffffff"}` : `1.5px solid ${"#5983EE"}`,
    borderRadius: "10px",
    minWidth: "300px",
    [breakpoints.down("lg")]: {
      minWidth: "230px",
    },
    [breakpoints.down("sm")]: {
      minWidth: "230px",
    },
    [breakpoints.down("xs")]: {
      minWidth: "180px",
    },
  }),

  progress: ({ breakpoints }, { isOwner }) => ({
    cursor: "pointer",
    minWidth: "220px",
    flexGrow: 1,
    backgroundColor: isOwner ? "rgba(89, 131, 238, 0.9)" : "#FFF",
    overflow: "hidden",
    borderRadius: "10px",
    height: "40px",
    "& .MuiLinearProgress-bar": {
      background: isOwner ? "#FFFFFF" : "#5983EE",
      transition: "background 0.5s",
      opacity: "0.3",
      height: "40px",
    },

    [breakpoints.down("lg")]: {
      minWidth: "230px",
    },
    [breakpoints.down("sm")]: {
      minWidth: "230px",
    },
    [breakpoints.down("xs")]: {
      minWidth: "180px",
    },
  }),
  progressUserVote: ({ breakpoints }, { isOwner }) => ({
    cursor: "pointer",
    minWidth: "220px",
    flexGrow: 1,
    backgroundColor: isOwner ? "#5983EE" : "#FFF",
    opacity: isOwner ? "0.3" : "1",
    overflow: "hidden",
    borderRadius: "10px",
    height: "40px",
    "& .MuiLinearProgress-bar": {
      background: isOwner ? "#ffff" : "rgba(89, 131, 238, 0.9)",
      transition: "background 0.5s",
      opacity: isOwner ? "1" : "0.2",
      height: "40px",
    },

    [breakpoints.down("lg")]: {
      minWidth: "230px",
    },
    [breakpoints.down("sm")]: {
      minWidth: "230px",
    },
    [breakpoints.down("xs")]: {
      minWidth: "180px",
    },
  }),
  iconContainer: ({ breakpoints }) => ({
    display: "flex",
    alignItems: "center",
    position: "absolute",
    [breakpoints.down("lg")]: {
      minWidth: "180px",
    },
    [breakpoints.down("sm")]: {
      minWidth: "140px",
    },
  }),
  iconButton: {
    ml: 1.5,
    p: 0,
    mr: 0.5,
  },
  iconLock: {
    ml: 1.5,
    p: 0,
    mr: 0.5,
    mb: -0.1,
    width: "16px",
  },
  selectNumber: ({ palette }, { isOwner }) => ({
    width: "26px",
    color: isOwner ? palette.white.main : palette.grey[400],
  }),
  rateNumber: ({ isOwner }) => ({
    color: isOwner ? "#FFFFFF" : "#5983EE",
    position: "absolute",
    right: "20px",
    "@media (max-width: 572px), (max-width: 480px)": {
      right: isOwner ? "20px" : "20px",
    },
  }),
  Voted: ({ isOwner }) => ({
    color: isOwner ? "#FFFFFF" : "#5983EE",
    position: "absolute",
    right: "10px",
    "@media (max-width: 572px), (max-width: 480px)": {
      right: isOwner ? "10px" : "10px",
    },
  }),
  voteNumber: ({ functions, palette }, { isOwner }) => ({
    color: isOwner ? functions.rgba(palette.white.main, 0.5) : palette.grey[300],
    mr: 1,
    fontSize: "13px",
  }),
  messageSendedIcon: {
    height: "14px",
    marginLeft: "8px",
  },
  messagePendingIcon: {
    height: "14px",
    width: "14px",
    marginLeft: "8px",
  },
  sendedIcon: {
    width: "22px",
    marginLeft: "8px",
  },
  emotionContainer: ({ palette, borders }, { isOwner }) => ({
    display: "flex",
    // border: `1.5px solid ${isOwner ? functions.rgba(palette.white.main, 0.6) : palette.white.main}`,
    // background: palette.white.main,
    // borderRadius: borders.borderRadius.round,

    background: isOwner ? palette.white.main : "",
    border: `1.5px solid ${palette.grey[250]}`,
    // background: palette.white.main,
    borderRadius: borders.borderRadius.round,
    p: 0.4,
    mx: 0.5,
    alignItems: "center",
    cursor: "pointer",
  }),
  emotionIcon: {
    height: "16px",
    width: "100%",
    maxWidth: "20px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  emotionCount: () => ({
    paddingLeft: "3px",
    paddingRight: "4px",
    color: "#898989",
    fontSize: "12px",
  }),
  tipIcon: {
    height: "16px",
    width: "100%",
    maxWidth: "30px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  tipCount: () => ({
    paddingLeft: "3px",
    paddingRight: "4px",
    minWidth: "30px",
    color: "#898989",
    fontSize: "12px",
  }),
};
