import { ReactComponent as DislikeIcon } from "assets/svg/icon/emotion_dislike.svg";
import { ReactComponent as FunIcon } from "assets/svg/icon/emotion_fun.svg";
import { ReactComponent as LikeIcon } from "assets/svg/icon/emotion_like.svg";
import { ReactComponent as SadIcon } from "assets/svg/icon/emotion_sad.svg";
import { ReactComponent as LoveIcon } from "assets/svg/icon/sub_emotion_love.svg";
import { ReactComponent as TipIcon } from "assets/svg/icon/tip_icon.svg";
// import { ReactComponent as PendingIcon } from "assets/svg/icon/pending.svg";

import { ReactComponent as SendIcon } from "assets/svg/icon/send.svg";
import { ReactComponent as SendedIcon } from "assets/svg/icon/sended.svg";

import { VALUE_ACTION_MESSAGE } from "constant/chat";
import styles from "assets/styles/pages/Chat/component/MessageNormal";
import { formatTimeSecond } from "utils/date";
import { Box, Grow, Typography } from "@mui/material";
import { convertNumber } from "utils/convert";
import { useTranslation } from "react-i18next";

export default function Emotion({ handleOpenViewerDialog, isOwner, value, absolute = false }) {
  const { t } = useTranslation();
  const group = () => {
    const result = {};
    value.likeUsers.forEach((item) => {
      if (item?.value) {
        if (result?.[item.value]) {
          result[item.value] += 1;
        } else {
          result[item.value] = 1;
        }
      }
    });

    return result;
  };

  return (
    <Box
      sx={{
        ...styles.messageTimeContainer,
        ...(absolute ? styles.footer : {}),
      }}
    >
      <Grow in={value?.tipUsers?.length > 0}>
        <Box
          sx={(e) => styles.emotionContainer(e, { isOwner })}
          onClick={() => handleOpenViewerDialog(value)}
        >
          <TipIcon style={styles.tipIcon} />
          <Typography sx={(e) => styles.tipCount(e, { isOwner })} variant="body2">
            {value?.tipUsers?.length > 0 &&
              convertNumber(
                value.tipUsers
                  .map((tip) => tip.value)
                  .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
              )}
          </Typography>
        </Box>
      </Grow>
      <Grow in={value?.likeUsers?.length > 0}>
        <Box
          sx={(e) => styles.emotionContainer(e, { isOwner })}
          onClick={() => handleOpenViewerDialog(value)}
        >
          {Object.keys(group()).map((key) => {
            switch (+key) {
              case +VALUE_ACTION_MESSAGE.LOVE:
                return <LoveIcon style={styles.emotionIcon} key={key} />;
              case +VALUE_ACTION_MESSAGE.FUN:
                return <FunIcon style={styles.emotionIcon} key={key} />;
              case +VALUE_ACTION_MESSAGE.LIKE:
                return <LikeIcon style={styles.emotionIcon} key={key} />;
              case +VALUE_ACTION_MESSAGE.DISLIKE:
                return <DislikeIcon style={styles.emotionIcon} key={key} />;
              case +VALUE_ACTION_MESSAGE.SAD:
                return <SadIcon style={styles.emotionIcon} key={key} />;
              default:
                return null;
            }
          })}

          {value.likeUsers.length > 1 && (
            <Typography sx={(e) => styles.emotionCount(e, { isOwner })} variant="body2">
              {value.likeUsers.length}
            </Typography>
          )}
        </Box>
      </Grow>
      {value?.edited && (
        <Typography sx={(e) => styles.messageEdit(e, { isOwner })} variant="body2">
          {t("chat.fixed")}
        </Typography>
      )}
      {/* send time */}
      <Typography sx={(e) => styles.messageTime(e, { isOwner })} variant="body2">
        {formatTimeSecond(value?.createdAt)}
      </Typography>
      {isOwner && (
        <>
          {/* {value.last_action === 1 && <PendingIcon style={styles.messagePendingIcon} />} */}
          {(value.last_action === 0 || value.last_action === 1) && (
            <SendIcon style={styles.messageSendedIcon} />
          )}
          {value.last_action === 2 && <SendedIcon style={styles.messageSendedIcon} />}
        </>
      )}
    </Box>
  );
}
