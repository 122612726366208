// styles.js

export default {
  ScoreBox: ({ borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.xl,
      },
    },
    height: "100%",
    textAlign: "center",
  }),
  ScoreDialog: {
    maxWidth: "100%",
    width: "100%",
  },

  title: {
    marginTop: "20px",
    mx: "auto",
  },
  endChannel: {
    textAlign: "right",
    right: 10,
    top: 10,
    position: "absolute",
    background: "#C3C3C3",
    borderRadius: "6px",
    zIndex: 200,
    fontWeight: 700,
    fontSize: "14px",
    px: 2,
    color: "#ffff",
    "&:hover": {
      background: "#C3C3C3",
    },
  },
  placeAndTime: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "25px",
    px: 1,
  },
  facilityContainer: {
    display: "flex",
    alignItems: "center",
  },
  facility: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xs,
    ml: 1,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    width: "100%",
    maxWidth: "250px",
  }),
  hole_index_text: ({ typography }) => ({
    ml: 1,
    color: "#000",
    fontFamily: typography.fontFamily3,
    fontSize: "13px",
    opacity: "0.7",
  }),
  hole_index: ({ typography }) => ({
    ml: 1,
    color: "#000",
    fontFamily: typography.fontFamily3,
    fontSize: "13px",
    fontWeight: 600,
  }),
  TeeContainer: {
    display: "flex",
  },
  boxTeeTime: ({ colorTee }) => ({
    background: colorTee,
    border: "1px solid #d9d9d9",
    width: "20px",
    mr: 1,
  }),
  TeamPlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  TeamRed: {
    marginRight: "5px",
    width: "100%",
  },
  imageBorderRed: {
    height: "140px",
    maxHeight: "140px",
    maxWidth: "270px",
  },
  TeamBlue: {
    marginLeft: "5px",
    width: "100px",
  },
  Typography: {
    margin: "0 10px",
    fontSize: "14px",
  },
  Score: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "35px",
  },
  ScoreTeamRed: {
    color: "red",
    fontWeight: 1000,
    fontSize: "30px",
    flex: 1,
    marginRight: "10px",
    marginLeft: "15px",
  },
  ScoreTeamBlue: {
    color: "blue",
    fontWeight: 1000,
    fontSize: "30px",
    flex: 1,
    marginLeft: "10px",
    marginRight: "15px",
  },
  TextABlue: {
    color: "#005DB4",
    fontWeight: 1000,
    fontSize: "35px",
    flex: 1,
    mr: 1,
  },
  TextSRed: {
    color: "red",
    fontWeight: 1000,
    fontSize: "35px",
    flex: 1,
  },
  TextScoreBlue: {
    color: "#005DB4",
    fontWeight: 1000,
    fontSize: "45px",
    flex: 1,
  },
  TextBlue: {
    color: "#005DB4",
    fontWeight: 1000,
    fontSize: "35px",
    flex: 1,
  },
  TextScoreRed: {
    color: "red",
    fontWeight: 1000,
    fontSize: "45px",
    flex: 1,
  },
  playerTeamRed: ({ indexUser, keyUser }) => ({
    position: "absolute",
    top:
      // eslint-disable-next-line no-nested-ternary
      indexUser > 2
        ? // eslint-disable-next-line no-nested-ternary
          `calc(40% - ${keyUser === 0 ? "50px" : keyUser === 1 ? "10px" : "-30px"})`
        : indexUser > 1
        ? `calc(40% - ${keyUser === 0 ? "40px" : "-20px"})`
        : "50px",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px",
  }),
  avatarContainer: ({ direction }) => ({
    mr: direction === "left" ? 1 : 0,
    ml: direction === "right" ? 1 : 0,
  }),
  avatar: {
    width: "35px",
    height: "35px",
  },
  playerTeamBlue: ({ indexUser, keyUser }) => ({
    position: "absolute",
    top:
      // eslint-disable-next-line no-nested-ternary
      indexUser > 2
        ? // eslint-disable-next-line no-nested-ternary
          `calc(40% - ${keyUser === 0 ? "50px" : keyUser === 1 ? "10px" : "-30px"})`
        : indexUser > 1
        ? `calc(40% - ${keyUser === 0 ? "40px" : "-20px"})`
        : "50px",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px",
    right: 0,
  }),
  ScoreRed: ({ indexUser, keyUser }) => ({
    position: "absolute",
    top:
      // eslint-disable-next-line no-nested-ternary
      indexUser > 2
        ? // eslint-disable-next-line no-nested-ternary
          `calc(40% - ${keyUser === 0 ? "63px" : keyUser === 1 ? "16px" : "-30px"})`
        : indexUser > 1
        ? `calc(40% - ${keyUser === 0 ? "50px" : "-25px"})`
        : "40px",
    right:
      // eslint-disable-next-line no-nested-ternary
      indexUser > 2 // eslint-disable-next-line no-nested-ternary
        ? `${keyUser === 0 ? "-10px" : keyUser === 1 ? "-25px" : "-10px"}`
        : indexUser > 1
        ? "-20px"
        : "-25px",
    flexDirection: "column",
    padding: "2px 10px",
  }),
  scoreContainer: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px",
  },
  imageCircle: {
    border: "2px solid #fff",
    borderRadius: "50%",
  },
  cap: {
    position: "absolute",
    color: "blue",
    fontWeight: 700,
  },
  scoreBlue: ({ indexUser, keyUser }) => ({
    position: "absolute",
    top:
      // eslint-disable-next-line no-nested-ternary
      indexUser > 2
        ? // eslint-disable-next-line no-nested-ternary
          `calc(40% - ${keyUser === 0 ? "63px" : keyUser === 1 ? "16px" : "-30px"})`
        : indexUser > 1
        ? `calc(40% - ${keyUser === 0 ? "50px" : "-25px"})`
        : "40px",
    left:
      // eslint-disable-next-line no-nested-ternary
      indexUser > 2 // eslint-disable-next-line no-nested-ternary
        ? `${keyUser === 0 ? "-10px" : keyUser === 1 ? "-25px" : "-10px"}`
        : indexUser > 1
        ? "-20px"
        : "-25px",
    flexDirection: "column",
    padding: "2px 10px",
  }),

  pagination: {
    textAlign: "left",
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "10px",
    display: "flex",
    justifyContent: "center",
  },
  holeContainer: ({ status }) => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    // eslint-disable-next-line no-nested-ternary
    background: status === "draw" ? "#d9d9d9" : status === "blue" ? "#005DB4" : status,
    mr: 1,
    border: status === "none" ? "1px solid #d9d9d9" : "none",
  }),
  hole: ({ status }) => ({
    color: status === "blue" || status === "red" ? "#FFFF" : "#000",
  }),
  holeNone: {
    transform: "rotate(120deg)",
    height: "2px",
    width: "100%",
    background: "#000",
    position: "absolute",
    opacity: 0.5,
  },
  fullname: ({ index }) => ({
    color: !index ? "#ffff" : "#000",
    fontSize: "14px",
  }),
  vID: ({ index }) => ({
    color: !index ? "#ffff" : "#999999",
    fontSize: "12px",
  }),
};
