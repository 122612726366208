// icon_golf
import birdie from "assets/stickers/icon_golf/birdie.png";
import eagle from "assets/stickers/icon_golf/eagle.png";
import gay_golf from "assets/stickers/icon_golf/gay_golf.png";
import gay_putter from "assets/stickers/icon_golf/gay_putter.png";
import hole_in_one from "assets/stickers/icon_golf/hole_in_one.png";
import longest from "assets/stickers/icon_golf/longest.png";
import near_line from "assets/stickers/icon_golf/near_line.png";
import near_pin from "assets/stickers/icon_golf/near_pin.png";
import OB from "assets/stickers/icon_golf/OB.png";
import par from "assets/stickers/icon_golf/par.png";
import score_card from "assets/stickers/icon_golf/score_card.png";
import tee_short from "assets/stickers/icon_golf/tee_short.png";
import water from "assets/stickers/icon_golf/water.png";

// smile
import embarrassed from "assets/stickers/smile/embarrassed.png";
import am_phu from "assets/stickers/smile/am_phu.png";
import angry from "assets/stickers/smile/angry.png";
import be_gay from "assets/stickers/smile/be_gay.png";
import bigcry from "assets/stickers/smile/bigcry.png";
import ca_muong from "assets/stickers/smile/ca_muong.png";
import ca_sau from "assets/stickers/smile/ca_sau.png";
import caddy from "assets/stickers/smile/caddy.png";
import comedown from "assets/stickers/smile/comedown.png";
import complex from "assets/stickers/smile/complex.png";
import cry from "assets/stickers/smile/cry.png";
import draw from "assets/stickers/smile/draw.png";
import excited from "assets/stickers/smile/excited.png";
import funny from "assets/stickers/smile/funny.png";
import happy from "assets/stickers/smile/happy.png";

// smile_2
import slice from "assets/stickers/smile_2/slice.png";
import hi from "assets/stickers/smile_2/hi.png";
import kiss from "assets/stickers/smile_2/kiss.png";
import kowtow_1 from "assets/stickers/smile_2/kowtow_1.png";
import kowtow_2 from "assets/stickers/smile_2/kowtow_2.png";
import laugh from "assets/stickers/smile_2/laugh.png";
import love from "assets/stickers/smile_2/love.png";
import sad from "assets/stickers/smile_2/sad.png";
import sap_ham from "assets/stickers/smile_2/sap_ham.png";
import sock from "assets/stickers/smile_2/sock.png";
import tan_nat from "assets/stickers/smile_2/tan_nat.png";
import team_chet from "assets/stickers/smile_2/team_chet.png";
import team_ruoi from "assets/stickers/smile_2/team_ruoi.png";
import tee_time from "assets/stickers/smile_2/tee_time.png";
import whocare from "assets/stickers/smile_2/whocare.png";

// icon_text

import down from "assets/stickers/icon_text/down.png";
import ball4 from "assets/stickers/icon_text/4ball.png";
import some4 from "assets/stickers/icon_text/4some.png";
import best_score from "assets/stickers/icon_text/best_score.png";
import cau_lac_bo from "assets/stickers/icon_text/cau_lac_bo.png";
import co_thuong from "assets/stickers/icon_text/co_thuong.png";
import duong_gay from "assets/stickers/icon_text/duong_gay.png";
import ganh_nuoc from "assets/stickers/icon_text/ganh_nuoc.png";
import match_play from "assets/stickers/icon_text/match_play.png";
import may_duoc_ty from "assets/stickers/icon_text/may_duoc_ty.png";
import outting from "assets/stickers/icon_text/outting.png";
import single_match from "assets/stickers/icon_text/single_match.png";
import stock_play from "assets/stickers/icon_text/stock_play.png";
import sut_moi from "assets/stickers/icon_text/sut_moi.png";
import take_ball from "assets/stickers/icon_text/take_ball.png";

// icon_text_2

import chen_gi_chen_lam_the from "assets/stickers/icon_text_2/chen_gi_chen_lam_the.png";
import anh_em_dau_500 from "assets/stickers/icon_text_2/500_anh_em_dau.png";
import bai_ban from "assets/stickers/icon_text_2/bai_ban.png";
import bat_dai from "assets/stickers/icon_text_2/bat_dai.png";
import bip from "assets/stickers/icon_text_2/bip.png";
import chao_thua from "assets/stickers/icon_text_2/chao_thua.png";
import chem_gio from "assets/stickers/icon_text_2/chem_gio.png";
import chen_tuyet_doi from "assets/stickers/icon_text_2/chen_tuyet_doi.png";
import chip_in from "assets/stickers/icon_text_2/chip_in.png";
import choi_hay_nghi_day from "assets/stickers/icon_text_2/choi_hay_nghi_day.png";
import co_ty_qua from "assets/stickers/icon_text_2/co_ty_qua.png";
import danh_xa_de_lam_gi from "assets/stickers/icon_text_2/danh_xa_de_lam_gi.png";
import day_mom from "assets/stickers/icon_text_2/day_mom.png";
import den_thoi_do_quen_di from "assets/stickers/icon_text_2/den_thoi_do_quen_di.png";
import di_rat_xa from "assets/stickers/icon_text_2/di_rat_xa.png";

// icon_text_3
import khoc_nhu_mua from "assets/stickers/icon_text_3/khoc_nhu_mua.png";
import choi_luon from "assets/stickers/icon_text_3/choi_luon.png";
import co_dao_duc from "assets/stickers/icon_text_3/co_dao_duc.png";
import co_hoc from "assets/stickers/icon_text_3/co_hoc.png";
import co_mon_nao_kho from "assets/stickers/icon_text_3/co_mon_nao_kho.png";
import co_oi_co_o_dau from "assets/stickers/icon_text_3/co_oi_co_o_dau.png";
import dong_chi_ho_cuoi from "assets/stickers/icon_text_3/dong_chi_ho_cuoi.png";
import kho_khan_nay_la from "assets/stickers/icon_text_3/kho_khan_nay_la.png";
import khoc_nhieu_qua from "assets/stickers/icon_text_3/khoc_nhieu_qua.png";
import khong_so_doi_thu_manh from "assets/stickers/icon_text_3/khong_so_doi_thu_manh.png";
import minh_thich_thi_minh from "assets/stickers/icon_text_3/minh_thich_thi_minh.png";
import mua_luc_nao from "assets/stickers/icon_text_3/mua_luc_nao.png";
import nguoi_am from "assets/stickers/icon_text_3/nguoi_am.png";
import nhan_van_qua from "assets/stickers/icon_text_3/nhan_van_qua.png";
import on_gioi_cau_day_roi from "assets/stickers/icon_text_3/on_gioi_cau_day_roi.png";

// icon_text_4

import san_nao_day from "assets/stickers/icon_text_4/san_nao_day.png";
import khong_vuong from "assets/stickers/icon_text_4/khong_vuong.png";
import loa_tau from "assets/stickers/icon_text_4/loa_tau.png";
import long_lay_qua from "assets/stickers/icon_text_4/long_lay_qua.png";
import mat_putt from "assets/stickers/icon_text_4/mat_putt.png";
import mat_swing from "assets/stickers/icon_text_4/mat_swing.png";
import putt_nhu_rong from "assets/stickers/icon_text_4/putt_nhu_rong.png";
import putt_xong_chip from "assets/stickers/icon_text_4/putt_xong_chip.png";
import san_khach from "assets/stickers/icon_text_4/san_khach.png";
import san_nha from "assets/stickers/icon_text_4/san_nha.png";
import tong_thong from "assets/stickers/icon_text_4/tong_thong.png";
import tri_tue from "assets/stickers/icon_text_4/tri_tue.png";
import tung_khan_thach_dau from "assets/stickers/icon_text_4/tung_khan_thach_dau.png";
import ve_hang from "assets/stickers/icon_text_4/ve_hang.png";
import vui_thoi_dung_vui_qua from "assets/stickers/icon_text_4/vui_thoi_dung_vui_qua.png";

// icon_text_5

import song_re from "assets/stickers/icon_text_5/song_re.png";
import swing_dep_lam from "assets/stickers/icon_text_5/swing_dep_lam.png";
import tang_tang from "assets/stickers/icon_text_5/tang_tang.png";
import thua_toan_tap from "assets/stickers/icon_text_5/thua_toan_tap.png";
import thuyen_qua from "assets/stickers/icon_text_5/thuyen_qua.png";
import up from "assets/stickers/icon_text_5/up.png";
import xi_ngau from "assets/stickers/icon_text_5/xi_ngau.png";
import y_chu_sao from "assets/stickers/icon_text_5/y_chu_sao.png";
import yeu_lam_co from "assets/stickers/icon_text_5/yeu_lam_co.png";

// birthday

import happy_birthday from "assets/stickers/birthday/happy_birthday.png";
import happy_birthday_to_you from "assets/stickers/birthday/happy_birthday_to_you.png";

export const Emojis = [
  {
    id: 6,
    name: "smile",
    images: [
      {
        uri: embarrassed,
        name: "embarrassed lúng túng",
      },
      {
        uri: am_phu,
        name: "vui vẻ smile cười",
      },
      {
        uri: angry,
        name: "angry tức giận",
      },
      {
        uri: be_gay,
        name: "khóc cry",
      },
      {
        uri: bigcry,
        name: "cry khóc",
      },
      {
        uri: ca_muong,
        name: "choáng váng catch up with",
      },
      {
        uri: ca_sau,
        name: "ngầu cool",
      },
      {
        uri: caddy,
        name: "caddy",
      },
      {
        uri: comedown,
        name: "mệt mỏi tired",
      },
      {
        uri: complex,
        name: "buồn sad",
      },
      {
        uri: cry,
        name: "khóc cry",
      },
      {
        uri: draw,
        name: "đánh golf draw",
      },
      {
        uri: excited,
        name: "hào hứng excited",
      },
      {
        uri: funny,
        name: "buồn cười funny",
      },
      {
        uri: happy,
        name: "happy hạnh phúc",
      },
    ],
  },
  {
    id: 7,
    name: "smile_2",
    images: [
      {
        uri: slice,
        name: "đánh golf slice",
      },
      {
        uri: hi,
        name: "cười vui vẻ hi",
      },
      {
        uri: kiss,
        name: "hôn kiss",
      },
      {
        uri: kowtow_1,
        name: "vui vẻ cute",
      },
      {
        uri: kowtow_2,
        name: "vui vẻ cute",
      },
      {
        uri: laugh,
        name: "cười vui vẻ hài hước",
      },
      {
        uri: love,
        name: "yêu love",
      },
      {
        uri: sad,
        name: "buồn sad",
      },
      {
        uri: sap_ham,
        name: "lái xe sập hầm drive down the tunnel",
      },
      {
        uri: sock,
        name: "sốc sock",
      },
      {
        uri: tan_nat,
        name: "tan nát broken",
      },
      {
        uri: team_chet,
        name: "team chết team dead",
      },
      {
        uri: team_ruoi,
        name: "team rưỡi",
      },
      {
        uri: tee_time,
        name: "tee time",
      },
      {
        uri: whocare,
        name: "không quan tâm dont care",
      },
    ],
  },
  {
    id: 0,
    name: "icon_goft",
    images: [
      {
        uri: birdie,
        name: "chim chết bird die",
      },
      {
        uri: eagle,
        name: "chim đại bàng eagle bird",
      },
      {
        uri: gay_golf,
        name: "gậy golf golf clubs",
      },
      {
        uri: gay_putter,
        name: "gậy putter",
      },
      {
        uri: hole_in_one,
        name: "hole in one golf",
      },
      {
        uri: longest,
        name: "longest golf",
      },
      {
        uri: near_line,
        name: "near line golf",
      },
      {
        uri: near_pin,
        name: "near pin golf",
      },
      {
        uri: OB,
        name: "ob golf",
      },
      {
        uri: par,
        name: "par golf",
      },
      {
        uri: score_card,
        name: "score card golf",
      },
      {
        uri: tee_short,
        name: "tee short golf",
      },
      {
        uri: water,
        name: "nước water",
      },
    ],
  },
  {
    id: 1,
    name: "icon_text",
    images: [
      {
        uri: down,
        name: "down xuống chữ text",
      },
      {
        uri: ball4,
        name: "4 ball chữ text",
      },
      {
        uri: some4,
        name: "4 some chữ text",
      },
      {
        uri: best_score,
        name: "best score golf",
      },
      {
        uri: cau_lac_bo,
        name: "câu lạc bộ club golf",
      },
      {
        uri: co_thuong,
        name: "cô thương text chữ teacher love",
      },
      {
        uri: duong_gay,
        name: "dương gậy golf stick",
      },
      {
        uri: ganh_nuoc,
        name: "gánh nước water carrier chữ text",
      },
      {
        uri: match_play,
        name: "match play text chữ",
      },
      {
        uri: may_duoc_ty,
        name: "may được tý text chữ lucky to have some text",
      },
      {
        uri: outting,
        name: "out ting golf text chữ",
      },
      {
        uri: single_match,
        name: "single match text chữ",
      },
      {
        uri: stock_play,
        name: "stock play text chữ",
      },
      {
        uri: sut_moi,
        name: "sứt môi text chữ",
      },
      {
        uri: take_ball,
        name: "take ball text chữ",
      },
    ],
  },
  {
    id: 2,
    name: "icon_text_2",
    images: [
      {
        uri: chen_gi_chen_lam_the,
        name: "chén gì chén lắm thế text chữ",
      },
      {
        uri: anh_em_dau_500,
        name: "500 anh em đâu text chữ",
      },
      {
        uri: bai_ban,
        name: "bài bản text chữ",
      },
      {
        uri: bat_dai,
        name: "bắt bài text chữ",
      },
      {
        uri: bip,
        name: "bịp text chữ",
      },
      {
        uri: chao_thua,
        name: "chào thua text chữ lost",
      },
      {
        uri: chem_gio,
        name: "chém gió text chữ",
      },
      {
        uri: chen_tuyet_doi,
        name: "chén tuyệt đối text chữ",
      },
      {
        uri: chip_in,
        name: "chip in text chữ",
      },
      {
        uri: choi_hay_nghi_day,
        name: "chơi hay nghỉ đây text chữ",
      },
      {
        uri: co_ty_qua,
        name: "có tý qua text chữ",
      },
      {
        uri: danh_xa_de_lam_gi,
        name: "đánh xa để làm gì text chữ",
      },
      {
        uri: day_mom,
        name: "đẫy mồm text chữ",
      },
      {
        uri: den_thoi_do_quen_di,
        name: "đen thôi đỏ quên đi text chữ",
      },
      {
        uri: di_rat_xa,
        name: "đi rất xa text chữ",
      },
    ],
  },
  {
    id: 3,
    name: "icon_text_3",
    images: [
      {
        uri: khoc_nhu_mua,
        name: "khóc như mưa text chữ",
      },
      {
        uri: choi_luon,
        name: "chơi luôn text chữ",
      },
      {
        uri: co_dao_duc,
        name: "có đạo đức text chữ",
      },
      {
        uri: co_hoc,
        name: "có học text chữ",
      },
      {
        uri: co_mon_nao_kho,
        name: "có món nào khó hơn không text chữ",
      },
      {
        uri: co_oi_co_o_dau,
        name: "cô ơi cô ở đâu text chữ",
      },
      {
        uri: dong_chi_ho_cuoi,
        name: "đồng chí hố cuối text chữ",
      },
      {
        uri: kho_khan_nay_la,
        name: "khó khăn này là có thật text chữ",
      },
      {
        uri: khoc_nhieu_qua,
        name: "khóc nhiều quá text chữ",
      },
      {
        uri: khong_so_doi_thu_manh,
        name: "không sợ đối thủ mạnh text chữ",
      },
      {
        uri: minh_thich_thi_minh,
        name: "mình thích thì mình chơi thôi text chữ",
      },
      {
        uri: mua_luc_nao,
        name: "mưa lúc nào có mặt lúc đấy text chữ",
      },
      {
        uri: nguoi_am,
        name: "người âm text chữ",
      },
      {
        uri: nhan_van_qua,
        name: "nhân văn quá text chữ",
      },
      {
        uri: on_gioi_cau_day_roi,
        name: "ơn giời cậu đây rồi text chữ",
      },
    ],
  },
  {
    id: 4,
    name: "icon_text_4",
    images: [
      {
        uri: san_nao_day,
        name: "sân nào đây text chữ",
      },
      {
        uri: khong_vuong,
        name: "không vướng text chữ",
      },
      {
        uri: loa_tau,
        name: "loa tầu text chữ",
      },
      {
        uri: long_lay_qua,
        name: "lộng lẫy quá text chữ",
      },
      {
        uri: mat_putt,
        name: "mất putt text chữ",
      },
      {
        uri: mat_swing,
        name: "mất swing text chữ",
      },
      {
        uri: putt_nhu_rong,
        name: "putt như rồng text chữ",
      },
      {
        uri: putt_xong_chip,
        name: "putt xong chip text chữ",
      },
      {
        uri: san_khach,
        name: "sân khách text chữ",
      },
      {
        uri: san_nha,
        name: "sân nhà text chữ",
      },
      {
        uri: tong_thong,
        name: "tổng thống text chữ",
      },
      {
        uri: tri_tue,
        name: "trí tuệ text chữ",
      },
      {
        uri: tung_khan_thach_dau,
        name: "tung khăn thách đấu text chữ",
      },
      {
        uri: ve_hang,
        name: "về hàng text chữ",
      },
      {
        uri: vui_thoi_dung_vui_qua,
        name: "vui thôi đừng vui quá text chữ",
      },
    ],
  },
  {
    id: 5,
    name: "icon_text_5",
    images: [
      {
        uri: song_re,
        name: "sóng rè text chữ",
      },
      {
        uri: swing_dep_lam,
        name: "swing đẹp lắm text chữ",
      },
      {
        uri: tang_tang,
        name: "tăngd tằng text chữ",
      },
      {
        uri: thua_toan_tap,
        name: "thua toàn tập text chữ",
      },
      {
        uri: thuyen_qua,
        name: "thuyền quá text chữ",
      },
      {
        uri: up,
        name: "up text chữ",
      },
      {
        uri: xi_ngau,
        name: "xí ngầu text chữ",
      },
      {
        uri: y_chu_sao,
        name: "ý chú sao text chữ",
      },
      {
        uri: yeu_lam_co,
        name: "yêu lắm cơ text chữ",
      },
    ],
  },
];
export const Stickers = [
  {
    id: 6,
    name: "smile",
    images: [
      embarrassed,
      am_phu,
      angry,
      be_gay,
      bigcry,
      ca_muong,
      ca_sau,
      caddy,
      comedown,
      complex,
      cry,
      draw,
      excited,
      funny,
      happy,
    ],
  },
  {
    id: 7,
    name: "smile_2",
    images: [
      slice,
      hi,
      kiss,
      kowtow_1,
      kowtow_2,
      laugh,
      love,
      sad,
      sap_ham,
      sock,
      tan_nat,
      team_chet,
      team_ruoi,
      tee_time,
      whocare,
    ],
  },
  {
    id: 0,
    name: "icon_golf",
    images: [
      birdie,
      eagle,
      gay_golf,
      gay_putter,
      hole_in_one,
      longest,
      near_line,
      near_pin,
      OB,
      par,
      score_card,
      tee_short,
      water,
    ],
  },
  {
    id: 1,
    name: "icon_text",
    images: [
      down,
      ball4,
      some4,
      best_score,
      cau_lac_bo,
      co_thuong,
      duong_gay,
      ganh_nuoc,
      match_play,
      may_duoc_ty,
      outting,
      single_match,
      stock_play,
      sut_moi,
      take_ball,
    ],
  },
  {
    id: 2,
    name: "icon_text_2",
    images: [
      chen_gi_chen_lam_the,
      anh_em_dau_500,
      bai_ban,
      bat_dai,
      bip,
      chao_thua,
      chem_gio,
      chen_tuyet_doi,
      chip_in,
      choi_hay_nghi_day,
      co_ty_qua,
      danh_xa_de_lam_gi,
      day_mom,
      den_thoi_do_quen_di,
      di_rat_xa,
    ],
  },
  {
    id: 3,
    name: "icon_text_3",
    images: [
      khoc_nhu_mua,
      choi_luon,
      co_dao_duc,
      co_hoc,
      co_mon_nao_kho,
      co_oi_co_o_dau,
      dong_chi_ho_cuoi,
      kho_khan_nay_la,
      khoc_nhieu_qua,
      khong_so_doi_thu_manh,
      minh_thich_thi_minh,
      mua_luc_nao,
      nguoi_am,
      nhan_van_qua,
      on_gioi_cau_day_roi,
    ],
  },
  {
    id: 4,
    name: "icon_text_4",
    images: [
      san_nao_day,
      khong_vuong,
      loa_tau,
      long_lay_qua,
      mat_putt,
      mat_swing,
      putt_nhu_rong,
      putt_xong_chip,
      san_khach,
      san_nha,
      tong_thong,
      tri_tue,
      tung_khan_thach_dau,
      ve_hang,
      vui_thoi_dung_vui_qua,
    ],
  },
  {
    id: 5,
    name: "icon_text_5",
    images: [
      song_re,
      swing_dep_lam,
      tang_tang,
      thua_toan_tap,
      thuyen_qua,
      up,
      xi_ngau,
      y_chu_sao,
      yeu_lam_co,
    ],
  },
  {
    id: 8,
    name: "birthday",
    images: [happy_birthday, happy_birthday_to_you],
  },
];

// export default Images;
