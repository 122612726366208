import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import { createRef, useEffect, useMemo, useRef, useState } from "react";

import styles from "assets/styles/pages/Chat/section/Footer";
import { Chip, Divider, TextField, Typography } from "@mui/material";
import { ReactComponent as InsertEmoticonIcon } from "assets/svg/icon/emoji_icon.svg";
import { ReactComponent as TickerIcon } from "assets/svg/icon/tickerIcon.svg";
import { ReactComponent as TickerIconActive } from "assets/svg/icon/tickerIconActive.svg";
import { ReactComponent as InsertEmoticonActiveIcon } from "assets/svg/icon/emoji_icon_active.svg";
import { ReactComponent as BarChartIcon } from "assets/svg/icon/poll_icon.svg";
import { ReactComponent as BarChartActiveIcon } from "assets/svg/icon/poll_icon_active.svg";
import { ReactComponent as AttachFileActiveIcon } from "assets/svg/icon/attach_icon_active.svg";
import { ReactComponent as AttachFileIcon } from "assets/svg/icon/attach_icon.svg";
import { ReactComponent as CloseVoiceIcon } from "assets/svg/icon/close_voice.svg";
import { ReactComponent as CircleVoiceIcon } from "assets/svg/icon/circle_voice.svg";
import { ReactComponent as Share } from "assets/svg/icon/share.svg";
import { ReactComponent as NhapDiem } from "assets/svg/icon/nhap_diem.svg";
import { ReactComponent as KetThuc } from "assets/svg/icon/ket_thuc.svg";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/validate";
import { useParams } from "react-router-dom";
import { getUserToken } from "utils/storage";
import { debounce } from "lodash";

import EditMessage from "../component/EditMessage";
import ReplyMessage from "../component/ReplyMessage";
import TickerPopper from "../component/TickerPopper";
import PollPopperVote from "../component/PollPopperVote";
import LoadingFooter from "../loading/Footer";
import TipPopup from "../component/TipPopup";
import EmojiPopper from "../component/EmojiPopper";
import MessageTagName from "../component/MessageTagName";
import InputPoint from "../component/InputPoint";
import ShareChannelTV from "../component/ShareChannelTV";

function Footer({
  handleSend,
  textRef,
  isVoice,
  setIsVoice,
  time,
  // setTime,
  startRecording,
  togglePauseResume,
  images,
  onPaste,
  handleDeleteImage,
  sendRef,
  handleKeypress,
  openEditMessage,
  handleCloseEditMessage,
  openReplyMessage,
  handleCloseReplyMessage,
  replyObject,
  isBlock,
  isFetchingNewGroup,
  handleUploadImage,
  disabled,
  isOpenTipMessage,
  handleCloseTip,
  handleTipMessage,
  pointUser,
  handleSendTicker,
  prevText,
  handleVotingMessage,
  detailGroup,
  detailChanel,
  // groupDetailKenhTv,
  confirmEnterPoint,
  listPoint,
  setListPoint,
  handleOpenForwardDialog,
  setSharedLink,
}) {
  const userInformation = getUserToken();

  const { vID } = useParams();
  const [openSetting, setOpenSetting] = useState(false);
  const [openVote, setOpenVote] = useState(false);
  const list = [{ value: "" }, { value: "" }];
  const [listVote, setListVote] = useState(list);
  const arrLength = listVote.length;
  const [elRefs, setElRefs] = useState([]);
  const boxRef = useRef();
  const [isMaxOption, setIsMaxOption] = useState(false);
  const [active, setActive] = useState(null);
  const [textQuestion, setTextQuestion] = useState("");
  const inputQuestionRef = useRef("");
  const [isActive, setIsActive] = useState(false);
  const [selectMulti, setSelectMulti] = useState(false);
  const [openShowEmoji, setOpenShowEmoji] = useState(false);
  const isGroupType = detailGroup?.type === "group";
  const [unsentMessages, setUnsentMessages] = useState({});
  const containerRef = useRef();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOpenTagName, setIsOpenTagName] = useState(false);
  const [openInputPoint, setOpenInputPoint] = useState(false);
  const [openShareChannel, setOpenShareChannel] = useState(false);
  const [isHoveredEmoji, setIsHoveredEmoji] = useState(false);
  const [isHoveredSticker, setIsHoveredSticker] = useState(false);
  const [isHoveredVote, setIsHoveredVote] = useState(false);
  const [isHoveredFile, setIsHoveredFile] = useState(false);
  const { t } = useTranslation();
  const handleVoice = () => {
    startRecording();
    setIsVoice(true);
  };
  const getTimeAudio = useMemo(() => {
    const duration = moment.duration(time, "seconds");
    return moment.utc(duration.asMilliseconds()).format("mm:ss");
  }, [time, isVoice]);
  useEffect(() => {
    if (openReplyMessage || openEditMessage) textRef?.current?.focus();
  }, [openReplyMessage, openEditMessage]);
  const handleCloseVoice = () => {
    togglePauseResume();
    setIsVoice(false);
  };

  const handleSendMessage = debounce(handleKeypress, 50);

  const checkIt = () => {
    setActive(null);
    document.body.onfocus = null;
    setActive(null);
  };

  const initialize = () => {
    setActive(3);
    document.body.onfocus = checkIt;
  };

  useEffect(() => {
    setElRefs((eR) =>
      Array(arrLength)
        .fill()
        .map((_, i) => eR[i] || createRef())
    );
  }, [arrLength]);

  useEffect(() => {
    if (boxRef?.current?.scrollTop) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight + 300;
    }
  }, [arrLength]);

  const handleClose = () => {
    setActive(null);
    setOpenSetting(false);
  };

  const handleOpen = () => {
    setActive(1);
    setOpenSetting((prevOpen) => !prevOpen);
  };

  const handleCloseVote = () => {
    setActive(null);
    setOpenVote(false);
    setListVote(list);
    setTextQuestion("");
    setIsMaxOption(false);
  };

  const handleOpenVote = () => {
    setActive(2);
    setOpenVote((prevOpen) => !prevOpen);
  };

  const handleAddVote = () => {
    if (arrLength <= 10) {
      setListVote([...listVote, { value: "" }]);
    } else {
      setIsMaxOption(true);
    }
  };

  const handleChange = (event, index) => {
    const cpList = [...listVote];
    cpList[index].value = event.target.value;
    setListVote(cpList);
  };

  const handleTextQuestion = (event) => {
    setTextQuestion(event.target.value);
  };
  useEffect(() => {
    if (
      inputQuestionRef?.current?.value &&
      listVote.map((item) => item.value).filter((item) => item.length > 0).length >= 2
    )
      setIsActive(true);
    else setIsActive(false);
  }, [listVote, textQuestion]);
  /* eslint no-param-reassign: ["error", { "props": false }] */

  useEffect(() => {
    const setRefValue = (ref, value) => {
      if (ref && ref.current) {
        ref.current.value = value;
      }
    };
    if (unsentMessages[vID]) {
      setRefValue(textRef, unsentMessages[vID]);
    } else {
      setRefValue(textRef, "");
    }
  }, [vID]);
  const handleCreatePoll = () => {
    const listVoteFinal = listVote.map((item) => item.value).filter((item) => item.length > 0);
    // eslint-disable-next-line no-console
    handleVotingMessage(selectMulti, textQuestion, listVoteFinal);
    setOpenVote((prevOpen) => !prevOpen);
    setActive(null);
    setListVote(list);
    setTextQuestion("");
    setSelectMulti(false);
  };
  const handleOpenEmoji = () => {
    setActive(4);
    setOpenShowEmoji((prevOpen) => !prevOpen);
  };
  const handleCloseEmoji = () => {
    setActive(null);
    setOpenShowEmoji(false);
  };
  const handleKeypressUp = (event) => {
    if (detailGroup?.users?.length > 2 && event.key === " " && isOpenTagName) {
      event.preventDefault();
      setIsOpenTagName(false);
    }
    if (
      detailGroup?.users?.length > 2 &&
      event.key === "@" &&
      textRef.current.value.slice(-1) === "@"
    ) {
      setFilteredUsers(detailGroup?.users?.filter((item) => +item.id !== +userInformation.id));
      setIsOpenTagName(true);
    }
  };
  const handleMessage = () => {
    // setMessage(e.target.value);
    if (
      textRef.current.value !== "" &&
      detailGroup?.users?.length > 2 &&
      textRef.current.value.split(" ")[textRef.current.value.split(" ").length - 1].includes("@")
    ) {
      const mentionRegex = /@(\w+)/g;
      const mentions = textRef.current.value.match(mentionRegex);
      if (mentions) {
        if (!isOpenTagName) {
          setIsOpenTagName(true);
        }

        // Lấy nhắc đến tên cuối cùng trong danh sách nhắc đến tên.
        const lastMention = mentions[mentions.length - 1];

        // Lấy tên người dùng cần gợi ý.
        const messageName = lastMention
          .substring(1)
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/đ/g, "d")
          .replace(/Đ/g, "D")
          ?.toLowerCase();

        // Lọc danh sách người dùng theo tên người dùng cần gợi ý.
        const filter = detailGroup?.users
          ?.filter((item) => +item.id !== +userInformation.id)
          .filter((user) => {
            const username = user.username
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/đ/g, "d")
              .replace(/Đ/g, "D")
              ?.toLowerCase();

            return username.includes(messageName) && +user.id !== +userInformation.id;
          });

        // Cập nhật danh sách người dùng được gợi ý.
        setFilteredUsers(filter);
        setSelectedIndex(0);
        // Nếu không có người dùng nào được tìm thấy, hãy đóng danh sách gợi ý người dùng.
        // if (filter.length === 0) {
        //   setIsOpenTagName(false);
        // }
      } else {
        setIsOpenTagName(false);
        setSelectedIndex(0);
      }
    } else {
      setIsOpenTagName(false);
      setSelectedIndex(0);
    }
    // setMessageTag(e.target.value);
  };
  const handleUserClick = (user) => {
    if (textRef.current.value.slice(-1) === "@") {
      const text = textRef.current.value.replace(/@$/, `@${user.username} - ${user.id} `);
      // eslint-disable-next-line no-param-reassign
      textRef.current.value = text;
    } else {
      const fill = textRef.current.value.replace(/@\w+$/, `@${user.username} - ${user.id} `);
      // eslint-disable-next-line no-param-reassign
      textRef.current.value = fill;
    }
    // setMessage(messageChat.replace(/@\w+$/, `@${user.username}`));
    setIsOpenTagName(false);
    setSelectedIndex(0);
    textRef.current.focus();
  };
  const onClickEmoji = (emojiData) => {
    const emoji = emojiData.isCustom ? emojiData.unified : emojiData.emoji;
    textRef.current.value += emoji;
  };
  if (disabled || isEmpty(vID)) return null;

  // cap nhap diem
  const handleClickOpenInputPoint = () => {
    setOpenInputPoint(true);
  };

  const handleCloseInputPoint = () => {
    setOpenInputPoint(false);
    setListPoint(detailChanel?.flight?.score);
  };
  const handleClickOpenShareChanel = () => {
    setOpenShareChannel(true);
  };

  const handleCloseShare = () => {
    setOpenShareChannel(false);
  };
  const handleMouseEnterEmoji = () => {
    setIsHoveredEmoji(true);
  };

  const handleMouseLeaveEmoji = () => {
    setIsHoveredEmoji(false);
  };
  const handleMouseEnterSticker = () => {
    setIsHoveredSticker(true);
  };

  const handleMouseLeaveSticker = () => {
    setIsHoveredSticker(false);
  };
  const handleMouseEnterVote = () => {
    setIsHoveredVote(true);
  };

  const handleMouseLeaveVote = () => {
    setIsHoveredVote(false);
  };
  const handleMouseEnterFile = () => {
    setIsHoveredFile(true);
  };
  const handleMouseLeaveFile = () => {
    setIsHoveredFile(false);
  };
  const reporter = detailChanel?.journalists;
  const isUserReporter = reporter && reporter.includes(String(userInformation?.id));
  if (isFetchingNewGroup) return <LoadingFooter />;
  return (
    <Box sx={styles.containerFooter}>
      {!isBlock ? (
        <Box ref={containerRef}>
          <Box sx={(e) => styles.container1(e, { openEditMessage, openReplyMessage })}>
            <Box ref={boxRef} sx={styles.container}>
              <Box sx={(e) => styles.flexBoxRight1(e, isVoice)}>
                <Box sx={styles.listIcon}>
                  <Box sx={styles.positionCenter}>
                    <IconButton
                      onClick={handleOpenEmoji}
                      onMouseEnter={handleMouseEnterEmoji}
                      onMouseLeave={handleMouseLeaveEmoji}
                      sx={{
                        path: {
                          fill: isHoveredEmoji ? "#406EE2" : "none",
                        },
                      }}
                    >
                      {active === 4 ? (
                        <InsertEmoticonActiveIcon sx={styles.fillColorIconLeft} />
                      ) : (
                        <InsertEmoticonIcon sx={styles.fillColorIconLeft} />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={handleOpen}
                      onMouseEnter={handleMouseEnterSticker}
                      onMouseLeave={handleMouseLeaveSticker}
                      sx={{
                        path: {
                          fill: isHoveredSticker ? "#406EE2" : "#9A9A9A",
                        },
                      }}
                    >
                      {active === 1 ? (
                        <TickerIconActive sx={styles.fillColorIconLeft} />
                      ) : (
                        <TickerIcon sx={styles.fillColorIconLeft} />
                      )}
                    </IconButton>
                    {/* ---DISABLE--- */}
                    {isGroupType && (
                      <IconButton
                        onClick={handleOpenVote}
                        onMouseEnter={handleMouseEnterVote}
                        onMouseLeave={handleMouseLeaveVote}
                        sx={{
                          ...styles.pIcon,
                          path: {
                            fill: isHoveredVote ? "#406EE2" : "#9A9A9A",
                          },
                        }}
                      >
                        {active === 2 ? (
                          <BarChartActiveIcon sx={styles.fillColorIconLeft} />
                        ) : (
                          <BarChartIcon sx={styles.fillColorIconLeft} />
                        )}
                      </IconButton>
                    )}
                    <IconButton
                      onClick={initialize}
                      component="label"
                      onChange={handleUploadImage}
                      onMouseEnter={handleMouseEnterFile}
                      onMouseLeave={handleMouseLeaveFile}
                      sx={{
                        path: {
                          fill: isHoveredFile ? "#406EE2" : "#9A9A9A",
                        },
                      }}
                    >
                      <input hidden id="file_upload" type="file" multiple />
                      {active === 3 ? (
                        <AttachFileActiveIcon sx={styles.fillColorIconLeft} />
                      ) : (
                        <AttachFileIcon sx={styles.fillColorIconLeft} />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              {detailGroup?.channel && isUserReporter && (
                <Box sx={styles.listIcon}>
                  <Chip
                    icon={<NhapDiem />}
                    label={t("footer_channel_tv.input_point")}
                    style={{ color: "#406EE2", background: "#E4EFFF", marginRight: "10px" }}
                    onClick={handleClickOpenInputPoint}
                  />
                  <Chip
                    icon={<Share />}
                    label={t("footer_channel_tv.share")}
                    style={{ color: "#406EE2", background: "#E4EFFF", marginRight: "10px" }}
                    onClick={handleClickOpenShareChanel}
                  />
                  <Chip
                    icon={<KetThuc />}
                    label={t("footer_channel_tv.the_end")}
                    style={{ color: "#406EE2", background: "#E4EFFF", marginRight: "10px" }}
                  />
                </Box>
              )}
              <TickerPopper
                handleSendTicker={handleSendTicker}
                openSetting={openSetting}
                anchor={containerRef.current}
                handleClose={handleClose}
              />
              <EmojiPopper
                onClickEmoji={onClickEmoji}
                openSetting={openShowEmoji}
                anchor={containerRef.current}
                handleClose={handleCloseEmoji}
              />
            </Box>
            <Divider />
            <ReplyMessage
              reply={replyObject}
              openEditMessage={openEditMessage}
              openReplyMessage={openReplyMessage}
              handleCloseReplyMessage={handleCloseReplyMessage}
            />
            <EditMessage
              openReplyMessage={openReplyMessage}
              openEditMessage={openEditMessage}
              handleCloseEditMessage={handleCloseEditMessage}
            />
            <Box ref={boxRef} sx={styles.container}>
              <Box sx={(e) => styles.flexBoxRight2(e, isVoice)}>
                <Box sx={(e) => styles.flexGrowResponsive(e, images)}>
                  <TextField
                    onPaste={onPaste}
                    autoFocus
                    multiline
                    inputRef={textRef}
                    maxRows={5}
                    placeholder={t("chat.footer.text_input")}
                    onChange={(e) => {
                      handleMessage();
                      setUnsentMessages((prev) => ({
                        ...prev,
                        [vID]: e.target.value,
                      }));
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" && detailGroup?.users?.length > 2 && isOpenTagName) {
                        e.preventDefault();
                        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                      } else if (
                        e.key === "ArrowDown" &&
                        detailGroup?.users?.length > 2 &&
                        isOpenTagName
                      ) {
                        e.preventDefault();
                        setSelectedIndex((prevIndex) =>
                          Math.min(prevIndex + 1, filteredUsers.length - 1)
                        );
                      }

                      if (e && e.key === "Enter" && !e.nativeEvent.shiftKey && isOpenTagName) {
                        e.preventDefault();
                        handleUserClick(filteredUsers[selectedIndex]);
                      } else if (
                        e &&
                        e.key === "Enter" &&
                        !e.nativeEvent.shiftKey &&
                        !isOpenTagName
                      ) {
                        setUnsentMessages((prev) => {
                          delete prev[vID];
                          return prev;
                        });
                        handleSendMessage(e);
                      }
                    }}
                    onKeyUp={handleKeypressUp}
                    InputProps={{ autoComplete: "off" }}
                    sx={styles.inputMessage}
                  />
                  {/* {coloredInput} */}
                  <Box sx={styles.pasteInputBox}>
                    {images.map((item, i) => (
                      <Box key={i}>
                        <Box
                          key={i}
                          sx={styles.pasteInputImage}
                          component="img"
                          src={item.preview}
                          alt="i"
                        />
                        <IconButton
                          onClick={(e) => handleDeleteImage(e, i)}
                          sx={styles.pasteInputClose}
                        >
                          <CloseIcon sx={styles.iconW18} />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.boxRight}>
                <Box sx={styles.flexEnd}>
                  {isVoice && (
                    <Box sx={styles.flexEnd}>
                      <IconButton>
                        <CircleVoiceIcon sx={styles.fillColorWhite} />
                      </IconButton>
                      <Box sx={styles.time}>{getTimeAudio}</Box>
                      <IconButton onClick={handleCloseVoice} sx={styles.closeVoiceIcon}>
                        <CloseVoiceIcon />
                      </IconButton>
                    </Box>
                  )}
                  {!openEditMessage &&
                    textRef?.current?.value === "" &&
                    !isVoice &&
                    images.length === 0 &&
                    !openShowEmoji && (
                      <IconButton sx={styles.fillColorIconRight} onClick={handleVoice}>
                        <KeyboardVoiceIcon sx={styles.fillColorWhite} />
                      </IconButton>
                    )}
                  {!openEditMessage &&
                    (textRef?.current?.value !== "" ||
                      isVoice ||
                      images.length > 0 ||
                      openShowEmoji) && (
                      <IconButton
                        ref={sendRef}
                        sx={styles.fillColorIconRight}
                        onClick={() => {
                          setUnsentMessages((prev) => {
                            delete prev[vID];
                            return prev;
                          });
                          handleSend();
                        }}
                      >
                        <SendIcon sx={styles.fillColorWhite} />
                      </IconButton>
                    )}
                  {openEditMessage && (
                    <IconButton
                      disabled={
                        textRef?.current?.value === "" ||
                        prevText?.localeCompare(textRef?.current?.value) === 0
                      }
                      onClick={() => {
                        handleSend();
                      }}
                      sx={() =>
                        styles.fillColorIconRight({
                          isChangeText:
                            textRef?.current?.value === "" ||
                            prevText?.localeCompare(textRef?.current?.value) === 0,
                        })
                      }
                    >
                      <CheckIcon sx={styles.fillColorWhite} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <InputPoint
            detailChanel={detailChanel}
            onClose={handleCloseInputPoint}
            isOpenInputPoint={openInputPoint}
            confirmEnterPoint={confirmEnterPoint}
            listPoint={listPoint}
            setListPoint={setListPoint}
          />
          <ShareChannelTV
            onClose={handleCloseShare}
            isOpen={openShareChannel}
            detailGroup={detailGroup}
            handleOpenForwardDialog={handleOpenForwardDialog}
            setSharedLink={setSharedLink}
          />
          <PollPopperVote
            openVote={openVote}
            textQuestion={textQuestion}
            inputQuestionRef={inputQuestionRef}
            boxRef={boxRef}
            listVote={listVote}
            elRefs={elRefs}
            isMaxOption={isMaxOption}
            isActive={isActive}
            setSelectMulti={setSelectMulti}
            handleCloseVote={handleCloseVote}
            handleTextQuestion={handleTextQuestion}
            setListVote={setListVote}
            handleChange={handleChange}
            handleAddVote={handleAddVote}
            handleCreatePoll={handleCreatePoll}
            handleVotingMessage={handleVotingMessage}
          />
          <TipPopup
            pointUser={pointUser}
            handleTip={handleTipMessage}
            openVote={isOpenTipMessage}
            handleClose={handleCloseTip}
          />
          {isOpenTagName && (
            <MessageTagName
              anchor={containerRef.current}
              handleUserClick={handleUserClick}
              isOpenTagName={isOpenTagName}
              handleClose={() => setIsOpenTagName(false)}
              filteredUsers={
                filteredUsers.length > 0 || isOpenTagName
                  ? filteredUsers
                  : detailGroup?.users?.filter((item) => +item.id !== +userInformation.id)
              }
              handleItemClick={handleUserClick}
              selectedIndex={selectedIndex}
              // listRef={listRef}
            />
          )}
        </Box>
      ) : (
        <Box sx={styles.blockBox}>
          <Typography variant="caption" sx={styles.blockTitle}>
            {t("chat.footer.notification_un_block")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default Footer;
