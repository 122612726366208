import { Buffer } from "buffer";
import { t } from "i18next";
import pako from "pako";

const zip = (data) =>
  Buffer.from(pako.deflate(Buffer.from(JSON.stringify(data))), "binary").toString("base64");

const unzip = (base64) =>
  JSON.parse(
    Buffer.from(pako.inflate(new Uint8Array(Buffer.from(base64, "base64"))), "binary").toString(
      "utf-8"
    )
  );

const formatGroup = (element) => ({
  group_permission: element.group_permission,
  id: element.id,
  image: element.image,
  is_brandname: element.is_brandname,
  is_club: element.is_club,
  is_mute: element.is_mute,
  is_private: element.is_private,
  name: element.name,
  review: element.review,
  type: element.type,
  vsim_display: element.vsim_display,
});

const formatGroupDetail = (groupDetail) => ({
  admin_ids: groupDetail.admin_ids,
  expire_time: groupDetail.expire_time,
  group_permission: groupDetail.group_permission,
  id: groupDetail.id,
  image: groupDetail.image,
  image_original: groupDetail.image_original,
  is_block: groupDetail.is_block,
  is_brandname: groupDetail.is_brandname,
  is_club: groupDetail.is_club,
  is_in_group: groupDetail.is_in_group,
  is_mute: groupDetail.is_mute,
  is_private: groupDetail.is_private,
  name: groupDetail.name,
  review: groupDetail.review,
  total_member: groupDetail.total_member,
  type: groupDetail.type,
  users: groupDetail.users,
});

const compareGroup = (idbGroup, fetchGroup) => {
  // false: similar, true: different
  if (!fetchGroup) return false;
  if (!idbGroup) return true;
  if (JSON.stringify(formatGroup(idbGroup)) === JSON.stringify(formatGroup(fetchGroup)))
    return false;
  return true;
};

const compareGroupDetail = (idbGroupDetail, fetchGroupDetail) => {
  if (!fetchGroupDetail) return false;
  if (!idbGroupDetail) return true;
  if (
    JSON.stringify(formatGroupDetail(idbGroupDetail)) ===
    JSON.stringify(formatGroupDetail(fetchGroupDetail))
  )
    return false;
  return true;
};

const GetLastMessage = (message) => {
  if (/^system_message\./.test(message)) return t(message);
  return message;
};

export { zip, unzip, compareGroup, compareGroupDetail, GetLastMessage };
