import { Avatar, Box, Chip, Drawer, IconButton, Stack, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/ListMember";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserToken } from "utils/storage";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as SearchIcon } from "assets/svg/icon/search.svg";
import { ReactComponent as CallIcon } from "assets/svg/icon/call.svg";
import { ReactComponent as MessgageIcon } from "assets/svg/icon/message_1.svg";
import { ReactComponent as CloseText } from "assets/svg/icon/close_text.svg";
import ListMemberAction from "./ListMemberAction";
// import InformationUserDialog from "./InformationUserDialog";

function ListMember({
  handleCloseListMember,
  isMediumSize,
  isOpenListMemberDialog,
  handleOpenAddMemberChat,
  group,
  isAdmin,
  handleChangePermissionGroup,
  handleRemoveUser,
  addGroupChat,
  handleOpenNotificationAdminDialog,
  openNotificationAdminDialog,
  handleCloseNotificationAdminDialog,
  addReporterChanelTv,
  removeReporterChanelTv,
  infoChanel,
}) {
  // const [active, setActive] = useState(0);
  const [anchorListMember, setAnchorListMember] = useState();
  const [select, setSelect] = useState(null);
  const [openPopperListMember, setOpenPopperListMember] = useState(false);
  const { t } = useTranslation();
  const userInformation = getUserToken();
  const [isClicked, setIsClicked] = useState(false);
  const textInputRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const handleOpenListMemberPopper = (event, item) => {
    event.preventDefault();
    setSelect(item);
    setAnchorListMember(event.currentTarget);
    setOpenPopperListMember(true);
  };
  const handleCloseListMemberPopper = () => {
    setSelect(null);
    setOpenPopperListMember(false);
  };
  // List admin
  const adminUsers = group?.users
    ?.filter((item) => group?.admin_ids.includes(item?.id))
    .filter(
      (item) =>
        item?.username?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.id.toString().includes(searchValue?.toLowerCase())
    )
    .sort((a, b) => a?.username?.localeCompare(b?.username));
  // list member
  const memberUsers = group?.users
    ?.filter((item) => !group?.admin_ids.includes(item?.id))
    .filter(
      (item) =>
        item?.username?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.id.toString().includes(searchValue?.toLowerCase())
    )
    .sort((a, b) => a?.username?.localeCompare(b?.username));
  const reporterIds = infoChanel?.journalists;

  // list id người chơi
  const player = [
    ...(infoChanel?.user_plays?.team1 || []),
    ...(infoChanel?.user_plays?.team2 || []),
  ];
  // list nguoi choi
  const listPlayer = group?.users?.filter((user) => player.includes(user?.id));
  // list thành viên
  const listMember = memberUsers?.filter(
    (user) => !reporterIds?.includes(String(user?.id)) && !player.includes(user?.id)
  );
  const members = group?.channel === "livechat" ? listMember : memberUsers;
  // list phóng viên
  const listReporter = group?.users?.filter((user) => reporterIds?.includes(user?.id.toString()));
  const handleTextFieldChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleTextFieldClick = () => {
    setIsClicked(true);
  };
  const handleTextFieldBlur = () => {
    setIsClicked(false);
  };
  const handleClearTextUserList = () => {
    setSearchValue("");
    textInputRef.current.focus();
    setIsClicked(false);
  };

  const handleAddAndRemoveReporter = (idMember) => {
    // eslint-disable-next-line no-unused-expressions
    reporterIds.includes(idMember.toString())
      ? removeReporterChanelTv(idMember)
      : addReporterChanelTv(idMember);
  };
  return (
    <>
      <Drawer
        sx={(e) => styles.drawer(e, { isOpenListMemberDialog })}
        onClose={handleCloseListMember}
        anchor="right"
        open={isOpenListMemberDialog}
        variant={!isMediumSize ? "permanent" : "temporary"}
      >
        <Box sx={styles.fLexCol}>
          <Box sx={styles.dialogTitle}>
            <IconButton onClick={handleCloseListMember}>
              <ArrowBackIosIcon sx={styles.arrowIcon} />
            </IconButton>
            <Typography variant="body1" color="initial" sx={styles.title}>
              {t("chat.list_member.participants")}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            marginLeft: "25px",
            marginRight: "25px",
            marginBottom: "25px",
            marginTop: "5px",
          }}
        >
          <TextField
            placeholder={t("chat.user_list.search")}
            value={searchValue}
            onChange={handleTextFieldChange}
            onClick={handleTextFieldClick}
            onBlur={handleTextFieldBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={styles.searchIcon} position="start">
                  <SearchIcon style={styles.searchIcon} />
                </InputAdornment>
              ),
              endAdornment: searchValue ? (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={handleClearTextUserList}
                >
                  <CloseText />
                </InputAdornment>
              ) : null,
              autoComplete: "off",
            }}
            sx={styles.searchUser({ isClicked })}
            inputRef={textInputRef}
          />
        </Box>
        <Box
          sx={styles.contentContainer}
          onContextMenu={(event) => {
            event.preventDefault();
          }}
        >
          <Stack sx={styles.overflowY}>
            {adminUsers?.length > 0 && (
              <Typography variant="body2" sx={styles.tagAll}>
                {t("chat.list_member.admin")}
              </Typography>
            )}
            {adminUsers?.map((item) => (
              <Box
                onContextMenu={
                  group?.admin_ids.includes(userInformation?.id) || userInformation?.id === item?.id
                    ? (e) => handleOpenListMemberPopper(e, item)
                    : (e) => {
                        e.preventDefault();
                        return false;
                      }
                }
                key={item.id}
                sx={({ breakpoints }) => ({
                  ...styles.dFlexNotJust,
                  ...(select === item ? styles.boxUserSelect : styles.boxUser),
                  py: 0.5,
                  pl: 1.5,
                  pr: 1.5,
                  [breakpoints.between("md", "lg")]: {
                    px: 1.25,
                  },
                  position: "relative",
                  "&:hover": {
                    background: "#E5F0FF",
                    borderRadius: "10px", // Apply border radius only when not the current user
                    ".tagContent": {
                      display: userInformation?.id !== item?.id ? "block" : "none", // Show only when not the current user
                    },
                    ".ellipsis-text": {
                      maxWidth: userInformation?.id !== item?.id ? "150px" : "none", // Limit width only when not the current user
                    },
                  },
                })}
              >
                <Box
                  sx={{
                    ...styles.dFlexNotJust1,
                    flexGrow: 1,
                  }}
                >
                  <Avatar src={defaultAvatar} srcSet={item.avatar} sx={styles.avatarImage} />
                  <Box
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    className="ellipsis-text"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="caption" color="initial" sx={styles.labelLeft}>
                        {item.username}
                      </Typography>
                    </Box>
                    <Typography variant="caption" sx={styles.labelActive}>
                      {item.id}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={styles.tag} className="tagContent">
                  <Box style={{ display: "flex" }}>
                    <Box sx={styles.listMessagse} aria-disabled="false">
                      <CallIcon />
                    </Box>
                    <Box
                      sx={styles.phonebook}
                      onClick={() => {
                        addGroupChat.handleAddSingleChat({ ...item, isClick: true });
                        handleCloseListMember();
                      }}
                    >
                      <MessgageIcon />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
            {group?.channel === "livechat" && (
              <>
                <Typography variant="body2" sx={styles.tagAll}>
                  Phóng viên
                </Typography>
                {listReporter?.map((item) => (
                  <Box
                    onContextMenu={
                      group?.admin_ids.includes(userInformation?.id) ||
                      userInformation?.id === item?.id
                        ? (e) => handleOpenListMemberPopper(e, item)
                        : (e) => {
                            e.preventDefault();
                            return false;
                          }
                    }
                    key={item?.id}
                    sx={({ breakpoints }) => ({
                      ...styles.dFlexNotJust,
                      ...(select === item ? styles.boxUserSelect : styles.boxUser),
                      py: 0.5,
                      pl: 1.5,
                      pr: 1.5,
                      [breakpoints.between("md", "lg")]: {
                        px: 1.25,
                      },
                      position: "relative",
                      "&:hover": {
                        background: "#E5F0FF",
                        borderRadius: "10px", // Apply border radius only when not the current user
                        ".tagContent": {
                          display: userInformation?.id !== item?.id ? "block" : "none", // Show only when not the current user
                        },
                        ".ellipsis-text": {
                          maxWidth: userInformation?.id !== item?.id ? "150px" : "none", // Limit width only when not the current user
                        },
                      },
                    })}
                  >
                    <Box
                      sx={{
                        ...styles.dFlexNotJust1,
                        flexGrow: 1,
                      }}
                    >
                      <Avatar src={defaultAvatar} srcSet={item?.avatar} sx={styles.avatarImage} />
                      <Box
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        className="ellipsis-text"
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="caption" color="initial" sx={styles.labelLeft}>
                            {item?.username}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={styles.labelActive}>
                          {item?.id}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.tag} className="tagContent">
                      <Box style={{ display: "flex" }}>
                        <Box sx={styles.listMessagse} aria-disabled="false">
                          <CallIcon />
                        </Box>
                        <Box
                          sx={styles.phonebook}
                          onClick={() => {
                            addGroupChat.handleAddSingleChat({ ...item, isClick: true });
                            handleCloseListMember();
                          }}
                        >
                          <MessgageIcon />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
            )}
            {group?.channel === "livechat" && (
              <>
                {listPlayer?.length > 0 && (
                  <Box style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <Typography variant="body2" sx={styles.tagAll} style={{ marginRight: "auto" }}>
                      Người chơi
                    </Typography>
                  </Box>
                )}
                {listPlayer?.map((item) => (
                  <Box
                    onContextMenu={
                      group?.admin_ids.includes(userInformation?.id) ||
                      userInformation?.id === item?.id
                        ? (e) => handleOpenListMemberPopper(e, item)
                        : (e) => {
                            e.preventDefault();
                            return false;
                          }
                    }
                    key={item.id}
                    sx={({ breakpoints }) => ({
                      ...styles.dFlexNotJust,
                      ...(select === item ? styles.boxUserSelect : styles.boxUser),
                      py: 0.5,
                      pl: 1.5,
                      pr: 1.5,
                      [breakpoints.between("md", "lg")]: {
                        px: 1.25,
                      },
                      position: "relative",
                      "&:hover": {
                        background: "#E5F0FF",
                        borderRadius: "10px", // Apply border radius only when not the current user
                        ".tagContent": {
                          display: userInformation?.id !== item?.id ? "block" : "none", // Show only when not the current user
                        },
                        ".ellipsis-text": {
                          maxWidth: userInformation?.id !== item?.id ? "150px" : "none", // Limit width only when not the current user
                        },
                      },
                    })}
                  >
                    <Box
                      sx={{
                        ...styles.dFlexNotJust1,
                        flexGrow: 1,
                      }}
                    >
                      <Avatar src={defaultAvatar} srcSet={item.avatar} sx={styles.avatarImage} />
                      <Box
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        className="ellipsis-text"
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="caption" color="initial" sx={styles.labelLeft}>
                            {item.username}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={styles.labelActive}>
                          {item.id}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.tag} className="tagContent">
                      <Box style={{ display: "flex" }}>
                        <Box sx={styles.listMessagse} aria-disabled="false">
                          <CallIcon />
                        </Box>
                        <Box
                          sx={styles.phonebook}
                          onClick={() => {
                            addGroupChat.handleAddSingleChat({ ...item, isClick: true });
                            handleCloseListMember();
                          }}
                        >
                          <MessgageIcon />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
            )}
            {members?.length > 0 && (
              <Box style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                <Typography variant="body2" sx={styles.tagAll} style={{ marginRight: "auto" }}>
                  {t("chat.list_member.member")}
                </Typography>
                {(isAdmin || group?.group_permission === 1) && (
                  <Chip
                    label={t("chat.list_member.add_member")}
                    size="small"
                    onClick={handleOpenAddMemberChat}
                    style={{
                      backgroundColor: "#2298FF",
                      color: "#FFF",
                      marginLeft: "auto",
                      lineHeight: "40px",
                    }}
                  />
                )}
              </Box>
            )}
            {members?.map((item) => (
              <Box
                onContextMenu={
                  group?.admin_ids?.includes(userInformation?.id) ||
                  userInformation?.id === item?.id
                    ? (e) => handleOpenListMemberPopper(e, item)
                    : (e) => {
                        e.preventDefault();
                        return false;
                      }
                }
                key={item.id}
                sx={({ breakpoints }) => ({
                  ...styles.dFlexNotJust,
                  ...(select === item ? styles.boxUserSelect : styles.boxUser),
                  py: 0.5,
                  pl: 1.5,
                  pr: 1.5,
                  [breakpoints.between("md", "lg")]: {
                    px: 1.25,
                  },
                  position: "relative",
                  "&:hover": {
                    background: "#E5F0FF",
                    borderRadius: "10px", // Apply border radius only when not the current user
                    ".tagContent": {
                      display: userInformation?.id !== item?.id ? "block" : "none", // Show only when not the current user
                    },
                    ".ellipsis-text": {
                      maxWidth: userInformation?.id !== item?.id ? "150px" : "none", // Limit width only when not the current user
                    },
                  },
                })}
              >
                <Box
                  sx={{
                    ...styles.dFlexNotJust1,
                    flexGrow: 1,
                  }}
                >
                  <Avatar src={defaultAvatar} srcSet={item?.avatar} sx={styles.avatarImage} />
                  <Box
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    className="ellipsis-text"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="caption" color="initial" sx={styles.labelLeft}>
                        {item.username}
                      </Typography>
                    </Box>
                    <Typography variant="caption" sx={styles.labelActive}>
                      {item.id}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={styles.tag} className="tagContent">
                  <Box style={{ display: "flex" }}>
                    <Box sx={styles.listMessagse} aria-disabled="false">
                      <CallIcon />
                    </Box>
                    <Box
                      sx={styles.phonebook}
                      onClick={() => {
                        addGroupChat.handleAddSingleChat({ ...item, isClick: true });
                        handleCloseListMember();
                      }}
                    >
                      <MessgageIcon />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
        </Box>
      </Drawer>
      <ListMemberAction
        openPopperListMember={openPopperListMember}
        anchor={anchorListMember}
        handleClose={handleCloseListMemberPopper}
        member={select}
        group={group}
        isAdmin={isAdmin}
        handleChangePermissionGroup={handleChangePermissionGroup}
        handleOpenNotificationAdminDialog={handleOpenNotificationAdminDialog}
        handleRemoveUser={handleRemoveUser}
        openNotificationAdminDialog={openNotificationAdminDialog}
        handleCloseNotificationAdminDialog={handleCloseNotificationAdminDialog}
        reporterIds={reporterIds}
        handleAddAndRemoveReporter={handleAddAndRemoveReporter}
      />
    </>
  );
}
export default ListMember;
