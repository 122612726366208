export default {
  dialog: ({ borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.xl,
      },
    },
  }),
  container: {
    width: "350px",
    py: 3,
  },

  titleText: ({ typography }) => ({
    fontSize: typography.size.sm,
    textAlign: "center",
    fontFamily: typography.fontFamily3,
    fontWeight: 500,
    mb: 1,
  }),
  boxImage: {
    width: "45px",
    height: "45px",
    mx: "auto",
    position: "relative",
  },
  boxStickerImage: {
    width: "50px",
    height: "50px",
    mx: "auto",
    position: "relative",
  },
  image: ({ borders }) => ({
    flexShrink: 0,
    border: "1px solid #D5D5D5",
    width: "45px",
    objectFit: "cover",
    height: "45px",
    borderRadius: borders.borderRadius.md,
  }),
  imageBg: ({ borders }) => ({
    background: "black",
    opacity: 0.5,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    height: "45px",
    borderRadius: borders.borderRadius.md,
  }),
  numberImagePin: ({ typography }) => ({
    position: "absolute",
    top: "30%",
    left: "30%",
    color: "#ffff",
    fontSize: "14px",
    fontFamily: typography.fontFamily3,
  }),
  boxMessage: {
    display: "flex",
    justifyContent: "center",
    px: 3,
  },
  message: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    fontSize: "18px",
    textAlign: "center",
    fontFamily: typography.fontFamily3,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    px: 0.5,
  }),
  DialogTittle: {
    alignItems: "center",
    p: 2,
  },
  action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: 2,
  },
  buttonCancel: ({ borders }) => ({
    mr: 2.5,
    borderRadius: borders.borderRadius.lg,
    border: "2px solid #406EE2",
    "&:hover": {
      border: "2px solid #406EE2",
      background: "#ffff",
    },
    // border: "transparent solid 2px !important",
    // background:
    //   "linear-gradient(#ffff 0 0) padding-box, linear-gradient(90deg, #26BBFE 3.27%, #1BC88B 97.45%) border-box",
    // "&:hover": {
    //   background:
    //     "linear-gradient(#ffff 0 0) padding-box, linear-gradient(90deg, #26BBFE 3.27%, #1BC88B 97.45%) border-box",
    // },
  }),
  cancel: ({ typography }) => ({
    width: "80px",
    fontWeight: 600,
    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xs,
    // background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    background: "#406EE2",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
  buttonCancelPin: ({ borders }) => ({
    borderRadius: borders.borderRadius.lg,
    // background: "linear-gradient(90deg, #26BBFE 0%, #1BC88B 100%)",
    background: "#406EE2",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#406EE2",
    },
  }),
  cancelPin: ({ typography }) => ({
    width: "80px",
    fontWeight: 600,
    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xs,
    color: "#ffff",
  }),
};
