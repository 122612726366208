import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styles from "assets/styles/pages/Chat/component/UpdateInformationDialog";
import { ReactComponent as CloseAddGroupIcon } from "assets/svg/icon/close_add_group.svg";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateProfile } from "service/UserService";

export default function UpdateInformationDialog({ onClose, isOpen, userInformation }) {
  const { t } = useTranslation();
  const { mutateAsync: updateInformationUser } = useUpdateProfile();

  const [updateUser, setUpdateUser] = useState({
    fullname: "",
    avatar: "",
    gender: null,
    birthday: null,
    nickname: "",
  });

  useEffect(() => {
    if (userInformation) setUpdateUser(userInformation);
  }, [userInformation]);
  const handleUpdateInformation = async () => {
    // Convert the birthday to a timestamp before sending it to the server
    const updatedUser = { ...updateUser };

    const { birthday, fullname, gender, nickname } = updatedUser;
    await updateInformationUser({
      vId: userInformation?._id,
      birthday: moment(birthday).format("YYYY-MM-DD"),
      fullname,
      gender,
      nickname,
    });
    onClose();
  };

  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return new Date(dateString).toLocaleDateString("vi-VN", options);
  // };
  return (
    <Dialog sx={styles.dialog} onClose={onClose} open={isOpen}>
      <DialogTitle sx={styles.dFlexColumn}>
        <Box sx={styles.dialogTitle}>
          <Typography variant="body1" color="initial" sx={styles.title}>
            {t("information.update_account")}
          </Typography>
          <IconButton sx={styles.paddingReset} onClick={onClose}>
            <CloseAddGroupIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={styles.divider} />
      <Box sx={styles.container}>
        <Typography variant="body1" color="initial" sx={styles.name}>
          {t("information.display_name")}
        </Typography>
        <TextField
          id="outlined-basic"
          fullWidth
          variant="outlined"
          value={updateUser?.fullname || ""}
          onChange={(e) => setUpdateUser({ ...updateUser, fullname: e.target.value })}
        />
        <Typography variant="body1" color="initial" sx={styles.nickName}>
          Nick name
        </Typography>
        <TextField
          id="outlined-basic"
          fullWidth
          variant="outlined"
          value={updateUser?.nickname || ""}
          onChange={(e) => setUpdateUser({ ...updateUser, nickname: e.target.value })}
        />
        <Typography variant="body1" color="initial" sx={styles.information}>
          {t("information.personal_information")}
        </Typography>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={updateUser?.gender || "0"} // Ensure the default value is "0"
            onChange={(e) => setUpdateUser({ ...updateUser, gender: e.target.value })}
          >
            <FormControlLabel value="0" control={<Radio />} label={t("information.male")} />
            <FormControlLabel value="1" control={<Radio />} label={t("information.female")} />
          </RadioGroup>
        </FormControl>
        <Typography variant="body1" color="initial" sx={styles.date}>
          {t("information.date_of_birth")}
        </Typography>
        <TextField
          type="date"
          fullWidth
          id="outlined-basic"
          variant="outlined"
          value={updateUser?.birthday || ""}
          onChange={(e) => setUpdateUser({ ...updateUser, birthday: e.target.value })}
        />
        <Button variant="contained" sx={styles.buttonCreateGroup} onClick={handleUpdateInformation}>
          {t("information.update")}
        </Button>
      </Box>
    </Dialog>
  );
}
