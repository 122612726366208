import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Avatar, Button } from "@mui/material";
import { ReactComponent as CloseAddGroupIcon } from "assets/svg/icon/close_add_group.svg";
import { useTranslation } from "react-i18next";
import styles from "../../../assets/styles/pages/Chat/component/NotificationAdminDialog";
import defaultAvatar from "../../../assets/svg/icon/default_avatar_group.svg";
import { getUserToken } from "../../../utils/storage";

export default function NotificationAdminDialog({
  listMember,
  group,
  openNotificationAdminDialog,
  handleChangePermissionGroup,
  handleCloseNotificationAdminDialog,
  handleRemoveUser,
}) {
  const userInformation = getUserToken();
  const { t } = useTranslation();
  const filteredList = listMember?.filter((item) => item.id !== userInformation.id);
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "80%",
          maxHeight: "90%",
        },
      }}
      onClose={() => {
        handleCloseNotificationAdminDialog();
      }}
      open={openNotificationAdminDialog}
      sx={styles.dialog}
    >
      <Box sx={styles.dialogTitle}>
        <Typography variant="body1" color="initial" sx={styles.title}>
          {t("notification_admin.notification")}
        </Typography>
        <IconButton
          sx={styles.paddingReset}
          onClick={() => {
            handleCloseNotificationAdminDialog();
          }}
        >
          <CloseAddGroupIcon />
        </IconButton>
      </Box>
      <Typography variant="body1" color="initial" sx={styles.content}>
        {t("notification_admin.content")}
      </Typography>
      <Box>
        <Box sx={styles.userGroupContainerLeft}>
          {filteredList?.map((item, index) => (
            <Box
              key={index}
              sx={styles.userGroupItem}
              onClick={() => {
                handleChangePermissionGroup(item?.id, group?.id);
                handleRemoveUser(userInformation?.id, group?.id);
                handleCloseNotificationAdminDialog();
              }}
            >
              <Box sx={styles.labelContainer}>
                <Avatar srcSet={item?.avatar} src={defaultAvatar} sx={styles.groupAvatar} />
                <Box sx={styles.groupUsernameContainer}>
                  <Typography variant="body2" color="initial" sx={styles.listUserName}>
                    {item?.username}
                  </Typography>
                  {item?.id && (
                    <Typography ml={0.5} variant="body2" color="initial" sx={styles.vidUser}>
                      {item?.id}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Button
            variant="contained"
            sx={styles.buttonCreateGroup}
            onClick={() => {
              handleCloseNotificationAdminDialog();
            }}
          >
            {t("notification_admin.close")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
