import { applyMiddleware, compose, legacy_createStore as createStore } from "redux";

import rootReducer from "redux/reducers";
import thunk from "redux-thunk";
import { decrypt, encrypt } from "utils/cipher";

function saveToLocalStorage(state) {
  try {
    const serializedState = encrypt(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // continue regardless of error
  }
}

// load from local storage
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return {};
    return decrypt(serializedState);
  } catch (e) {
    return undefined;
  }
}

const preloadedState = loadFromLocalStorage();

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false,
      })
    : compose;

const configureStore = () => {
  const middlewares = [thunk];
  const enhancers = [applyMiddleware(...middlewares)];
  const store = createStore(rootReducer, preloadedState, composeEnhancers(...enhancers));
  store.subscribe(() => {
    const state = store.getState();
    saveToLocalStorage(state);
  });

  return store;
};

const store = configureStore();

export default store;
