export default {
  dialog: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "750px",
        maxWidth: "500px",
        [breakpoints.down("sm")]: {
          maxWidth: "500px",
        },
      },
    },
  }),
  dFlexColumn: { display: "flex", flexDirection: "column" },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textCenter: { textAlign: "center" },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    marginTop: "15px",
    marginBottom: "15px",
    flex: 1,
    textAlign: "center",
  }),
  doneActive: ({ typography }) => ({
    fontFamily: `${typography.fontFamily2} !important`,
  }),
  label: ({ typography }) => ({
    flexGrow: 1,
    px: 1.25,
    fontWeight: typography.fontWeightBold,
  }),
  tabList: {
    mx: 2,
    my: 1.5,
    background: "#fff",
    justifyContent: "space-between",
  },

  divider: {
    my: 0,
    backgroundColor: "#D1D1D1",
  },
  boxShowSelected: { height: "70px", mb: 1, pr: 2, pl: 2.5 },
  overflowX: { display: "flex", overflowX: "auto" },
  selectedImage: {
    height: "70px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarImageSelected: { width: "54px", height: "54px", borderRadius: "50%" },
  avatarImage: { width: "44px", height: "44px" },
  countSelectedBox: { display: "flex", justifyContent: "space-between", pr: 4, pl: 2.5 },
  overflowY: { overflowY: "auto", pl: 0.5, ml: 2 },
  boxCheckBox: {
    display: "flex ",
    alignItems: "center",
    borderRadius: "10px",
    width: "100%",
    pl: 2.5,
    "&:hover": {
      background: "#F3F3F3",
    },
  },
  checkBox: {
    py: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  containerImage: {
    width: "100%",
    position: "absolute",
    top: "210px",
  },
  username: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    fontSize: "20px",
    // marginTop: "10px",
    textAlign: "center",
    // marginBottom: "15px",
  }),
  infor: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    fontSize: "16px",
    marginBottom: "20px",
  }),
  vId: {
    fontSize: "18px",
    marginTop: "10px",
    textAlign: "center",
    color: "#999",
  },
  search: ({ palette, borders }) => ({
    marginTop: "30px",
    borderRadius: borders.borderRadius.round,
    mx: 2,
    mb: 2,
    backgroundColor: palette.background.search,
    fieldset: {
      borderRadius: borders.borderRadius.round,
      border: "none",
      height: "50px",
    },
    input: {
      py: 1.5,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
  }),
  dFlexCenter: {
    display: "flex",
    px: 4,
    py: 3,
  },

  buttonDefault: {
    width: "100%",
    height: "44px",
    borderRadius: "10px",
    pointerEvents: "none",
    border: "2px solid",
    borderColor: "linear-gradient(180deg, #26BBFE 3.27%, #1BC88B 97.45%) !important",
  },
  iconW18: {
    width: "14px",
    fill: "#fff",
  },
  tabPanel: {
    overflowY: "auto",
  },
  paddingReset: {
    p: 0,
    marginRight: "10px",
    marginLeft: "-20px",
  },
  groupCropImage: () => ({
    width: "60px",
    height: "63px",
    // border: `1px dashed ${palette.primary.main}`,
    backgroundColor: "#e0e0e0",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  }),
  cameraContainer: {
    // width: "30px",
    mt: 0.5,
  },
  cropAvatarContainer: ({ borders, palette }) => ({
    position: "relative",
    width: "120px",
    marginRight: "auto",
    marginLeft: "auto",
    borderRadius: borders.borderRadius.round,
    border: `1px solid ${palette.grey[100]}`,
    "&:hover": {
      "& .avatar-overlay": {
        display: "flex",
      },
    },
  }),
  overlay: ({ palette, functions, borders }) => ({
    position: "absolute",
    display: "none",
    width: "120px",
    height: "100%",
    left: 0,
    bottom: 0,
    backgroundColor: functions.rgba(palette.black.main, 0.3),
    zIndex: 1,
    flexGrow: 1,
    borderRadius: borders.borderRadius.round,
    justifyContent: "center",
    alignItems: "center",
  }),
  cropBackGroundContainer: ({ borders }) => ({
    borderRadius: borders.borderRadius.round,
    cursor: "pointer",
  }),
  overlayBackGround: {
    width: "140px",
    height: "35px",
    right: "10px",
    top: "235px",
    backgroundColor: "rgba(0, 0, 0, 0.60);",
    borderRadius: "5px",
    alignItems: "center",
    position: "absolute",
    cursor: "pointer",
  },
  cropAvatar: {
    width: "60px",
    height: "60px",
  },
  updateAvatarIcon: {
    marginRight: "5px",
  },
  updateImageBackGroundIcon: {
    fill: "white",
  },
  buttonCreateGroup: {
    borderRadius: "10px",
    background: "#406EE2",
    minWidth: "100px",
    maxWidth: "200px",
    height: "50px",
    margin: "30px auto",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    "&:disabled": {
      backgroundColor: "#c8d3f3",
      color: "#FFFF",
    },
    "&:hover": {
      background: "#406EE2",
      boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    },
  },
};
