export default {
  dialog: ({ borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.xl,
      },
    },
  }),
  container: {
    width: "300px",
    mt: 2,
  },

  titleText: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    fontSize: "18px",
    textAlign: "center",
    fontFamily: typography.fontFamily3,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    px: 0.5,
    mb: 1,
  }),

  boxMessage: {
    display: "flex",
    justifyContent: "center",
    px: 3,
  },
  message: ({ typography }) => ({
    fontSize: typography.size.sm,
    textAlign: "center",
    fontFamily: typography.fontFamily3,
    fontWeight: 500,
    mb: 1,
  }),
  DialogTittle: {
    alignItems: "center",
    p: 2,
  },
  action: {
    borderTop: "1px solid #d9d9d9",

    py: 1,
    mt: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: "#d9d9d9",
    },
  },
  buttonCancel: () => ({
    borderTop: "1px solid #d9d9d9",
    width: "100%",
    m: 0,
    p: 0,
    "&:hover": {
      background: "#d9d9d9",
    },
  }),
  cancel: ({ typography }) => ({
    width: "100%",
    fontWeight: 700,
    textAlign: "center",
    fontFamily: typography.fontFamily3,
    fontSize: "16px",
    background: "#406EE2",
    // background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
};
