// base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
// helper functions
import linearGradient from "assets/theme/functions/linearGradient";

const { white, gradients, grey, transparent, primary } = colors;
const { borderWidth, borderRadius } = borders;
const { md } = boxShadows;

export default {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    root: {
      marginTop: "-5px",
    },
    switchBase: {
      color: primary.main,

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.Mui-checked": {
        color: primary.main,

        "&:hover": {
          backgroundColor: transparent.main,
        },

        "& .MuiSwitch-thumb": {
          // borderColor: `${primary.main} !important`,
          borderColor: "#406EE2",
        },

        "& + .MuiSwitch-track": {
          // background: `linear-gradient(90deg, #26BBFE 0%, #1BC88B 100%)`,
          background: "#406EE2",
          border: "none",
          opacity: 1,
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
      },
    },

    thumb: {
      backgroundColor: white.main,
      boxShadow: md,
      border: `${borderWidth[1]} solid ${grey[200]}`,
      marginTop: "3px",
    },

    track: {
      height: "20px",
      width: "38px",
      backgroundColor: grey[200],
      border: `${borderWidth[1]} solid ${grey[200]}`,
      borderRadius: borderRadius.xxl,
      opacity: 1,
    },

    checked: {},
  },
};
