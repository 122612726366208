export default {
  container: {
    mt: 2,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  section: ({ borders }) => ({
    backgroundColor: "#B8BCC6",
    borderRadius: borders.borderRadius.round,
    display: "flex",
    alignItems: "center",
  }),
  avatar: {
    width: "24px",
    height: "24px",
    ml: 0.7,
  },
  contentContainer: ({ avatar }) => ({
    pl: avatar ? 1 : 1.5,
    pr: 1.5,
    py: 1,
  }),
  message: ({ typography }) => ({
    display: "inline",
    fontWeight: typography.fontWeightBold,
  }),
};
