import { Box, CircularProgress } from "@mui/material";
import styles from "assets/styles/components/Loading/CircularLoading";

export default function CircularLoading() {
  return (
    <Box sx={styles.container}>
      <CircularProgress style={styles.progress} />
    </Box>
  );
}
