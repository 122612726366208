export default {
  container: ({ palette, borders }, { isSelected }) => ({
    backgroundColor: isSelected ? `#E5F0FF !important` : palette.transparent.main,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "6px",
    height: "60px",
    p: 0.5,
    borderRadius: borders.borderRadius.md,
    cursor: "pointer",
    overflowY: "hidden",
  }),
  informationContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflowY: "hidden",
  },
  avatarContainer: {
    position: "relative",
    width: "60px",
    height: "60px",
  },
  avatarUser: ({ palette }) => ({
    cursor: "pointer",
    "& .MuiBadge-badge": {
      backgroundColor: palette.success.light,
      bottom: 9,
      right: 9,
      height: 15,
      minWidth: 14,
      border: `2px solid ${palette.white.main}`,
    },
    mr: 0.75,
  }),
  avatar: {
    width: "40px",
    height: "40px",
    margin: "10px auto",
    border: "0.1px solid lightgray",
  },
  overlay: ({ borders, palette, functions }) => ({
    position: "absolute",
    backgroundColor: functions.rgba(palette.black.main, 0.1),
    display: "none",
    borderRadius: borders.borderRadius.xxxl,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }),
  information: {
    width: "100%",
    pl: 0.75,
    overflowY: "hidden",
  },

  nameContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-2px",
  },
  infoContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "2px",
  },
  name: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    pr: 2,
    lineHeight: "19px",
    fontSize: "15px",
    height: "19px",
    width: "100%",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    overflow: "hidden",
  }),
  muteIcon: {
    width: "19px",
    height: "18px",
  },
  lastMessageContainer: {
    width: "100%",
    // marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  lastMessage: {
    width: "90%",
    display: "flex",
    alignItems: "center",
  },
  userSendMessage: ({ palette, typography }, { isUnread }) => ({
    color: palette.grey[900],
    mt: 0.5,
    lineHeight: "20px",
    height: "18px",
    flexShrink: 0,
    fontSize: "13px",
    fontWeight: isUnread ? 600 : typography.fontWeightRegular,
    mr: 1,
    whiteSpace: "nowrap", // Ensure that the text doesn't wrap to the next line
    textOverflow: "ellipsis",
  }),
  message: ({ palette, typography }, { isUnread }) => ({
    color: isUnread ? palette.grey[900] : palette.grey[650],
    mt: 0.55,
    lineHeight: "20px",
    overflow: "hidden",
    height: "18px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word", // Thêm dòng này
    fontWeight: isUnread ? 600 : typography.fontWeightRegular,
    fontSize: "13px",
  }),

  time: ({ palette, typography }, { isUnread }) => ({
    fontWeight: isUnread ? 600 : typography.fontWeightRegular,
    color: isUnread ? 600 : palette.grey[650],
    mr: 1,
    marginLeft: "10px",
    alignSelf: "flex-start",
    flexShrink: 0,
    fontSize: "11px",
  }),
  badgeUnread: ({ palette }) => ({
    color: palette.white.main,
    flexShrink: 0,
    "& .MuiBadge-badge": {
      backgroundColor: "#406EE2",
      mr: 2,
      width: "fit-content",
    },
  }),
};
