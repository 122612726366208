import { useState } from "react";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { ReactComponent as UnMuteIcon } from "assets/svg/icon/un_mute.svg";
import { ReactComponent as MuteIcon } from "assets/svg/icon/chat_mute.svg";
import { ReactComponent as TrashIcon } from "assets/svg/icon/chat_trash.svg";
import { ReactComponent as OutGroup } from "assets/svg/icon/out_group2.svg";
import { Button, Divider, Typography } from "@mui/material";
import { ReactComponent as AddGroup } from "assets/svg/icon/add_group.svg";
import { ReactComponent as AddGroup2 } from "assets/svg/icon/add_group2.svg";
import { ReactComponent as Phone } from "assets/svg/icon/phone.svg";
import styles from "assets/styles/pages/Chat/component/ChatActionSheet";
import { useTranslation } from "react-i18next";
import { AdminNames } from "../../../constant/chat";
import ConfirmLeaveAndDeleteChatDialog from "./ConfirmLeaveAndRemoveDialog";
import ConfirmDeleteChatDialog from "./ConfirmRemoveDialog";
import NotificationAdminDialog from "./NotificationAdminDialog";
import { getUserToken } from "../../../utils/storage";

function ChatActionMenuGroup({
  openSetting,
  anchor,
  handleClose,
  handleLeaveAndRemoveGroupChatMenu,
  handleRemoveGroupChatMenu,
  handleMuteGroupChat,
  handleOpenNewGroupDialog,
  // openNewGroup,
  contextMenu,
  idGroup,
  information,
  setMember,
  showChatActionSheet,
  isAdmin,
  openNotificationAdminDialog,
  handleOpenNotificationAdminDialog,
  handleCloseNotificationAdminDialog,
  handleChangePermissionGroup,
  handleRemoveUser,
}) {
  const { t } = useTranslation();
  const userInformation = getUserToken();
  const [openDeleteChatDialog, setOpenDeleteChatDialog] = useState(false);
  const [openLeaveAndDeleteChatDialog, setOpenLeaveAndDeleteChatDialog] = useState(false);

  const handleCloseLeaveAndDeleteChatDialog = () => {
    setOpenLeaveAndDeleteChatDialog(false);
  };
  const handleCloseDeleteChatDialog = () => {
    setOpenDeleteChatDialog(false);
  };
  const handleOpenDeleteChatDialog = () => {
    console.log("openDeleteChatDialog");
    setOpenDeleteChatDialog(true);
  };
  const handleDeleteChat = () => {
    // Xóa chat
    handleRemoveGroupChatMenu(idGroup);
    setOpenDeleteChatDialog(false);
  };
  const handleLeaveAndDeleteChat = () => {
    // Xóa chat
    handleLeaveAndRemoveGroupChatMenu(idGroup);
    setOpenLeaveAndDeleteChatDialog(false);
  };
  const isMute = information?.detail?.is_mute;
  return (
    <Popper
      placement="bottom-start"
      sx={styles.popper}
      open={openSetting}
      anchorEl={anchor}
      transition
      disablePortal
      popperOptions={{
        modifiers: [{ name: "offset", options: { offset: [contextMenu.x, contextMenu.y] } }],
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener disableReactTree onClickAway={handleClose}>
              <Box>
                <Button onClick={handleMuteGroupChat} sx={styles.topItemContainer}>
                  {isMute === 1 ? (
                    <UnMuteIcon sx={{ marginRight: "10px" }} />
                  ) : (
                    <MuteIcon sx={{ marginRight: "10px" }} />
                  )}
                  <Typography variant="body1" sx={styles.item}>
                    {isMute === 1
                      ? t("chat.header.chat_action_sheet.mute_un")
                      : t("chat.header.chat_action_sheet.mute")}
                  </Typography>
                </Button>
                {information?.type === "single" && (
                  <>
                    {/* Mute */}
                    {!AdminNames.includes(information?.name) && (
                      <>
                        <Divider sx={styles.divider} />
                        <Button
                          onClick={() => {
                            // Nếu không trùng, thực hiện các hành động bình thường
                            handleOpenNewGroupDialog();
                            if (showChatActionSheet) {
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              setMember((prev) => {
                                const newMembers = information?.users.filter(
                                  (user) => user.id !== userInformation.id
                                );
                                return [...prev, ...newMembers];
                              });
                            }
                            handleClose();
                          }}
                          sx={styles.itemContainer}
                        >
                          <AddGroup2 />
                          <Typography variant="body1" sx={styles.item}>
                            {t("chat.header.chat_action_sheet.create_a_new_group_with")}
                          </Typography>
                        </Button>
                      </>
                    )}
                    <Divider sx={styles.divider} />
                  </>
                )}
                {!AdminNames.includes(information?.name) && information?.type !== "group" && (
                  <>
                    <Button onClick={handleClose} sx={styles.itemContainer} disabled>
                      <Phone />
                      <Typography variant="body1" sx={styles.item}>
                        {t("chat.header.chat_action_sheet.call_for")}
                      </Typography>
                    </Button>
                    <Divider sx={styles.divider} />
                  </>
                )}

                {information?.type === "group" && information?.is_club !== 1 && (
                  <>
                    {isAdmin && (
                      <>
                        <Divider sx={styles.divider} />
                        <Button
                          onClick={() => {
                            handleOpenNewGroupDialog();
                            if (showChatActionSheet) {
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              setMember((prev) => {
                                // Lọc ra các thành viên không trùng lặp theo userInformation.id
                                const newMembers = information?.users.filter(
                                  (user) => user.id !== userInformation.id
                                );
                                // Thêm các thành viên mới vào mảng cũ
                                return [...prev, ...newMembers];
                              });
                            }
                            handleClose();
                          }}
                          sx={styles.itemContainer}
                        >
                          <AddGroup />
                          <Typography variant="body1" sx={styles.item}>
                            {t("chat.header.chat_action_sheet.create_new_group_others")}
                          </Typography>
                        </Button>
                      </>
                    )}
                    <Divider sx={styles.divider} />
                    <Button
                      onClick={() => {
                        if (isAdmin && JSON.parse(information?.admin_ids).length === 1) {
                          handleOpenNotificationAdminDialog();
                        } else {
                          setOpenLeaveAndDeleteChatDialog(true);
                        }
                      }}
                      sx={styles.itemContainer}
                    >
                      <OutGroup />
                      <Typography variant="body1" sx={styles.itemErrorColor}>
                        {t("chat.chat_information.leave_and_delete_chat")}
                      </Typography>
                    </Button>
                    <Divider sx={styles.divider} />
                  </>
                )}
                <Button
                  onClick={() => {
                    handleOpenDeleteChatDialog();
                  }}
                  sx={styles.bottomItemContainer}
                >
                  <TrashIcon />
                  <Typography variant="body1" sx={styles.itemErrorColor}>
                    {t("chat.chat_information.delete_chat")}
                  </Typography>
                </Button>
                <ConfirmLeaveAndDeleteChatDialog
                  open={openLeaveAndDeleteChatDialog}
                  handleDeleteChat={handleLeaveAndDeleteChat}
                  handleClose={handleCloseLeaveAndDeleteChatDialog}
                  style={{ borderRadius: "20px !important" }}
                />
                <ConfirmDeleteChatDialog
                  open={openDeleteChatDialog}
                  handleDeleteChat={handleDeleteChat}
                  handleClose={handleCloseDeleteChatDialog}
                />
                <NotificationAdminDialog
                  listMember={information?.users}
                  group={information}
                  openNotificationAdminDialog={openNotificationAdminDialog}
                  handleChangePermissionGroup={handleChangePermissionGroup}
                  handleCloseNotificationAdminDialog={handleCloseNotificationAdminDialog}
                  handleRemoveUser={handleRemoveUser}
                />
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
export default ChatActionMenuGroup;
