export default {
  popper: {
    position: "absolute",
    zIndex: 9999,
  },
  paper: ({ borders }) => ({
    maxWidth: "375px",
    minWidth: "300px",
    height: "290px",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: borders.borderRadius.lg,
  }),

  divider: () => ({
    my: 0,
    py: 0,
    backgroundColor: "#3C3C434D",
  }),
  icon: {
    height: "18px",
  },
  imgMedia: {
    textAlign: "center",
    width: "100%",
    height: "100%",
  },
  height50: { height: "55px" },
  height49: { height: "54px" },
  heightFull: { height: "100%" },
  hoverIcon: {
    textAlign: "center",
    "&:hover": {
      borderRadius: 2,
      background: "#F3F3F3",
      cursor: "pointer",
    },
  },
  dFlex: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "0",
  },
  IconBox: {
    flexGrow: 1,
    heigh: "250px",
    overflowY: "auto",
    padding: "15px 13px 8px 21px",
  },
  borderRadius0: {
    borderRadius: 0,
    background: "#F3F3F3",
    border: "none",
  },
  tabList: {
    height: "100%",
    overflowX: "auto",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    p: 0,
    border: "1px solid #E0E0E0",
    background: "#FFFFFF",
    "&::-webkit-scrollbar:horizontal": {
      height: "0 !important",
      background: "rgba(0,0,0,0.00) !important",
    },
    "&::-webkit-scrollbar-thumb:horizontal": {
      backgroundColor: "#cdcccc !important",
      borderRadius: "0 !important",
    },
  },
  tab: {
    height: "100%",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    // p: 0,
  },
};
