import { Avatar, Box, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/UserItem";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import { useEnabledSearchGolfer } from "service";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkbox from "./CheckBox";

export default function ListSearchGolfer({
  searchId,
  handleAddSingleChat,
  setActive,
  openSend = false,
  listSelect,
  active,
}) {
  const { data } = useEnabledSearchGolfer(searchId);

  return (
    <Box>
      {data?.map((item, index) => (
        <Box
          sx={() => ({
            p: "8px",
            borderRadius: "8px",
            mb: "6px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: active === item?.id ? "#E5F0FF" : "#fff",
            "&:hover": {
              backgroundColor: "#E5F0FF",
            },
          })}
          key={index}
          onClick={() => {
            setActive(item.id);
            handleAddSingleChat({
              ...item,
              isClick: true,
            });
          }}
        >
          {openSend && (
            <Box
              sx={{
                paddingLeft: "8px",
              }}
            >
              <Checkbox
                checked={listSelect?.includes(item.id)}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
            </Box>
          )}

          <Box>
            <Avatar
              sx={{
                ...styles.avatarUser,
                width: openSend ? "44px" : "40px",
                height: openSend ? "44px" : "40px",
                marginRight: openSend ? "10px" : "0px",
              }}
              srcSet={item?.avatar_large || item?.avatar}
              src={defaultAvatar}
            />
          </Box>

          <Box sx={{ pl: "6px", pt: "4px" }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: !openSend ? 700 : 400, fontSize: !openSend ? "15px" : "14px" }}
              color="initial"
            >
              {item?.username}
            </Typography>
            {item?.id && (
              <Typography
                variant="body2"
                color="initial"
                style={{ color: "#858383", fontSize: "12px", marginTop: !openSend ? "5px" : "0px" }}
              >
                {item?.id}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
