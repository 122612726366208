import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LogoWGHN from "assets/images/logo/WGHN.png";
import { Fade, InputAdornment, Typography } from "@mui/material";
import styles from "assets/styles/pages/Login";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { currencies, regex } from "constant/currencies";
import { getUserInformation, sendOTP, verifyOTP } from "api/loginPhone";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";
import FormTextField from "components/FormTextField";
import FormAutocomplete from "components/FormAutocomplete";
import { isEmpty } from "utils/validate";
import { VIETNAM_PHONE, VIETNAM_PHONE_PREFIX } from "utils/pattern";
import { useDispatch } from "react-redux";
import { updateRoute } from "redux/actions/routeAction";
import routes from "routes/routes";
import { FORM_ERROR } from "final-form";
import { getVIDMapChat, removeVIDMapChat, setUserToken } from "utils/storage";
import { numberRegex } from "constant/regex";
import { encodeID } from "utils/hex";
import { addGroupChat, getListGroupChat } from "api/chat";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReCAPTCHA from "react-google-recaptcha";
import CustomPopper from "./components/popper";

export default function Login() {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const SetKey = "6LeTVLIoAAAAACyfQvS2xcBna1YErEKZO2qb7qLP";
  const vIDMapChat = getVIDMapChat();

  const handleCaptchaChange = () => {
    setIsCaptchaVerified(true);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isShowOtp, setShowOtp] = useState(false);
  const inputRef1 = useRef("");
  const inputRef2 = useRef("");
  const inputRef3 = useRef("");
  const inputRef4 = useRef("");
  useEffect(() => {
    if (isShowOtp) inputRef1.current.focus();
  }, [isShowOtp]);

  useEffect(
    () => () => {
      window.mutator = undefined;
    },
    []
  );

  const handleLoginQR = () => navigate("/qr");
  const backLoginPhone = () => {
    setShowOtp(false);
    window.mutator.resetOtp();
  };
  const handleSendOtp = async (value, event, setSubmitError) => {
    let { phone } = value;
    if (VIETNAM_PHONE_PREFIX.test(phone)) phone = phone.replace(VIETNAM_PHONE_PREFIX, "0");
    if (phone.length === 9) phone = `0${phone}`;
    setData(value);
    const res = await sendOTP({ phone }).catch((error) =>
      setSubmitError({ phone: t(`server.${error.error_msg}`) })
    );
    if (res.error !== 0) {
      setSubmitError({ phone: t(`server.${res.error_msg}`) });
    } else {
      setShowOtp(true);
    }
  };

  const handleValidateLogin = (value) => {
    const errors = {};
    if (!VIETNAM_PHONE.test(value.phone) && !VIETNAM_PHONE.test(`0${value.phone}`))
      errors.phone = t("validate.login.phone_invalid");
    if (isEmpty(value.phone)) errors.phone = t("validate.login.phone_require");

    return errors;
  };
  const handleVerify = async (value) => {
    const otpInput = `${value.otp1}${value.otp2}${value.otp3}${value.otp4}`;
    let { phone } = data;
    if (VIETNAM_PHONE_PREFIX.test(phone)) phone = phone.replace(VIETNAM_PHONE_PREFIX, "0");
    if (phone.length === 9) phone = `0${phone}`;
    const res = await verifyOTP({ phone, code: +otpInput });
    if (res.error === 0) {
      const user = await getUserInformation({ id: res.uinfo.id });
      if (res.error === 0) {
        const userInformation = { ...user.uinfo, token: user.token, sip: { ...res.uinfo } };
        setUserToken(userInformation);
        dispatch(updateRoute(routes));
        if (!isEmpty(vIDMapChat)) {
          const storeData = {
            type: "single",
            member_users: [userInformation?.id, vIDMapChat],
            is_private: 0,
          };
          addGroupChat(storeData).then((resGroup) => {
            if (+resGroup.error !== 0) return;
            removeVIDMapChat();
            navigate(`/t/${encodeID(resGroup?.group?.id)}`);
          });
        } else {
          const groupChat = await getListGroupChat();
          const [first] = groupChat.groups;
          const vID = encodeID(first.id.toString());
          navigate(`/t/${vID}`);
        }
      } else {
        return { [FORM_ERROR]: t(`server.${res.msg}`) };
      }
    } else {
      inputRef4.current.focus();
      return { [FORM_ERROR]: t(`server.${res.msg}`) };
    }
  };

  const handleKeyPress = (event, forwardRef, backRef) => {
    if (event && regex.test(event.target.value)) {
      if (event.target.value.length >= 1 && event.key !== "Backspace") forwardRef.current?.focus();
    } else if (event && event.key === "Backspace") backRef.current?.focus();
  };

  const handleChangeOtp = (e, callback) => {
    if (regex.test(e.target.value) || e.target.value === "") callback(e);
  };

  const handleChangeLastOTP = (e, callback) => {
    if (regex.test(e.target.value) || e.target.value === "") callback(e);
    if (
      !isEmpty(window.mutator.getOtpValue("otp1")) &&
      !isEmpty(window.mutator.getOtpValue("otp2")) &&
      !isEmpty(window.mutator.getOtpValue("otp3")) &&
      !isEmpty(window.mutator.getOtpValue("otp4"))
    )
      window.mutator.handleSubmitOTP();
  };

  const handlePasteOtp = (e) => {
    e.preventDefault();
    const inputArray = ["otp1", "otp2", "otp3", "otp4"];
    const pasteValue = e.clipboardData.getData("Text");
    if (numberRegex.test(pasteValue)) {
      const emptyInputArray = inputArray.filter((element) =>
        isEmpty(window.mutator.getOtpValue(element))
      );
      emptyInputArray.forEach((element, index) => {
        window.mutator.setOtpValue(element, pasteValue.charAt(index));
      });
      if (isEmpty(window.mutator.getOtpValue("otp1"))) return inputRef1.current.focus();
      if (isEmpty(window.mutator.getOtpValue("otp2"))) return inputRef2.current.focus();
      if (isEmpty(window.mutator.getOtpValue("otp3"))) return inputRef3.current.focus();
      if (isEmpty(window.mutator.getOtpValue("otp4"))) return inputRef4.current.focus();
      inputRef4.current.focus();
      window.mutator.handleSubmitOTP();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={styles.container}>
        <Box sx={styles.pRelative}>
          <Box sx={styles.LogoWGHN} component="img" src={LogoWGHN} alt="LogoWGHN" />
        </Box>
        <Typography sx={styles.title}>{t("login.title")}</Typography>
        {!isShowOtp ? (
          <Typography variant="subtitle2" sx={styles.subtitleContainer}>
            {t("login.option_1")}
          </Typography>
        ) : (
          <Typography variant="subtitle2" sx={styles.subtitleContainer}>
            {t("login.option_2")} {data?.phone?.slice(-9)}
          </Typography>
        )}
        <Form
          onSubmit={handleSendOtp}
          keepDirtyOnReinitialize
          validate={handleValidateLogin}
          initialValues={{
            region: {
              title: "Việt Nam",
              value: 1,
            },
            ...data,
          }}
          render={(e) => (
            <Box
              onSubmit={e.handleSubmit}
              component="form"
              sx={() => styles.loginForm({ isShowOtp })}
            >
              <Field name="region">
                {({ input, meta }) => (
                  <FormAutocomplete
                    input={input}
                    meta={meta}
                    onChange={(c, value) => {
                      e.values.region = value;
                    }}
                    fullWidth
                    disablePortal
                    disableClearable
                    options={currencies}
                    filterOptions={(options) => options}
                    getOptionLabel={(option) => option.title}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    sx={styles.popperOption}
                    PopperComponent={CustomPopper}
                    renderOption={(props, option) => (
                      <Typography variant="caption" {...props} style={styles.popperListBox}>
                        {option.title}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={styles.autocomplete}
                        label={t("login.nation_input")}
                      />
                    )}
                  />
                )}
              </Field>
              <Field name="phone">
                {({ input, meta }) => (
                  <FormTextField
                    input={input}
                    meta={meta}
                    variant="outlined"
                    color="primary"
                    sx={styles.textfield}
                    fullWidth
                    label={t("login.phone_number_input")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={styles.startText}>
                          +84
                        </InputAdornment>
                      ),
                    }}
                    error={meta.touched && !!meta.error}
                  />
                )}
              </Field>
              <Box sx={styles.dFlexC}>
                <ReCAPTCHA
                  sx={{ maxWidth: "300px" }}
                  sitekey={SetKey}
                  onChange={handleCaptchaChange}
                />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={styles.loginButton}
                  disabled={e.submitting || !isCaptchaVerified}
                >
                  <Typography variant="body1" sx={styles.login}>
                    {t("login.login_button")}
                  </Typography>
                </Button>
                <Button variant="outlined" sx={styles.loginButtonQR} onClick={handleLoginQR}>
                  <Typography variant="body1" sx={styles.loginQR}>
                    {t("login.login_by_qr")}
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}
        />
        {/*  */}
        <Form
          onSubmit={handleVerify}
          initialValues={{}}
          mutators={{
            setOtpValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
            getOtpValue: (field, state) => state?.fields[field]?.lastFieldState?.value || "",
          }}
          render={(e) => {
            if (isEmpty(window?.mutator?.setOtpValue) || isEmpty(window?.mutator?.getOtpValue))
              window.mutator = {
                ...(window.mutator || {}),
                setOtpValue: e.form.mutators.setOtpValue,
                getOtpValue: e.form.mutators.getOtpValue,
                resetOtp: e.form.reset,
                handleSubmitOTP: e.handleSubmit,
              };
            return (
              <Box
                onSubmit={e.handleSubmit}
                component="form"
                sx={() => styles.otpForm({ isShowOtp })}
              >
                <Fade in={Boolean(e.submitError)} style={{ transformOrigin: "0 0 0" }}>
                  <Typography variant="subtitle2" sx={styles.messageError}>
                    {e.submitError}
                  </Typography>
                </Fade>
                <Box sx={styles.formOtp}>
                  <Field name="otp1">
                    {({ input }) => (
                      <TextField
                        {...input}
                        onPaste={handlePasteOtp}
                        onChange={(event) => handleChangeOtp(event, input.onChange)}
                        inputRef={inputRef1}
                        sx={styles.textOtp}
                        inputProps={{ maxLength: 1, autoComplete: "off" }}
                        variant="standard"
                        autoFocus
                        onKeyDown={(event) => handleKeyPress(event, inputRef2, inputRef1)}
                      />
                    )}
                  </Field>
                  <Field name="otp2">
                    {({ input }) => (
                      <TextField
                        {...input}
                        onPaste={handlePasteOtp}
                        onChange={(event) => handleChangeOtp(event, input.onChange)}
                        inputRef={inputRef2}
                        sx={styles.textOtp}
                        inputProps={{ maxLength: 1, autoComplete: "off" }}
                        variant="standard"
                        autoFocus
                        onKeyDown={(event) => handleKeyPress(event, inputRef3, inputRef1)}
                      />
                    )}
                  </Field>
                  <Field name="otp3">
                    {({ input }) => (
                      <TextField
                        {...input}
                        onPaste={handlePasteOtp}
                        onChange={(event) => handleChangeOtp(event, input.onChange)}
                        inputRef={inputRef3}
                        sx={styles.textOtp}
                        inputProps={{ maxLength: 1, autoComplete: "off" }}
                        variant="standard"
                        autoFocus
                        onKeyDown={(event) => handleKeyPress(event, inputRef4, inputRef2)}
                      />
                    )}
                  </Field>
                  <Field name="otp4">
                    {({ input }) => (
                      <TextField
                        {...input}
                        onPaste={handlePasteOtp}
                        onChange={(event) => handleChangeLastOTP(event, input.onChange)}
                        inputRef={inputRef4}
                        sx={styles.textOtp}
                        inputProps={{ maxLength: 1, autoComplete: "off" }}
                        variant="standard"
                        autoFocus
                        onKeyDown={(event) => handleKeyPress(event, inputRef4, inputRef3)}
                      />
                    )}
                  </Field>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  sx={styles.loginButton}
                  disabled={
                    e.submitting ||
                    isEmpty(e?.values?.otp1) ||
                    isEmpty(e?.values?.otp2) ||
                    isEmpty(e?.values?.otp3) ||
                    isEmpty(e?.values?.otp4)
                  }
                >
                  <Typography variant="body1" color="primary" sx={styles.login}>
                    {t("login.confirm")}
                  </Typography>
                </Button>
                <Button variant="outlined" sx={styles.loginButtonQR} onClick={backLoginPhone}>
                  <Typography variant="body1" sx={styles.loginQR}>
                    {t("login.come_back")}
                  </Typography>
                </Button>
              </Box>
            );
          }}
        />
      </Box>
    </Container>
  );
}
