export default {
  container: ({ palette, borders }, { isSelected }) => ({
    backgroundColor: isSelected
      ? `${palette.background.message} !important`
      : palette.transparent.main,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    p: 1,
    borderRadius: borders.borderRadius.md,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    overflowY: "hidden",
  }),
  informationContainer: {
    display: "flex",
    alignItems: "center",
    overflowY: "hidden",
  },
  avatarContainer: {
    position: "relative",
    width: "51px",
    height: "51px",
  },
  avatar: {
    width: "51px",
    height: "51px",
  },
  overlay: ({ borders, palette, functions }) => ({
    position: "absolute",
    backgroundColor: functions.rgba(palette.black.main, 0.1),
    display: "none",
    borderRadius: borders.borderRadius.xxxl,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }),
  information: {
    pl: 1.5,
    overflowY: "hidden",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  infoUser: {
    display: "flex",
    alignItems: "center",
  },
  name: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xs,
    color: "#090710",
    fontWeight: typography.fontWeightBold,
    // background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    background: "#090710",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
  vID: ({ typography }) => ({
    px: 1,
    color: "#406EE2",
    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xs,
    fontWeight: typography.fontWeightBold,
  }),
  // name: ({ typography }) => ({
  //   fontWeight: typography.fontWeightRegular,
  //   fontFamily: typography.fontFamily3,
  //   pr: 2,
  //   overflow: "hidden",
  //   lineHeight: "16px",
  //   height: "16px",
  //   textOverflow: "ellipsis",
  //   display: "-webkit-box",
  //   WebkitBoxOrient: "vertical",
  //   WebkitLineClamp: 1,
  //   whiteSpace: "pre-wrap",
  // }),
  time: ({ palette, typography }) => ({
    color: palette.grey[400],
    fontFamily: typography.fontFamily3,
    mt: 1,
    lineHeight: "14px",
    overflow: "hidden",
    height: "14px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
  }),
  emotion: ({ palette }) => ({
    display: "flex",
    alignItems: "center",
    color: palette.grey[650],
    mt: 1.5,
    // mr: { sm: 1, md: 2 },
    alignSelf: "flex-start",
  }),
  emotionIcon: {
    height: "20px",
    width: "100%",
    maxWidth: "20px",
  },
  emotionTip: ({ palette, borders }) => ({
    display: "flex",
    border: `1.5px solid #D5D5D5`,
    background: palette.white.main,
    borderRadius: borders.borderRadius.round,
    p: 0.4,
    pr: 1,
    ml: 1,
    alignItems: "center",
  }),
  tipIcon: {
    height: "16px",
    width: "100%",
    maxWidth: "30px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  tipCount: () => ({
    paddingLeft: "1px",
    paddingRight: "4px",
    minWidth: "30px",
    color: "#898989",
    fontSize: "12px",
  }),
};
