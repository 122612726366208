export default {
  container: {
    display: "flex",
    alignItems: "center",
    height: "50px",
    marginTop: "-8px",
    paddingLeft: "16px",
    // justifyContent: "space-between",
  },
  title: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: "14px",
    background: "#406EE2",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
};
