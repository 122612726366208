import {
  informationWidth,
  lgInformationWidth,
  xlInformationWidth,
  xxlInformationWidth,
} from "constant/chat";

export default {
  drawer: ({ transitions, breakpoints }, { isOpenInformation }) => ({
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
    ...(!isOpenInformation && {
      width: 0,
      "& .MuiDrawer-paper": {
        width: 0,
      },
    }),
    ...(isOpenInformation && {
      width: informationWidth,
      "& .MuiDrawer-paper": {
        width: informationWidth,
      },
      [breakpoints.down("xxl")]: {
        width: xxlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xxlInformationWidth,
        },
      },
      [breakpoints.down("xl")]: {
        width: xlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xlInformationWidth,
        },
      },
      [breakpoints.down("lg")]: {
        width: lgInformationWidth,
        "& .MuiDrawer-paper": {
          width: lgInformationWidth,
        },
      },
      [breakpoints.down("md")]: {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    }),
  }),
  dFlexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    position: "relative",
    height: "270px",
  },
  imgAvatar: {
    width: "100%",
    height: "100%",
    minHeight: "160px",
    objectFit: "cover",
    maxHeight: "270px",
  },
  groupCloseButton: {
    position: "absolute",
    top: "4px",
    right: "4px",
  },
  groupNameContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)",
    height: "140px",
    display: "flex",
  },
  groupName: ({ typography }) => ({
    pl: 2,
    fontWeight: typography.fontWeightBold,
    fontFamily: typography.fontFamily2,
    marginTop: "10px",
    width: "70%",
    marginBottom: "10px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word", // Thêm dòng này
    fontSize: "18px",
    overflow: "hidden",
  }),
  editNameGroup: ({ typography }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "80%",
    height: "100%",
    background: "none",
    justifyContent: "flex-end",
    border: "none",
    "&:focus": {
      borderColor: "transparent",
      outline: "none",
      color: "#FFF", // Chuyển màu chữ sang trắng khi ô TextField được focus
    },
    input: {
      color: "#FFF",
      fontWeight: typography.fontWeightBold,
      fontFamily: typography.fontFamily2,
      fontSize: "18px",
    },
    "& fieldset": { border: "none" },
    marginBottom: "10px",
  }),
  personalContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
  },
  personalSection: {
    pl: 2,
    pr: 1,
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "90px",
    alignItems: "flex-end",
  },
  personalName: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    fontFamily: typography.fontFamily2,
    pb: 1,
    fontSize: "18px",
  }),
  itemContainer: {
    display: "flex",
    px: 1.5,
    justifyContent: "flex-start",
    height: "56px",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
    width: "100%",
  },
  itemAddGroupContainer: {
    display: "flex",
    px: 1,
    height: "56px",
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
  },
  informationContainer: {
    ml: 2.5,
  },
  iconContainer: {
    width: "33px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexShrink: 0,
  },
  iconContainerLinkMessage: {
    width: "80px",
    flexShrink: 0,
  },
  messageLinkImage: {
    width: "100%",
    maxHeight: "10vh",
  },
  linkNoImageMessage: {
    width: "80px",
    flexShrink: 0,
    height: "40px",
    background: "#d9d9d9",
  },
  messageNoLinkImage: {
    width: "100%",
    minHeight: "100%",
    background: "#cccc",
  },
  icon: {
    width: "100%",
  },
  phone: ({ typography }) => ({
    pt: 0.25,
    fontFamily: typography.fontFamily2,
  }),
  nameFile: ({ typography }) => ({
    pt: 0.25,
    fontSize: "14px",
    fontFamily: typography.fontFamily2,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    wordBreak: "break-all",
  }),
  nameLinkMessage: ({ typography }) => ({
    pt: 0.25,
    fontFamily: typography.fontFamily2,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    overflow: "hidden",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: "14px",
  }),
  vga: ({ typography }) => ({
    fontFamily: typography.fontFamily2,
    pb: 1.5,
    color: "#000",
    fontSize: "12px",
  }),

  itemContainerNotification: {
    display: "flex",
    pl: 1,
    pr: 1,
    height: "56px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
  },
  itemNotificationSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  textOnlyContainer: ({ typography }) => ({
    alignSelf: "center",
    fontFamily: typography.fontFamily2,
    ml: 2,
    fontSize: "15px",
  }),
  newGroupContainer: {
    ml: 1.6,
  },
  newGroup: ({ typography }) => ({
    fontFamily: typography.fontFamily2,
    display: "inline",
    mr: 0.5,
    fontSize: "15px",
  }),
  newGroupName: ({ typography }) => ({
    fontFamily: typography.fontFamily2,
    display: "inline",
    fontWeight: typography.fontWeightBold,
    fontSize: "15px",
  }),
  itemDeleteContainer: {
    display: "flex",
    px: 1.2,
    height: "56px",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
  },

  divider: {
    my: 0,
    py: 0,
    opacity: "1",
    backgroundColor: "#DDDDDD",
  },
  divider1: {
    my: 0,
    py: 0,
    // height: "10px",
    opacity: "1",
    backgroundColor: "#DDDDDD",
  },
  fullWidth: {
    width: "100%",
  },
  mediaTab: ({ palette }) => ({
    paddingLeft: 0.2,
    paddingRight: 0.2,
    width: "100%",
    backgroundColor: palette.background.paper,
  }),
  tabList: ({ palette }) => ({
    p: 0,
    backgroundColor: palette.background.paper,
    borderRadius: 0,
    borderBottom: 1,
    borderColor: "#DDDDDD",
    "& .MuiButtonBase-root": {
      borderRadius: 0,
    },
    "& .MuiTabs-indicator": {
      borderRadius: 0,
      boxShadow: "none",
      borderBottom: "2px solid #406EE2",
      "&:hover": {
        background: "#406EE2",
      },
    },
  }),
  tabPanel: {
    width: "100%",
    p: 1,
  },
  showAll: {
    width: "100%",
    height: "34px",
    borderRadius: "5px",
    background: "#E4EFFF",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  textAll: {
    lineHeight: "34px",
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    color: "#406EE2",
    fontWeight: 500,
  },
  textMedia: ({ palette, typography }, { isBlack, isBold }) => ({
    fontFamily: typography.fontFamily2,
    background: isBlack ? palette.black.main : "#406EE2",
    color: "#3D3D3D",
    fontSize: "15px",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: isBold ? typography.fontWeightBold : typography.fontWeightRegular,
  }),
  video: () => ({
    width: "100%",
    height: "100%",
    objectFit: "contain",
  }),
  tab: {
    px: 1.5,
    pt: 2,
    pb: 1.5,
  },
  imgMedia: () => ({
    cursor: "pointer",
    width: "70px",
    marginLeft: "5px",
    borderRadius: "7px",
    // objectFit: "cover",
    height: "70px",
  }),
  imgMediaVideo: {
    cursor: "pointer",
    width: "70px",
    objectFit: "cover",
    height: "70px",
    background: "black",
    borderRadius: "7px",
  },
  imgPlayVideo: {
    width: "40px",
    height: "40px",
    position: "absolute",
    cursor: "pointer",
    objectFit: "cover",
  },
};
