import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import TabPanel from "components/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close.svg";
import { ReactComponent as LockIcon } from "assets/svg/icon/lock.svg";
import { ReactComponent as SoundIcon } from "assets/svg/icon/sound.svg";
import { ReactComponent as LanguageIcon } from "assets/svg/icon/language.svg";
import { ReactComponent as VNIcon } from "assets/svg/icon/vietnam.svg";
import { ReactComponent as ENIcon } from "assets/svg/icon/england_icon.svg";
import styles from "assets/styles/pages/Chat/section/SettingDialog";
import { useTranslation } from "react-i18next";
import { getLanguage, setLanguage } from "utils/storage";

export default function SettingDialog({ onClose, isOpen }) {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => setValue(newValue);
  const [lang, setLang] = useState(getLanguage() || "vi");
  const handleChangeLang = (event) => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };
  return (
    <Dialog sx={styles.dialog} onClose={onClose} open={isOpen}>
      <DialogTitle sx={styles.dialogTitle}>
        <Box />
        <Typography variant="body1" color="initial" sx={styles.title}>
          {t("chat.setting.title")}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={styles.divider} />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.tabsContainer}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={styles.tabs}
          >
            <Tab
              sx={styles.tab}
              label={
                <Box sx={() => styles.tabContainer({ value })}>
                  <LockIcon style={styles.tabIcon} />
                  <Typography
                    sx={styles.tabName}
                    color={value === 0 ? "white.main" : "inherit.main"}
                    variant="body1"
                  >
                    {t("chat.setting.privacy")}
                  </Typography>
                </Box>
              }
            />
            <Tab
              sx={styles.tab}
              label={
                <Box sx={() => styles.tabContainer1({ value })}>
                  <SoundIcon style={styles.tabIcon} />
                  <Typography
                    sx={styles.tabName}
                    color={value === 1 ? "white.main" : "inherit.main"}
                    variant="body1"
                  >
                    {t("chat.setting.sound")}
                  </Typography>
                </Box>
              }
            />
            <Tab
              sx={styles.tab}
              label={
                <Box sx={() => styles.tabContainer2({ value })}>
                  <LanguageIcon style={styles.tabIcon} />
                  <Typography
                    sx={styles.tabName}
                    color={value === 2 ? "white.main" : "inherit.main"}
                    variant="body1"
                  >
                    {t("chat.setting.language")}
                  </Typography>
                </Box>
              }
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} sx={styles.tabPanel}>
          <Box sx={styles.personalContainer}>
            <Typography variant="body1" color="initial" sx={styles.personalTitle}>
              {t("chat.setting.label_sync_contacts")}
            </Typography>
            <Switch defaultChecked disabled />
          </Box>
          <Box sx={styles.personalContainer}>
            <Box>
              <Typography variant="body1" color="initial" sx={styles.personalTitle}>
                {t("chat.setting.label_viewed_status")}
              </Typography>
              <Typography variant="body2" sx={styles.personalContent}>
                {t("chat.setting.show_viewed_status")}
              </Typography>
            </Box>
            {/* ---DISABLE--- */}
            <Switch defaultChecked disabled />
          </Box>
          <Box sx={styles.personalContainer}>
            <Box>
              <Typography variant="body1" color="initial" sx={styles.personalTitle}>
                {t("chat.setting.label_calls_strangers")}
              </Typography>
              <Typography variant="body2" sx={styles.personalContent}>
                {t("chat.setting.public_calls_strangers")}
              </Typography>
            </Box>
            {/* ---DISABLE--- */}
            <Switch defaultChecked disabled />
          </Box>
          <Box sx={styles.personalContainer}>
            <Box>
              <Typography variant="body1" color="initial" sx={styles.personalTitle}>
                {t("chat.setting.label_calls_strangers")}
              </Typography>
              <Typography variant="body2" sx={styles.personalContent}>
                {t("chat.setting.public_calls_strangers")}
              </Typography>
            </Box>
            {/* ---DISABLE--- */}
            <Switch disabled />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1} />
        <TabPanel value={value} index={2} sx={styles.tabPanel}>
          <Box sx={styles.languageContainer}>
            <Typography variant="body1" color="initial" sx={styles.personalTitle}>
              {t("chat.setting.language")}
            </Typography>
            <FormControl sx={styles.formSelect}>
              <Select
                value={lang}
                sx={styles.select}
                MenuProps={styles.selectLang}
                onChange={handleChangeLang}
              >
                <MenuItem sx={styles.menuItemLang} value="vi">
                  <Box sx={styles.boxLang}>
                    <Box sx={styles.lang}>
                      <IconButton sx={styles.iconButton}>
                        <VNIcon />
                      </IconButton>
                      <Typography sx={styles.nameLang}>Tiếng Việt</Typography>
                    </Box>
                  </Box>
                </MenuItem>
                <MenuItem sx={styles.menuItemLang} value="en">
                  <Box sx={styles.boxLang}>
                    <Box sx={styles.lang}>
                      <IconButton sx={styles.iconButton}>
                        <ENIcon />
                      </IconButton>
                      <Typography sx={styles.nameLang}>English</Typography>
                    </Box>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </TabPanel>
      </Box>
      <Box />
    </Dialog>
  );
}
