import { Box, Button, Typography } from "@mui/material";
import styles from "assets/styles/pages/LoginQR/QR";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { ReactComponent as LogoWghnIcon } from "assets/svg/icon/wghn.svg";
import LogoWIcon from "assets/svg/icon/logoW.svg";
import Avatar from "@mui/material/Avatar";
// import QRCode from "react-qr-code";

function QR({ handleNavigateLogin, qrCode }) {
  const { t } = useTranslation();
  // console.log("qrCode===============", qrCode);
  return (
    <Box sx={styles.container}>
      <LogoWghnIcon />
      <Typography variant="d2" sx={styles.title} color="initial">
        {t("login_qr.login_by_qr")}
      </Typography>

      <Box style={{ position: "relative", display: "inline-block" }}>
        <Avatar
          alt="code"
          src={qrCode}
          variant="square"
          sx={{ width: 300, height: 300, borderRadius: "8px" }}
        />
        <Avatar
          src={LogoWIcon}
          alt="Logo"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#FFF",
            width: 300 / 5, // Adjust the size of the logo as needed
            height: 300 / 5,
            borderRadius: "20%", // Make it circular if needed
          }}
        />
      </Box>
      {/* <Avatar alt="Remy Sharp" src={qrCode} variant="square" sx={{ width: 300, height: 300 }} /> */}
      <Box sx={styles.subtitleContainer}>
        <Typography variant="subtitle2" color="initial">
          {t("login_qr.option_1")}
        </Typography>
        <Box sx={styles.inlineContainer}>
          <Typography sx={styles.inline} variant="subtitle2" color="initial">
            {t("login_qr.option_2")}
          </Typography>
          <Typography sx={styles.inlineBold} variant="subtitle2" color="initial">
            {" "}
            {t("login_qr.option_2_bold")}
          </Typography>
        </Box>
        <Typography variant="subtitle2" color="initial">
          {t("login_qr.option_3")}
        </Typography>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        sx={styles.loginButton}
        onClick={handleNavigateLogin}
      >
        <Typography variant="body1" color="primary" sx={styles.login}>
          {t("login_qr.login_button")}
        </Typography>
      </Button>
    </Box>
  );
}

export default memo(QR);
