import axiosInstance from "middleware/axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useGetProfile = (id) =>
  useQuery(["GetProfile"], async () => {
    const { data } = await axiosInstance.get(
      `https://wghn.vcallid.com/api/v1/other_services/get_user_info_for_web?uid=${id}`,
      {
        headers: { authen: "A4KcOxIPiLr9oPqDNX0AWJlN1SIhBIt7cfHE6SHDYWPj5dTWUIQCxD961e" },
      }
    );

    const { avatar, birthday, birthday_display, fullname, gender, img_background, nickname } = data;

    return {
      avatar,
      birthday,
      birthday_display,
      fullname,
      gender,
      nickname,
      img_background,
    };
  });

// https://api.vcallid.com/api/v1/voiprivate_services/profile?uid=80603&token=304622ccd3ea254b2aefec09a43501e1&lang=vn&uuid=2cd74957-6593-480d-9777-0e054494df75&readableVersion=2.51.1&majorVersionIOS=17&is_vCall=1&version_wghn=2

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["UpdateProfile"],
    async (profile) => {
      const { vId, ...body } = profile;
      const data = await axiosInstance.post(
        `https://api-v2.vcallid.com/api/v1/users/web_update_userinfo?uid=${vId}`,
        body
      );
      return data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["GetProfile"]);
      },
    }
  );
};

export const useGetSeen = (messId) =>
  useQuery(
    ["GetSeen", messId],
    async () => {
      const res = await axiosInstance.get(
        `https://api.vcallid.com/api/group_messenger/list_viewer/${messId}?`,
        {
          headers: { authen: "A4KcOxIPiLr9oPqDNX0AWJlN1SIhBIt7cfHE6SHDYWPj5dTWUIQCxD961e" },
        }
      );
      if (res.error_code) return [];
      return res?.list?.filter((item) => item.status > 1);
    },
    {
      enabled: !!messId,
    }
  );
