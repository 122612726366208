import { Avatar, Box, Link, Typography } from "@mui/material";
import { Stickers } from "assets/stickers";
import styles from "assets/styles/pages/Chat/component/MessageEmoji";
import { ReactComponent as AudioIcon } from "assets/svg/icon/audio.svg";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import { ReactComponent as DOCIcon } from "assets/svg/icon/doc_message.svg";
import { ReactComponent as FILEIcon } from "assets/svg/icon/file_message.svg";
import { ReactComponent as PDFIcon } from "assets/svg/icon/pdf_message.svg";
import { ReactComponent as PPTIcon } from "assets/svg/icon/ppt_message.svg";
import { ReactComponent as ReplyIcon } from "assets/svg/icon/reply.svg";
import { ReactComponent as TXTIcon } from "assets/svg/icon/txt_message.svg";
import { ReactComponent as XLSXIcon } from "assets/svg/icon/xlsx_message.svg";
import { ReactComponent as ZIPIcon } from "assets/svg/icon/zip_message.svg";
import { FILE_TYPE, MESSAGE_TYPE } from "constant/chat";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import playVideo from "../../../assets/images/playVideo.png";
import { tagManyNameRegex, tagNameRegex, urlRegex } from "../../../constant/regex";
import { isEmpty } from "../../../utils/validate";
import Emotion from "./Emotion";
import ReplyAndForwardAndDetail from "./ReplyAndForwardAndDetail";

const transferText = (htmlContent = "") => {
  const match = htmlContent.match(/<a>(.*?)<\/a>/);

  if (match) {
    const url = match[1].replace("Tại đây;httpsss", "https");

    const aTag = `
      <a href="${url}" style="font-weight: 600">
          Tại đây
      </a>`;

    const modifiedText = htmlContent.replace(/<a>(.*?)<\/a>/g, aTag);

    const result = modifiedText.replace(/\n/g, "<br />");
    return result;
  }

  return htmlContent;
};
function MessageEmoji({
  isBrand = false,
  value,
  isOwner,
  handleOpenPopper,
  idMessage,
  handleOpenViewerDialog,
  addGroupChat,
  handleOpenReplyMessage,
  handleOpenForwardDialog,
  handleScrollPin,
  statusMessage,
}) {
  const [urlVideo, setUrlVideo] = useState("");
  const [bill, setBill] = useState();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const maxLength = 530;
  const truncatedText = value && value.text ? value.text.slice(0, maxLength) : "";
  const shouldDisplayEllipsis = value && value.text && value.text.length > maxLength;
  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };
  const displayText = expanded
    ? value?.text
    : truncatedText + (shouldDisplayEllipsis ? " ..." : "");
  useEffect(() => {
    try {
      const val = JSON.parse(value?.text);
      if (val?.provider) setBill(val);
      if (val?.type === "live_stream" && val?.data?.video_url && !val?.data?.end_live)
        setUrlVideo(val?.data?.video_url);
    } catch (error) {
      setBill(null);
    }
  }, [value.text]);
  const modifiedReplyText = tagManyNameRegex.exec(value?.reply?.text)
    ? value?.reply?.text.replace(tagManyNameRegex, `$1`)
    : value?.reply?.text;
  const truncatedReplyText =
    modifiedReplyText?.length > 50 ? `${modifiedReplyText.slice(0, 50)}...` : modifiedReplyText;
  return (
    <Box sx={() => styles.container({ statusMessage })}>
      <Box
        sx={() => ({
          ...styles.section({ isOwner }),
          alignItems: "center",
          "&:hover": {
            "& .iconContainer": {
              display: "flex",
            },
          },
        })}
      >
        {!isOwner && !statusMessage && (
          <Avatar
            src={defaultAvatar}
            srcSet={value?.user?.avatar}
            sx={styles.avatar}
            onClick={() => addGroupChat.handleAddSingleChat({ ...value?.user, isClick: true })}
          />
        )}
        <Box
          onContextMenu={(e) => handleOpenPopper(e, isOwner, value)}
          sx={(e) => styles.messageSection(e, { isOwner })}
          data-id={idMessage}
        >
          <Box
            sx={(e) => styles.emojiContainer(e, { statusMessage })}
            className={`id-message-${value._id}`}
          >
            {value?.group?.type === "group" && !isOwner && !statusMessage && (
              <Box
                sx={styles.nameContainer}
                onClick={() => addGroupChat.handleAddSingleChat({ ...value?.user, isClick: true })}
              >
                <Typography
                  className="username"
                  color="initial"
                  variant="body1"
                  sx={styles.username}
                >
                  {value?.user?.username || value?.user?.user_name} - {value?.user?.id}
                </Typography>
              </Box>
            )}
            {!isEmpty(value.reply) && (
              <Box
                onClick={() => handleScrollPin(value.reply)}
                sx={(e) =>
                  styles.replyContainer(e, {
                    isOwner,
                    isDisplayUsername: value?.group?.type === "group",
                    statusMessage,
                  })
                }
              >
                <Box style={{ display: "flex", marginBottom: "2px" }}>
                  <Box sx={(e) => styles.replyIconContainer(e, { isOwner })}>
                    <ReplyIcon style={styles.replyIcon} />
                  </Box>
                  <Typography
                    color="initial"
                    variant="body1"
                    sx={(e) => styles.replyName(e, { isOwner })}
                  >
                    {value?.reply?.user.username || value?.reply?.user.user_name}
                  </Typography>
                </Box>
                <Box>
                  {value?.reply?.kind === MESSAGE_TYPE.IMAGE && (
                    <Box sx={styles.messageImage}>
                      <Box sx={styles.boxImage}>
                        <Box
                          component="img"
                          src={value?.reply?.image[0]?.url}
                          sx={styles.imageReply}
                        />
                        {value?.reply?.image?.length > 1 && (
                          <>
                            <Box sx={styles.imageBg} />
                            <Typography sx={styles.numberImagePin}>
                              +{value.reply.image.length - 1}
                            </Typography>
                          </>
                        )}
                      </Box>
                      <Typography sx={(e) => styles.replyMessage(e, { isOwner })}>
                        {t("chat.type_message.photo_messages")}
                      </Typography>
                    </Box>
                  )}
                  {value?.reply?.kind === MESSAGE_TYPE.VIDEO && (
                    <Box sx={styles.messageImage}>
                      <Box sx={styles.boxImage}>
                        <Box
                          component="img"
                          src={value?.reply?.video.url_thumb}
                          sx={styles.imageReply}
                        />
                        <Box sx={styles.imageVideoBg} />
                        <Box component="img" src={playVideo} sx={styles.imagePlayBg} />
                      </Box>
                      <Typography sx={(e) => styles.replyMessage(e, { isOwner })}>
                        {t("chat.type_message.video_messages")}
                      </Typography>
                    </Box>
                  )}
                  {value?.reply?.kind === MESSAGE_TYPE.FILE && (
                    <Box sx={styles.messageImage}>
                      <Box sx={styles.boxFile}>
                        <Box sx={styles.downloadIcon}>
                          {FILE_TYPE.XLSX.includes(value?.reply?.file.typeFile) && (
                            <XLSXIcon style={styles.icon} />
                          )}
                          {FILE_TYPE.PDF.includes(value?.reply?.file.typeFile) && (
                            <PDFIcon style={styles.icon} />
                          )}
                          {FILE_TYPE.DOC.includes(value?.reply?.file.typeFile) && (
                            <DOCIcon style={styles.icon} />
                          )}
                          {FILE_TYPE.PPT.includes(value?.reply?.file.typeFile) && (
                            <PPTIcon style={styles.icon} />
                          )}
                          {FILE_TYPE.TXT.includes(value?.reply?.file.typeFile) && (
                            <TXTIcon style={styles.icon} />
                          )}
                          {(FILE_TYPE.ZIP.includes(value?.reply?.file.typeFile) ||
                            value?.reply?.file.name.includes(".rar")) && (
                            <ZIPIcon style={styles.icon} />
                          )}
                          {!FILE_TYPE.XLSX.includes(value?.reply?.file.typeFile) &&
                            !FILE_TYPE.PDF.includes(value?.reply?.file.typeFile) &&
                            !FILE_TYPE.DOC.includes(value?.reply?.file.typeFile) &&
                            !FILE_TYPE.PPT.includes(value?.reply?.file.typeFile) &&
                            !FILE_TYPE.TXT.includes(value?.reply?.file.typeFile) &&
                            !(
                              FILE_TYPE.ZIP.includes(value?.reply?.file.typeFile) ||
                              value?.reply?.file.name.includes(".rar")
                            ) && <FILEIcon style={styles.icon} />}
                        </Box>
                      </Box>
                      <Typography sx={(e) => styles.replyMessage(e, { isOwner })}>
                        {t("chat.type_message.file_messages")}
                      </Typography>
                    </Box>
                  )}
                  {value?.reply?.kind === MESSAGE_TYPE.AUDIO && (
                    <Box sx={styles.messageImage}>
                      <Box style={{ marginRight: "5px" }}>
                        <AudioIcon />
                      </Box>
                      <Typography sx={(e) => styles.replyMessage(e, { isOwner })}>
                        {t("chat.type_message.voice_messages")}
                      </Typography>
                    </Box>
                  )}
                  {value?.reply?.kind === MESSAGE_TYPE.TEXT && (
                    <Typography
                      color="initial"
                      variant="body1"
                      sx={(e) => styles.replyMessage(e, { isOwner })}
                    >
                      {truncatedReplyText}{" "}
                    </Typography>
                  )}
                  {value?.reply?.kind === MESSAGE_TYPE.EMOJI && (
                    <Box sx={styles.messageImage}>
                      <Box
                        sx={styles.boxImageEmoji}
                        className="imageUrl"
                        component="img"
                        src={
                          Stickers[value?.reply?.emoji?.stickerIndex]?.images[
                            value?.reply?.emoji?.index
                          ]
                        }
                      />
                      <Typography sx={(e) => styles.replyMessage(e, { isOwner })}>
                        {t("chat.type_message.sticker_messages")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {!urlRegex.exec(value?.text) &&
              !tagNameRegex.exec(value?.text) &&
              !tagManyNameRegex.exec(value?.text) && (
                <Box sx={(e) => styles.message(e, { isOwner })} className="message">
                  {isBrand && (
                    <Box dangerouslySetInnerHTML={{ __html: transferText(displayText) }} />
                  )}
                  {!isBrand && <p style={{ whiteSpace: "pre-line" }}>{displayText}</p>}
                  {value?.text && value.text.length > maxLength && (
                    <Box
                      onClick={handleToggleExpand}
                      style={{ marginTop: "5px", cursor: "pointer", float: "right" }}
                    >
                      <Typography
                        className="username"
                        color="initial"
                        variant="body1"
                        sx={(e) => styles.textMessageBottom(e, { isOwner })}
                      >
                        {expanded ? t("chat.hide_less") : t("chat.see_more")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            {!urlRegex.exec(value?.text) && tagManyNameRegex.exec(value?.text) && (
              <Box
                sx={(e) => styles.message(e, { isOwner })}
                className="message"
                dangerouslySetInnerHTML={{
                  __html: value?.text.replace(
                    tagManyNameRegex,
                    `${
                      isOwner
                        ? '<span style="color:#fff; font-weight:550">$1</span>'
                        : '<span style="color:#406EE2; font-weight:550">$1</span>'
                    }`
                  ),
                }}
              />
            )}
            {urlRegex.exec(value?.text) && (
              <Box sx={() => styles.linkContainer({ isOwner })}>
                <Box component={Link} target="_blank" href={urlRegex.exec(value?.text)[0]}>
                  <Box sx={styles.imageContainer}>
                    {value?.url_preview?.image ||
                    (value?.preview?.images && value?.preview?.images[0]) ? (
                      <Box
                        component="img"
                        src={value?.url_preview?.image || value?.preview?.images[0]}
                        sx={(e) => styles.image(e, { isOwner })}
                      />
                    ) : (
                      <Box
                        src={
                          value?.url_preview?.image ||
                          (value?.preview?.images && value?.preview?.images[0])
                        }
                        sx={(e) => styles.noImage(e, { isOwner })}
                      />
                    )}
                  </Box>
                  <Box sx={styles.linkContentContainer}>
                    {value?.preview?.description !== "NULL" && (
                      <Typography sx={(e) => styles.linkTitle(e, { isOwner })} variant="body1">
                        {value?.url_preview?.title || value?.preview?.title}
                      </Typography>
                    )}

                    {!bill && !urlVideo && (
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: value?.text?.replace(
                            urlRegex.exec(value?.text)[0],
                            `<a href="${urlRegex.exec(value?.text)[0]}" target="_blank">${
                              urlRegex.exec(value?.text)[0]
                            }</a>`
                          ),
                        }}
                        sx={(e) => styles.link(e, { isOwner })}
                      />
                    )}
                    {bill && (
                      <Box sx={(e) => styles.link(e, { isOwner })}>
                        {bill.list_product.map((item, index) => (
                          <Box key={index}>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Box>{item.name}</Box>
                              <Box>{item.number}</Box>
                              <Box>{item.money}</Box>
                            </Box>
                          </Box>
                        ))}
                        <div>total: {bill.money}</div>
                        <div>{bill.note}</div>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={(e) =>
                styles.emojiSection(e, { isOwner, isDisplayUsername: value?.user?.username })
              }
            >
              <Box
                className="imageUrl"
                component="img"
                src={Stickers[value.emoji.stickerIndex]?.images[value.emoji.index]}
                sx={styles.imageEmoji}
              />
            </Box>

            <Emotion value={value} handleOpenViewerDialog={handleOpenViewerDialog} />
          </Box>
        </Box>
        <ReplyAndForwardAndDetail
          value={value}
          handleOpenReplyMessage={handleOpenReplyMessage}
          handleOpenForwardDialog={handleOpenForwardDialog}
          handleOpenPopper={(e) => handleOpenPopper(e, isOwner, value)}
        />
      </Box>
    </Box>
  );
}

export default memo(MessageEmoji);
