export default {
  popper: {
    position: "absolute",
    zIndex: 9999,
  },
  paper: ({ borders }) => ({
    maxWidth: "500px",
    minWidth: "300px",
    height: "450px",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: borders.borderRadius.lg,
  }),

  divider: () => ({
    my: 0,
    py: 0,
    backgroundColor: "#3C3C434D",
  }),

  hoverIcon: {
    textAlign: "center",
    "&:hover": {
      borderRadius: 2,
      background: "#F3F3F3",
      cursor: "pointer",
    },
  },
  dFlex: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "0",
  },
  IconBox: {
    flexGrow: 1,
    heigh: "250px",
    overflowY: "auto",
    padding: "15px 13px 8px 21px",
  },
  borderRadius0: {
    borderRadius: 0,
    background: "#F3F3F3",
    border: "none",
  },
};
