import axiosInstance from "middleware/axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useGetSetting = (id) =>
  useQuery(
    ["/setting", id],
    async () => {
      const res = await axiosInstance.get(
        `/api/v1/voiprivate_services/get_setting_private_user?uid=${id}`
      );
      return res.error === 0 ? res.data : [];
    },
    {
      enabled: !!id,
    }
  );

export const useUpdateSetting = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["/setting"],
    async (setting) => {
      const { user_id, ...body } = setting;
      const data = await axiosInstance.post(
        `/api/v1/voiprivate_services/update_setting_private_user?uid=${user_id}`,
        body
      );
      return data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["/setting"]);
      },
    }
  );
};
