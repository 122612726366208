const chatBarHeight = "68px";
const chatWidth = "410px"; // 552px
const xxlChatWidth = "340px";
const xlChatWidth = "340px";
const lgChatWidth = "352px";

const informationWidth = "340px"; // 448px
const xxlInformationWidth = "340px";
const xlInformationWidth = "340px";
const lgInformationWidth = "302px";

const AdminNames = ["World golf handicap network", "vBank", "WBooking", "vBooking", "CSKH"];

const ACTION_TYPE = {
  DELETE_GROUP: 699,
  LEAVE_GROUP: 6999,

  UPDATE_GROUP: 7000,
};

const MESSAGE_TYPE = {
  TEXT: "text",
  LINK: "link",
  IMAGE: "image",
  FILE: "file",
  VIDEO: "video",
  AUDIO: "audio",
  EMOJI: "emoji",
  VOTE: "vote",
};

const MESSAGE_TYPE_MEDIA = {
  TEXT: 0,
  IMAGE: 1,
  VIDEO: 2,
  FILE: 3,
  AUDIO: 4,
  LINK: 6,
};
const FILE_TYPE = {
  XLSX: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"],
  DOC: [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ],
  PDF: ["application/pdf"],
  TXT: ["text/plain"],
  PPT: [
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-powerpoint",
  ],
  ZIP: ["application/x-zip-compressed"],
};

const SPECIAL_FILE_TYPE = {
  IMAGE: "image/",
  VIDEO: "video/",
};

const MESSAGE_STATE = {
  PENDING: 1,
  SENDING: 2,
  SENDED: 3,
  RESEND: 4,
  FAIL: 999,
};

const TYPE_MUTE = {
  TRUE: 0,
  FALSE: 1,
};
const VALUE_ACTION_MESSAGE = {
  LIKE: 5,
  DISLIKE: 6,
  SAD: 7,
  FUN: 8,
  LOVE: 9,
  TIP: 10,
};
const TYPE_ACTION_MESSAGE = {
  5: "like",
  6: "dislike",
  7: "sad",
  8: "haha",
  9: "love",
  10: "tip",
};
const CHAT_ACTIVE_TYPE = {
  sent: 0,
  deliver: 1,
  seen: 2,
  love: 3,
  angry: 4,
  haha: 5,
  cry: 6,
  deleteGroup: 699,
  deleteGroupForAll: 700,
  removedFromChat: 701,
  edit: 999,
  removeForMe: 1000,
  removeForAll: 1001,
  pinMessage: 4555,
  unPinMessage: 4556,
  unVote: 4999,
  vote: 5000,
  calendarLeave: 5999,
  calendarAdd: 6000,
  updateUserState: 6555,
  leaveGroup: 6999,
  updateGroup: 7000,
  unlike: 7001,
  typing: 7999,
  mute: 702,
  unMute: 703,
  hideChat: 704,
  unHideChat: 705,
  tip: 7,
  updatePoint: 4444,
};

const VALUE_TIP = [
  {
    value: 10000,
    text: "10k",
  },
  {
    value: 20000,
    text: "20k",
  },
  {
    value: 50000,
    text: "50k",
  },
  {
    value: 100000,
    text: "100k",
  },
  {
    value: 200000,
    text: "200k",
  },
  {
    value: 500000,
    text: "500k",
  },
];
const STATUS_SCORE = {
  BLUE: "blue",
  RED: "red",
  DRAW: "draw",
  NONE: "none",
};
export {
  chatBarHeight,
  chatWidth,
  xxlChatWidth,
  xlChatWidth,
  lgChatWidth,
  informationWidth,
  xxlInformationWidth,
  xlInformationWidth,
  lgInformationWidth,
  AdminNames,
  ACTION_TYPE,
  MESSAGE_TYPE,
  FILE_TYPE,
  MESSAGE_STATE,
  SPECIAL_FILE_TYPE,
  TYPE_MUTE,
  MESSAGE_TYPE_MEDIA,
  VALUE_ACTION_MESSAGE,
  TYPE_ACTION_MESSAGE,
  CHAT_ACTIVE_TYPE,
  VALUE_TIP,
  STATUS_SCORE,
};
