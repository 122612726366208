export default {
  dialog: ({ borders, breakpoints }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.xl,
        width: "100%",
        maxWidth: "500px",
        minWidth: "300px",
        [breakpoints.down("sm")]: {
          maxWidth: "430px",
        },
      },
    },
  }),
  container: {},
  numberPin: ({ typography }) => ({
    pr: 1,
    fontSize: typography.size.sm,
    // background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    background: "#406EE2",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
  }),
  iconDown: ({ openDialogPinMessage }) => ({
    p: 0,
    transform: openDialogPinMessage ? "rotate(180deg)" : "none",
    transition: "0.5s",
  }),
  titleText: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    fontSize: typography.size.md,
    textAlign: "center",
    fontFamily: typography.fontFamily3,
  }),
  saveEdit: ({ typography }) => ({
    // background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    background: "#406EE2",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: typography.size.sm,
    fontWeight: typography.fontWeightMedium,
    textAlign: "right",
    mr: 1.5,
    cursor: "pointer",
  }),
  IconButtonEdit: { py: 0, width: "40.5px" },
  textMessage: ({ typography }) => ({
    color: "rgb(64, 110, 226)",
    wordBreak: "break-all",
    textAlign: "left",
    fontSize: typography.size.sm,
    fontWeight: typography.fontWeightMedium,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    mr: 2,
    overflow: "hidden",
  }),
  textPin: { display: "flex" },
  textPin1: ({ typography }) => ({
    fontFamily: typography.fontFamily2,
    fontSize: typography.size.xs,
    color: "#999999",
    display: "inline",
  }),
  textPin2: ({ typography }) => ({
    fontFamily: typography.fontFamily2,
    fontSize: typography.size.xs,
    fontWeight: 600,
    color: "#090710",
    display: "inline",
    ml: 0.5,
  }),
  iconPin: ({ breakpoints }) => ({
    position: "fixed",
    transform: "translateX(230px) translateY(-15px)",
    [breakpoints.down("sm")]: {
      transform: "translateX(195px) translateY(-15px)",
    },
  }),
  DialogTittle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: 1.5,
    mt: 3,
  },
  boxLeft: { ml: 4 },
  boxRight: { mr: 4 },

  DividerT: {
    pt: 0.2,
    opacity: 1,
    mb: 0,
    mt: 2,
    backgroundColor: "#DCDCDC",
  },
  notificationMaxPin: {
    pt: 1,
    color: "#FF0000",
    fontSize: "14px",
  },
  listPin: {
    maxHeight: "450px",
    overflowY: "auto",
  },
  boxMessage: ({ index }) => ({
    py: 2,
    pl: 1,
    justifyContent: "space-between",
    display: "flex",
    cursor: "pointer",
    borderTop: index > 0 ? "0.5px dashed #C8C8C8" : "none",
  }),
  content: {
    display: "flex",
    flexGrow: 1,
  },
  messagePin: {
    textAlign: "center",
  },

  icon: {
    pt: 1.5,
  },
  IconClose: {
    mr: 1.5,
  },
  fileIcon: {
    mx: 1,
  },
  boxImage: {
    width: "40px",
    height: "40px",
    mr: 1.5,
    ml: 1,
    position: "relative",
  },
  boxStickerImage: {
    width: "40px",
    height: "40px",
    mr: 1.5,
    ml: 1,
    position: "relative",
  },
  image: ({ borders }) => ({
    flexShrink: 0,
    border: "1px solid #D5D5D5",
    width: "40px",
    objectFit: "cover",
    height: "40px",
    borderRadius: borders.borderRadius.md,
  }),
  imageSticker: {
    flexShrink: 0,
    width: "45px",
    height: "45px",
  },
  imageBg: ({ borders }) => ({
    background: "black",
    opacity: 0.5,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    height: "40px",
    borderRadius: borders.borderRadius.md,
  }),
  numberImagePin: ({ typography }) => ({
    position: "absolute",
    top: "30%",
    left: "30%",
    color: "#ffff",
    fontSize: "14px",
    fontFamily: typography.fontFamily3,
  }),
  boxMessagePin4: () => ({
    py: 2,
    pl: 1,
    justifyContent: "space-between",
    display: "flex",
  }),
  buttonAddPin: ({ borders }) => ({
    borderRadius: borders.borderRadius.lg,
    // background: "linear-gradient(90deg, #26BBFE 0%, #1BC88B 100%)",
    background: "#406EE2",
    mb: 3,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  addPin: ({ typography }) => ({
    fontWeight: 600,
    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xs,
    color: "#ffff",
  }),
};
