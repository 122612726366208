import LoginQR from "pages/LoginQR";
import Login from "pages/Login";
import Chat from "pages/Chat";
import AddChatSingle from "pages/Chat/component/AddChatSingle";
// name: string,
// route: string,
// component: node,
// require_token: boolean

const routes = [
  {
    name: "Login QR",
    route: "/qr",
    component: <LoginQR />,
    exact: true,
  },
  {
    name: "Login",
    route: "/login",
    component: <Login />,
    exact: true,
  },
  {
    name: "Chat",
    route: "/",
    component: <Chat />,
    require_token: true,
    exact: false,
  },
  {
    name: "Chat",
    route: "/t/:vID",
    component: <Chat />,
    require_token: true,
    exact: false,
  },
  {
    name: "AddChatSingle",
    route: "/map-chat-by-user/:vID",
    component: <AddChatSingle />,
    exact: false,
  },
];

export default routes;
