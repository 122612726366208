import { BASE_URL_API_V2 } from "config/keys";
import axiosInstance from "middleware/axios";

const checkQrCodeLogin = (params) =>
  axiosInstance.get(`${BASE_URL_API_V2}/api/utils/check_qrcode_login`, { params });

const genQrCode = (params) =>
  axiosInstance.get(`${BASE_URL_API_V2}/api/utils/gen_qrcode`, { params });

export { checkQrCodeLogin, genQrCode };
