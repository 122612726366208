import { chatWidth, lgChatWidth, xlChatWidth, xxlChatWidth } from "constant/chat";

export default {
  drawer: ({ palette, transitions, breakpoints }) => ({
    width: chatWidth,
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
    "& .MuiDrawer-paper": {
      overflow: "visible",
      width: chatWidth,
      borderRight: `1px solid ${palette.grey[200]}`,
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    [breakpoints.down("xxl")]: {
      width: xxlChatWidth,
      "& .MuiDrawer-paper": {
        width: xxlChatWidth,
      },
    },
    [breakpoints.down("xl")]: {
      width: xlChatWidth,
      "& .MuiDrawer-paper": {
        width: xlChatWidth,
      },
    },
    [breakpoints.down("lg")]: {
      width: lgChatWidth,
      "& .MuiDrawer-paper": {
        width: lgChatWidth,
      },
    },
    [breakpoints.down("md")]: {
      width: "100%",
      "& .MuiDrawer-paper": {
        width: "100%",
      },
    },
  }),
  boxA: {
    height: "100%",
  },
  boxHeader: {
    // px: 2,
    pt: 2.5,
    textAlign: "center",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    minHeight: "60px",
    mb: 2,
  },
  personalContainer1: {
    marginBottom: "25px",
  },
  avatarContainer: ({ palette }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: palette.success.light,
      bottom: 9,
      right: 9,
      height: 15,
      minWidth: 14,
      border: `2px solid ${palette.white.main}`,
    },
    // mt: 1,
  }),
  avatar: {
    width: "48px",
    height: "48px",
    border: "1px solid #FFF",
  },
  listMessagse: {
    borderRadius: "10px",
    cursor: "pointer",
    marginBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "48px",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  phonebook: {
    borderRadius: "10px",
    cursor: "pointer",
    marginBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "48px",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  setting: {
    borderRadius: "10px",
    cursor: "pointer",
    marginBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "48px",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  name: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    lineHeight: "18px",
    overflow: "hidden",
    height: "18px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
  }),
  settingIcon: {
    width: "45px",
    height: "45px",
  },
  searchIcon: {
    mx: 0.5,
    cursor: "default",
  },

  search: ({ isClicked }) => ({
    width: "100%",
    height: "44px",
    borderRadius: "100px",
    fieldset: {
      borderRadius: "100px",
      border: "none",
      height: "50px",
    },
    input: {
      py: 1.5,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
    border: isClicked ? "1px solid var(--button_new, #406EE2)" : "none",
    backgroundColor: isClicked ? "none" : "#F6F6F6",
  }),
  menu: {
    backgroundColor: "#142D5B",
    height: "100%",
  },
  userList: {
    maxHeight: "calc(100vh - 157px)",
    overflowY: "scroll",
    pl: 2,
    pr: 1,
    pb: 4,
    mt: 2,
  },
  userListAdd: {
    maxHeight: "calc(100vh - 240px)",
    overflow: "auto ", // Sử dụng "auto" để hiển thị thanh cuộn chỉ khi cần thiết
    pl: 2,
    pr: 1,
  },
  userContainer: ({ breakpoints }) => ({
    overflowY: "auto",
    maxWidth: chatWidth,
    [breakpoints.down("xxl")]: {
      maxWidth: xxlChatWidth,
      "& .MuiDrawer-paper": {
        maxWidth: xxlChatWidth,
      },
    },
    [breakpoints.down("xl")]: {
      maxWidth: xlChatWidth,
      "& .MuiDrawer-paper": {
        maxWidth: xlChatWidth,
      },
    },
    [breakpoints.down("lg")]: {
      maxWidth: lgChatWidth,
      "& .MuiDrawer-paper": {
        maxWidth: lgChatWidth,
      },
    },
    [breakpoints.down("md")]: {
      maxWidth: "100%",
      "& .MuiDrawer-paper": {
        maxWidth: "100%",
      },
    },
  }),

  //* * Style Loading */
  loadingContainer: {
    display: "flex",
    mb: 2,
    justifyContent: "space-between",
  },
  loadSearch: {
    borderRadius: "100px",
  },
  loadInformation: {
    display: "flex",
    alignItems: "center",
  },
  loadAvatar: {
    width: "60px",
    height: "60px",
    mr: 1.5,
  },
  loadName: {
    mb: 1,
  },
  loadTime: {
    mt: 1,
  },
  settingSkeleton: {
    margin: "5px",
    width: "25px",
    height: "25px",
    borderRadius: "4px",
  },
  messagseTile: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    color: "#000",
    leadingTrim: "both",
    fontFamily: typography.fontFamily3,
    textEdge: "cap",
    fontSize: "32px",
    fontStyle: "normal",
    lineHeight: "50px",
  }),
  messagseTileBook: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    color: "#000",
    leadingTrim: "both",
    fontFamily: typography.fontFamily3,
    textEdge: "cap",
    fontSize: "32px",
    fontStyle: "normal",
    lineHeight: "50px",
    margin: "20px 10px 10px 20px",
  }),
  backIconContainer: {
    display: "flex",
    p: 2,
    alignItems: "center",
  },
  backButton: {
    px: 0,
    mr: 2,
    my: 0,
  },
  backIcon: {
    width: "24px",
    height: "24px",
  },
  username: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    lineHeight: "18px",
    height: "18px",
    whiteSpace: "normal", // Change to "normal" instead of "pre-wrap"
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  vid: ({ typography }) => ({
    color: "var(--text1, #999)",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
  }),
  personalContainer: ({ selectedBox, boxIndex }) => ({
    pr: 2,
    pl: 2,
    minHeight: "55px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#E5F0FF",
      borderRadius: "5px",
      borderTop: "none",
      borderBottom: "none",
    },
    borderBottom: `1px solid #E7E7E7`,
    backgroundColor: selectedBox === boxIndex ? "#E5F0FF" : "initial",
    borderRadius: selectedBox === boxIndex ? "5px" : "initial",
    // marginTop: "10px",
  }),
  personalTitle: ({ typography }) => ({
    fontWeight: typography.fontFamily,
    lineHeight: "55px",
  }),
  addIcon: {
    width: "24px",
    height: "24px",
    marginTop: "2px",
    marginLeft: "2px",
  },
  searchType: {
    ml: 1,
    mb: 0.5,
  },
  listUserContainer: {
    height: "100%", // Sử dụng chiều cao 100% của parent container
    overflow: "auto ",
    pr: 1,
    mt: 1,
  },
  singleContainer: ({ selectedBox, boxIndex }) => ({
    py: 1,
    px: 1,
    display: "flex",
    borderRadius: "5px",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginTop: "5px",
    cursor: "pointer",
    backgroundColor: selectedBox === boxIndex ? "#E5F0FF" : "initial",
    "&:hover": {
      backgroundColor: "#E5F0FF",
    },
  }),
  singleAvatar: {
    width: "40px",
    height: "40px",
    border: "0.1px solid lightgray",
  },
  avatarContainerSetting: {
    display: "flex",
    alignItems: "center",
    px: 2,
    marginTop: "40px",
  },
  avatarSetting: {
    width: "70px",
    height: "70px",
    mr: 2,
  },
  nameSetting: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    fontSize: "18px",
    lineHeight: "18px",
    overflow: "hidden",
    height: "18px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
  }),
  vgaSetting: ({ palette }) => ({
    color: palette.grey[650],
    lineHeight: "16px",
    overflow: "hidden",
    height: "16px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    mt: 1,
  }),
  singleNameContainer: {
    ml: 1.5,
    // display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
    float: "left",
  },
  groupContainer: {
    display: "flex",
    alignItems: "center",
    mb: 2,
  },
  cameraContainer: {
    width: "30px",
    mt: 0.5,
  },
  groupNameContainer: {
    width: "100%",
    ml: 2,
  },
  groupName: {
    width: "100%",
  },
  addGroupChatContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    // ml: 1,
    marginTop: "15px",
  },
  addGroupIconContainer: {
    width: "100%",
    height: "100%",
  },
  addGroupChatIcon: {
    width: "100%",
    height: "100%",
  },
  memberContainer: {
    display: "flex",
    overflowY: "scroll",
    ml: 2,
    mb: 0.5,
    height: "65px",
  },
  memberSection: {
    mr: 2,
  },
  memberAvatarContainer: {
    position: "relative",
  },
  memberAvatar: {
    width: "50px",
    height: "50px",
  },
  removeMemberIconContainer: {
    position: "absolute",
    top: "0px",
    right: "-8px",
    backgroundColor: "#8D929F !important",
    width: "20px",
    height: "20px",
    "&:hover": {
      opacity: 0.9,
    },
  },
  removeMemberIcon: {
    width: "12px",
    height: "12px",
    fill: "#fff",
  },
  userGroupContainer: {
    overflow: "scroll",
    pb: 2,
  },
  checkContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    mr: 0,
    label: {
      width: "100%",
    },
    svg: {
      width: "18px !important",
      height: "18px !important",
    },
  },
  labelContainer: {
    py: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  groupAvatar: {
    width: "44px",
    height: "44px",
  },
  groupUsernameContainer: {
    display: "flex",
    userSelect: "none",
    ml: 1,
  },
  overlay: ({ palette, functions, borders }) => ({
    position: "absolute",
    display: "none",
    width: "100%",
    height: "100%",
    left: 0,
    bottom: 0,
    backgroundColor: functions.rgba(palette.black.main, 0.3),
    zIndex: 1,
    flexGrow: 1,
    borderRadius: borders.borderRadius.round,
    justifyContent: "center",
    alignItems: "center",
  }),
  updateAvatarIcon: {
    fill: "white",
  },
  cropAvatar: {
    width: "50px",
    height: "50px",
  },
  tabList: {
    mx: 2,
    my: 1.5,
    background: "#F6F6F6",
    borderRadius: "100px",
    justifyContent: "space-between",
    "& button[aria-selected='true']": {
      boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.1)",
    },
  },
  tab: (value, i) => ({
    p: 0.75,
    borderRadius: "100px",
    textAlign: "center",
    background: value === i ? "linear-gradient(90deg, #ffff 0%, #ffff 100%);" : "#F6F6F6",
    transition: "background 5s ease-in-out",
  }),
  textDefault: ({ palette }) => ({
    borderRadius: "100px",
    marginTop: "3px",
    color: palette.grey[900],
  }),
  textActive: {
    borderRadius: "100px",
    fontWeight: 700,
    marginTop: "3px",
    color: "#0089ff",
  },
  languageContainer: ({ breakpoints }) => ({
    width: "100%",
    float: "left",
    minHeight: "55px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#E5F0FF",
      borderRadius: "5px",
      borderTop: "none",
      borderBottom: "none",
    },
    // marginTop: "10px",
    // marginBottom: "10px",
    borderBottom: `1px solid #E7E7E7`,
    [breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
  formSelect: {
    border: "none",
    width: "100%",
  },
  select: {
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    outline: "none",
    width: "100%",
    borderRadius: "4px",
    marginLeft: "2px",
  },
  selectLang: {
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    PaperProps: {
      sx: {
        // mt: 1,
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
        "& .MuiMenuItem-root.Mui-selected": {
          background: "#DDF7F9",
        },
        "& .MuiMenuItem-root:hover": {
          background: "#F2F2F2",
        },
        "& .MuiMenuItem-root.Mui-selected:hover": {
          background: "#DDF7F9",
        },
      },
    },
  },
  menuItemLang: {
    border: "none",
    mt: 0.5,
    p: 1,
    "&.Mui-selected": {
      backgroundColor: "#FFF",
      /* Thay #FFF bằng mã màu hoặc tên màu bạn muốn sử dụng */
    },
  },
  boxLang: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  lang: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    p: 0,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  nameLang: () => ({
    ml: 1,
    color: "#3D3D3D",
    fontSize: "16px",
    fontWeight: 600,
  }),
};
