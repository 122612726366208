import colors from "assets/theme/base/colors";
import { chatWidth, lgChatWidth, xlChatWidth, xxlChatWidth } from "constant/chat";

const { primary, initial } = colors;
export default {
  paper: {
    boxShadow: "none",
  },
  dFlex: {
    display: "flex",
  },
  selectMulti: { padding: "0px 17px 10px", display: "flex", alignItems: "center" },
  flexGrow: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },

  fillColorIconLeft: {
    fill: "#00ABA7",
    width: "24px",
    height: "24px",
  },

  popperPoll: {
    height: "380px",
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.15)",
  },

  popper: ({ breakpoints }) => ({
    zIndex: "9999",
    width: "100%",
    [breakpoints.up("md")]: {
      maxWidth: `calc(100% - ${lgChatWidth})`,
    },
    [breakpoints.up("sm")]: {
      width: "100%",
    },
    [breakpoints.up("xxl")]: {
      maxWidth: `calc(100% - ${chatWidth})`,
    },
    [breakpoints.up("xl")]: {
      maxWidth: `calc(100% - ${xxlChatWidth})`,
    },
    [breakpoints.up("lg")]: {
      maxWidth: `calc(100% - ${xlChatWidth})`,
    },
  }),

  boxHeader: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #C7C7C7;",
  },
  boxTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textTitle: ({ typography }) => ({
    py: 2.5,
    mr: 15,
    fontFamily: typography.fontFamily2,
    fontWeight: "700",
    fontSize: "18px",
    textTransform: "uppercase",
  }),
  pointContainer: {
    display: "flex",
    alignItems: "center",
    ml: 1.5,
  },
  surplus: ({ typography, palette }) => ({
    fontSize: typography.size.xs,
    fontWeight: "400px",
    fontFamily: typography.fontFamily3,
    color: palette.initial.main,
  }),
  point: ({ typography }) => ({
    ml: 0.5,
    fontSize: typography.size.sm,
    fontWeight: "700px",
    fontFamily: typography.fontFamily3,
    color: "#FFB92B",
  }),
  infoPoint: {
    display: "flex",
    ml: 1,
  },
  noteContainer: {
    mt: 3,
    textAlign: "center",
  },
  titleNoteOne: ({ typography, breakpoints }) => ({
    fontSize: typography.size.sm,
    fontFamily: typography.fontFamily3,
    color: "#636363",
    [breakpoints.down("sm")]: {
      fontSize: typography.size.xs,
    },
  }),
  titleNoteTwo: ({ typography, breakpoints }) => ({
    mt: 0.5,
    fontSize: typography.size.xs,
    fontFamily: typography.fontFamily3,
    color: "#406EE2",
    [breakpoints.down("sm")]: {
      fontSize: typography.size.xxs,
    },
  }),
  icon: { width: "24px", height: "24px" },

  textQuestion: ({ typography }) => ({
    padding: "20px 17px 0 ",
    fontSize: "16px",
    width: "100%",
    input: {
      fontFamily: `${typography.fontFamily2} !important`,
      fontSize: "16px",
      marginBottom: "20px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: `1.5px solid ${primary.main}`,
    },
  }),

  InputAdornment: ({ typography }) => ({
    marginBottom: "20px",
    fontSize: "16px",
    fontFamily: typography.fontFamily2,
  }),

  boxAnswer: ({ breakpoints }) => ({
    my: 4,
    textAlign: "center",
    maxHeight: "230px",
    mx: "auto",
    maxWidth: "440px",
    [breakpoints.up("sm")]: {
      maxWidth: "400px",
    },
  }),
  buttonPoint: ({ typography }, { value }) => ({
    px: 1,
    borderRadius: 6,
    minWidth: "110px",
    fontSize: typography.size.xs,
    fontFamily: typography.fontFamily3,
    color: value ? "#406EE2" : "#FFB92B",
    border: value ? "2px solid #406EE2" : "2px solid #E0E0E0",
    "&:focus": {
      border: "2px solid #406EE2",
      color: "#406EE2",
    },
    "&:hover": {
      border: "2px solid #406EE2",
      color: "#406EE2",
    },
    "&:active": {
      border: "2px solid #406EE2",
      color: "#406EE2",
    },
  }),

  inputAnswer: ({ typography }) => ({
    pt: 2,
    flexGrow: 1,
    fontSize: "16px",
    textAlign: "center",
    input: {
      fontFamily: typography.fontFamily2,
      mb: 2,
    },
    "&:focus": {
      outline: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: `1.5px solid ${primary.main}`,
    },
  }),

  dragIcon: {
    justifyContent: "end",
    pt: "20px",
    position: "relative",
    top: "-18px",
    marginRight: "17px",
  },

  addAnswerIcon: { justifyContent: "start", pt: "20px" },

  dFlexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTipPoint: {
    px: 1.5,
    borderRadius: 6,
    border: "none",
    background: "#406EE2",
    "&:hover": {
      border: "none",
      background: "#406EE2",
    },
  },
  textTip: ({ typography }) => ({
    ml: 1,
    color: "#ffff",
    fontSize: typography.size.xs,
    fontWeight: "500px",
    fontFamily: typography.fontFamily3,
  }),

  textColorBlack: { color: initial.main },
};
