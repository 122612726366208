import axiosInstance from "middleware/axios";
import axios from "axios";
import { UPLOAD_URL } from "config/keys";

const OLD_UPLOAD_URL = `${process.env.REACT_APP_BASE_URL}/static/uploads/`;

const searchVGA = (params) => axiosInstance.get(`/api/account/search_by_vga`, { params });

const searchGolfer = (params, data) =>
  axiosInstance.post(`https://api-cms-prod.vcallid.com/other_service/search_golfer`, data, {
    params,
  });

const websitePreview = (data) =>
  axiosInstance.post("https://api-v2.vcallid.com/api/utils/web_thumbnail", data).then((res) => {
    if (res.error_code === 0) return res.data;
    return undefined;
  });

const downloadMedia = async (urls, name = null) => {
  let arrUrl = urls;
  if (!Array.isArray(arrUrl)) {
    arrUrl = [urls];
  }
  const downloadPromises = arrUrl.map((url) =>
    axios({
      url,
      method: "GET",
      responseType: "blob",
    })
  );

  Promise.all(downloadPromises)
    .then((responses) => {
      responses.forEach((response, index) => {
        const fileName =
          name || arrUrl[index].replace(`${UPLOAD_URL}/`, "").replace(OLD_UPLOAD_URL, "");

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(new Blob([response.data]));
        downloadLink.setAttribute("download", fileName);

        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
      });
    })
    .catch((error) => error);
};
const uploadMediaResource = (data) =>
  axios.post(`https://upload.vcallid.com/api/public/post`, data);
export { searchVGA, websitePreview, searchGolfer, downloadMedia, uploadMediaResource };
