import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "assets/styles/pages/Chat/component/NotificationAddFriend";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";

export default function NotificationAddFriend({ isAddFriend, isBlockFriend, handleAddFriend }) {
  const { t } = useTranslation();
  const notification = isAddFriend
    ? t("chat.header.notification.add_friend")
    : t("chat.header.notification.un_block");
  const buttonAdd = isAddFriend
    ? t("chat.header.notification.buttonAdd")
    : t("chat.header.notification.buttonBlock");

  return (
    <Fade in={isAddFriend || isBlockFriend}>
      <Box sx={() => styles.notification({ isAddFriend, isBlockFriend })}>
        <Box sx={styles.boxAdd1}>
          <Typography variant="caption" sx={styles.nameAdd}>
            Nguyen Kelly
            <Typography variant="caption" sx={styles.title}>
              {notification}
            </Typography>
          </Typography>
        </Box>
        <Button onClick={handleAddFriend} sx={styles.buttonAdd} variant="outlined">
          {buttonAdd}
        </Button>
      </Box>
    </Fade>
  );
}
