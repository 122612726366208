/* eslint-disable default-param-last */
import * as constants from "redux/constants";

const initialState = [];

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.ROUTE_UPDATE:
      return [...payload];
    default:
      return state;
  }
};

export default reducer;
