import {
  chatBarHeight,
  chatWidth,
  lgChatWidth,
  xlChatWidth,
  xxlChatWidth,
  informationWidth,
  xxlInformationWidth,
} from "constant/chat";

export default {
  appBar: ({ breakpoints, transitions }, { isOpenInformation }) => ({
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
    // boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 4px 4px",
    position: "absolute",
    left: chatWidth,
    width: `calc(100% - ${chatWidth}${isOpenInformation ? ` - ${informationWidth}` : ""})`,
    height: chatBarHeight,
    [breakpoints.down("xxl")]: {
      left: xxlChatWidth,
      width: `calc(100% - ${xxlChatWidth}${isOpenInformation ? ` - ${xxlInformationWidth}` : ""})`,
    },
    [breakpoints.down("xl")]: {
      left: xlChatWidth,
      width: `calc(100% - ${xlChatWidth})`,
    },
    [breakpoints.down("lg")]: {
      left: lgChatWidth,
      width: `calc(100% - ${lgChatWidth})`,
    },
    [breakpoints.down("md")]: {
      left: 0,
      width: "100%",
    },
  }),
  toolbar: {
    py: { xs: 1.5, sm: 1 },
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    marginTop: "2px",
  },
  informationContainer: {
    display: "flex",
    alignItems: "center",
  },
  backButton: ({ palette }) => ({
    color: palette.grey[200],
    mr: 1,
  }),
  backIcon: {
    width: "28px",
    height: "28px",
  },
  avatar: (click = false) => ({
    width: "40px",
    height: "40px",
    marginLeft: "-5px",
    mr: 1.5,
    border: "0.1px solid lightgray",
    "&:hover": click && {
      cursor: "pointer",
      userSelect: "none",
    },
  }),
  name: ({ typography }, click = false) => ({
    fontWeight: typography.fontWeightBold,
    fontFamily: `${typography?.fontFamily2} !important`,
    fontSize: "18px",
    lineHeight: "23px",
    overflow: "hidden",
    height: "23px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    cursor: "pointer",
    "&:hover": click && {
      cursor: "pointer",
      userSelect: "none",
    },
  }),
  onlineTime: ({ isGroup, typography }) => ({
    color: "#8A8A8D",
    lineHeight: "16px",
    overflow: "hidden",
    height: "16px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    mt: 0.5,
    "&:hover": isGroup && {
      // background: "-webkit-linear-gradient(#26BBFE 3.27%, #1BC88B 97.45%)",
      background: "#406EE2",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontFamily: typography?.fontFamily2,
      cursor: "pointer",
      userSelect: "none",
    },
  }),

  actionContainer: {
    display: "inline-flex",
  },
  icon: {
    marginRight: "-15px",
  },
  iconKtv: {
    marginRight: "5px",
  },
  noTiAddFriend: {
    with: "100%",
    background: "linear-gradient(90deg, #009ACB 0%, #3176DE 100%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    py: 1,
    px: { xs: 2, sm: 3 },
  },
  boxAdd1: {
    display: "flex",
    opacity: 0.8,
  },
  nameAdd: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    color: "#ffff",
    fontWeight: typography.fontWeightBold,
  }),
  noTiTitle: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    color: "#ffff",
    ml: 0.5,
    fontWeight: typography.fontWeightRegular,
  }),
  buttonAdd: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    color: "#ffff",
    flexShrink: 0,
    fontWeight: 600,
    fontSize: "13.5px",
    borderRadius: 6,
    border: "1px solid #ffff",
    "&:hover": {
      borderColor: "#ffff",
    },
  }),
  headerIcon: {
    height: "22px",
  },
  channelContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  scoreContainer: {
    display: "flex",
    position: "absolute",
    color: "#ffff",
  },
  scoreRed: {
    color: "#ffff",
    textAlign: "center",
    mx: "auto",
    fontWeight: 600,
    mr: 2.5,
  },
  scoreBlue: {
    color: "#ffff",
    textAlign: "center",
    fontWeight: 600,
    mx: "auto",
  },
  TextScore: {
    color: "#ffff",
    fontWeight: 600,
    fontSize: "16px",
  },
  TextS: {
    color: "#ffff",
    fontWeight: 600,
    fontSize: "14px",
    mx: 0.5,
  },
  TextHoleNone: {
    color: "#ffff",
    fontWeight: 600,
    fontSize: "14px",
  },
};
