import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "assets/styles/pages/Chat/component/NotifyStatusPinMessage";
import { Box, Typography } from "@mui/material";

function NotifyStatusPin({ openDialogNotifyStatusPin, handleOpenNotifyPin }) {
  return (
    <Box>
      <Dialog
        open={openDialogNotifyStatusPin}
        onClose={() => handleOpenNotifyPin(false)}
        sx={styles.dialog}
      >
        <Box sx={styles.container}>
          <Box textAlign="center">
            <DialogTitle>
              <Box>
                <Typography sx={styles.titleText}>Thông báo</Typography>
                <Box sx={styles.boxMessage}>
                  <Typography sx={styles.message}>
                    Tin nhắn không tồn tại hoặc đã bị thu hồi
                  </Typography>
                </Box>
              </Box>
            </DialogTitle>
          </Box>
          <Box onClick={() => handleOpenNotifyPin(false)} sx={styles.action}>
            <Typography sx={styles.cancel}>Ok</Typography>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default React.memo(NotifyStatusPin);
