export default {
  container: {
    display: "flex",
    height: "100vh",
  },
  header: ({ breakpoints }, { isHide }) => ({
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    [breakpoints.down("md")]: {
      display: isHide ? "none" : "flex",
    },
  }),
};
