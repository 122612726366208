import { Avatar, Divider, Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import playVideo from "assets/images/play_channel.png";
import styles from "assets/styles/pages/Chat/section/ChatInformation";
import { ReactComponent as DOCIcon } from "assets/svg/icon/doc_message.svg";
import { ReactComponent as FILEIcon } from "assets/svg/icon/file_message.svg";
import { ReactComponent as PDFIcon } from "assets/svg/icon/pdf_message.svg";
import { ReactComponent as PPTIcon } from "assets/svg/icon/ppt_message.svg";
import { ReactComponent as TXTIcon } from "assets/svg/icon/txt_message.svg";
import { ReactComponent as XLSXIcon } from "assets/svg/icon/xlsx_message.svg";
import { ReactComponent as ZIPIcon } from "assets/svg/icon/zip_message.svg";
import TabPanel from "components/TabPanel";
import { FILE_TYPE } from "constant/chat";
import { t } from "i18next";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useGetListMedia } from "service";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

import { urlRegex } from "constant/regex";
import moment from "moment";
import Video from "yet-another-react-lightbox/plugins/video";
import Multimedia from "../component/Multimedia";

export default function Media({ type, nameTab, groupId, setOpenAll, openAll }) {
  const [limit, setLimit] = useState(20);
  const { data: listMessageMedia } = useGetListMedia(groupId, type, limit);

  const [isOpenPreview, setOpenPreview] = useState(false);

  const [indexShow, setIndexShow] = useState(0);
  const handleClosePreview = () => setOpenPreview(false);

  const [listImg, setListPrev] = useState([]);
  // const [listMessageMedia, setListMessageMedia] = useState([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  useEffect(() => {
    if (openAll && listMessageMedia?.count < limit) setLimit(listMessageMedia?.count || 20);
  }, [openAll]);

  useEffect(() => {
    setOpenAll(false);
  }, [groupId]);

  const playAudio = (id) => {
    if (currentlyPlaying) {
      currentlyPlaying.pause();
    }
    const audio = document.getElementById(id);
    setCurrentlyPlaying(audio);
    audio.play();
  };
  const handleOpenPreview = (index) => {
    setOpenPreview(true);
    setIndexShow(index);
  };

  const handleDownloadFile = (v) => {
    if (v?.file?.url) saveAs(v.file.url, v.file.name);
  };

  useEffect(() => {
    if (type === 1)
      setListPrev(
        listMessageMedia?.data
          ?.filter(({ media_type }) => media_type === 1)
          ?.map((item) => item.message.image)
          .flat()
      );
    if (type === 2)
      setListPrev(
        listMessageMedia?.data
          ?.filter(({ media_type }) => media_type === 2)
          ?.map((item) => item.message.video)
      );
  }, [listMessageMedia]);

  if (openAll)
    return (
      <Multimedia
        isMediumSize={false}
        handleCloseMultimedia={() => setOpenAll(false)}
        isOpenMultimediaDialog={openAll}
        listMessageMedia={listMessageMedia?.data || []}
        textMedia={nameTab}
        indexMedia={type}
      />
    );

  if (!openAll)
    return (
      <>
        {type === 1 && (
          <Lightbox
            plugins={[Thumbnails]}
            open={isOpenPreview}
            close={handleClosePreview}
            carousel={{
              finite: true,
              preload: listImg?.length || 0,
            }}
            animation={{ navigation: 0 }}
            index={indexShow}
            slides={listImg?.map((image) => ({ src: image.url }))}
            thumbnails={{ width: 120, height: 80, padding: 0 }}
          />
        )}

        {type === 2 && (
          <Lightbox
            plugins={[Video]}
            open={isOpenPreview}
            close={handleClosePreview}
            animation={{ navigation: 0 }}
            index={indexShow}
            slides={listImg?.map((v) => ({
              type: "video",
              width: 1280,
              height: 720,
              autoPlay: true,
              sources: [
                {
                  src: v.url,
                  type: "video/mp4",
                },
              ],
            }))}
          />
        )}

        {listMessageMedia && (
          <TabPanel value={type} sx={styles.tabPanel} index={type}>
            {type === 1 && (
              <Grid container spacing={1} style={{ marginTop: "1px" }}>
                {listImg?.slice(0, 12).map((image, index) => (
                  <Grid
                    onClick={() => handleOpenPreview(index)}
                    xs={12 / 5}
                    sm={12 / 7}
                    md={4}
                    lg={3}
                    key={index}
                  >
                    <Avatar
                      src={image.url}
                      style={{ marginBottom: "8px" }}
                      sx={styles.imgMedia}
                      variant="rounded"
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            {type === 2 && (
              <Grid container spacing={1} style={{ marginTop: "1px" }}>
                {listMessageMedia?.data
                  ?.filter(({ media_type }) => media_type === 2)
                  ?.slice(0, 12)
                  .map((item, index) => (
                    <Grid
                      sx={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                      xs={12 / 5}
                      sm={12 / 7}
                      md={4}
                      lg={3}
                      key={index}
                    >
                      <Box
                        component="img"
                        src={
                          item.message?.video?.url_thumb ||
                          item.message?.video?.url_thumb ||
                          item.message?.video?.url_thumb
                        }
                        sx={styles.imgMediaVideo}
                      />
                      <Box
                        onClick={() => handleOpenPreview(index)}
                        component="img"
                        src={playVideo}
                        sx={styles.imgPlayVideo}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}

            {type === 3 && (
              <Box style={{ marginTop: "5px" }}>
                {listMessageMedia?.data
                  ?.filter(({ media_type }) => media_type === 3)
                  ?.slice(0, 4)
                  .map(({ message: v }, indexFile) => (
                    <>
                      <Box
                        key={indexFile}
                        sx={styles.itemContainer}
                        onClick={() => handleDownloadFile(v)}
                      >
                        <Box sx={styles.iconContainer}>
                          {FILE_TYPE.XLSX.includes(v.file.typeFile) && (
                            <XLSXIcon style={styles.icon} />
                          )}
                          {FILE_TYPE.PDF.includes(v.file.typeFile) && (
                            <PDFIcon style={styles.icon} />
                          )}
                          {FILE_TYPE.DOC.includes(v.file.typeFile) && (
                            <DOCIcon style={styles.icon} />
                          )}
                          {FILE_TYPE.PPT.includes(v.file.typeFile) && (
                            <PPTIcon style={styles.icon} />
                          )}
                          {FILE_TYPE.TXT.includes(v.file.typeFile) && (
                            <TXTIcon style={styles.icon} />
                          )}
                          {(FILE_TYPE.ZIP.includes(v.file.typeFile) ||
                            v.file.name.includes(".rar")) && <ZIPIcon style={styles.icon} />}
                          {!FILE_TYPE.XLSX.includes(v.file.typeFile) &&
                            !FILE_TYPE.PDF.includes(v.file.typeFile) &&
                            !FILE_TYPE.DOC.includes(v.file.typeFile) &&
                            !FILE_TYPE.PPT.includes(v.file.typeFile) &&
                            !FILE_TYPE.TXT.includes(v.file.typeFile) &&
                            !(
                              FILE_TYPE.ZIP.includes(v.file.typeFile) ||
                              v.file.name.includes(".rar")
                            ) && <FILEIcon style={styles.icon} />}
                        </Box>
                        <Box sx={styles.informationContainer}>
                          <Typography variant="body1" color="inherit.main" sx={styles.nameFile}>
                            {v.file.name}
                          </Typography>
                          <Typography variant="body2" sx={styles.vga}>
                            {`${v.user.username} `}{" "}
                            {moment(v.file.createdAt).format("HH:mm DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider sx={styles.divider} />
                    </>
                  ))}
              </Box>
            )}

            {type === 4 && (
              <Box style={{ marginTop: "5px" }}>
                {listMessageMedia?.data
                  ?.filter(({ media_type }) => media_type === 4)
                  ?.slice(0, 3)
                  ?.map(({ message: v }) => (
                    <Box
                      key={v?.id}
                      style={{ width: "100%", marginTop: "19px", textAlign: "center" }}
                    >
                      <audio
                        controls
                        id={`audio_${v?.id}`}
                        onPlay={() => playAudio(`audio_${v?.id}`)}
                      >
                        <source src={v?.audio?.url} type="audio/mp3" />
                        <track kind="captions" srcLang="en" label="English" />
                      </audio>
                    </Box>
                  ))}
              </Box>
            )}

            {type === 6 && (
              <Box style={{ marginTop: "5px" }}>
                {listMessageMedia?.data
                  ?.filter(({ media_type }) => media_type === 6)
                  ?.slice(0, 4)
                  .map(({ message: v }, indexFile) => (
                    <Box
                      key={indexFile}
                      sx={styles.itemContainer}
                      component={Link}
                      target="_blank"
                      href={urlRegex.exec(v?.text)?.[0]}
                    >
                      {v?.preview?.description !== "NULL" ? (
                        <Box sx={styles.iconContainerLinkMessage} style={{ height: "100%" }}>
                          <Box
                            component="img"
                            src={v?.url_preview?.image || v?.preview?.images?.[0]}
                            style={{ objectFit: "contain", height: "100%", padding: "4px" }}
                            sx={styles.messageLinkImage}
                          />
                        </Box>
                      ) : (
                        <Box sx={styles.linkNoImageMessage} />
                      )}
                      <Box sx={styles.informationContainer}>
                        <Typography
                          variant="body1"
                          color="inherit.main"
                          sx={styles.nameLinkMessage}
                        >
                          {v.url_preview?.title || v?.preview?.title}
                        </Typography>
                        <Typography variant="body2" sx={styles.vga}>
                          {`${v.user.username} `} {moment(v.createdAt).format("HH:mm DD/MM/YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
              </Box>
            )}

            {!openAll && listMessageMedia?.data.length > 3 && (
              <Box sx={styles.showAll} onClick={() => setOpenAll(true)}>
                <Typography sx={styles.textAll}>{t("chat.chat_information.seeAll")}</Typography>
              </Box>
            )}
          </TabPanel>
        )}
      </>
    );
}
