import { searchVGA } from "api/global";
import { useQuery } from "react-query";

export const useSearchGolfer = (id) =>
  useQuery(["search-vga", id], async () => {
    const res = await searchVGA({ vga_id: id });
    return res.error === 0 ? res.res : [];
  });

export const useEnabledSearchGolfer = (id) =>
  useQuery(["search-golfer", id], async () => {
    const res = await searchVGA({ vga_id: id });
    return res.error === 0 ? res.res : [];
  });
