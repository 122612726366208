import { Avatar, Box, Grow, Typography } from "@mui/material";
import { memo } from "react";
import { ReactComponent as MuteIcon } from "assets/svg/icon/mute.svg";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import styles from "assets/styles/pages/Chat/component/ViewerItem";
import PropTypes from "prop-types";
import { VALUE_ACTION_MESSAGE } from "constant/chat";
import { ReactComponent as LikeIcon } from "assets/svg/icon/emotion_like.svg";
import { ReactComponent as DislikeIcon } from "assets/svg/icon/emotion_dislike.svg";
import { ReactComponent as SadIcon } from "assets/svg/icon/emotion_sad.svg";
import { ReactComponent as FunIcon } from "assets/svg/icon/emotion_fun.svg";
import { ReactComponent as LoveIcon } from "assets/svg/icon/sub_emotion_love.svg";
import { formatNumber } from "utils/convert";
import { ReactComponent as TipIcon } from "assets/svg/icon/tip_icon.svg";

function ViewerItem({ information, mute, isSelected }) {
  return (
    <Box sx={(e) => styles.container(e, { isSelected })}>
      <Box sx={styles.informationContainer}>
        <Box sx={styles.avatarContainer}>
          <Avatar srcSet={information.avatar} sx={styles.avatar} src={defaultAvatar} />
        </Box>
        <Box sx={styles.information}>
          <Box sx={styles.nameContainer}>
            <Box sx={styles.infoUser}>
              <Typography variant="body1" color="initial" sx={styles.name}>
                {information.name} -
              </Typography>
              <Typography sx={styles.vID}> {information.vID}</Typography>
            </Box>

            <Grow in={Boolean(mute)} unmountOnExit>
              <MuteIcon />
            </Grow>
          </Box>
          {/* <Typography variant="caption" sx={styles.time}>
            {information.time}
          </Typography> */}
        </Box>
      </Box>
      <Box sx={styles.emotion}>
        {information.emotion === VALUE_ACTION_MESSAGE.LOVE && (
          <LoveIcon style={styles.emotionIcon} />
        )}
        {information.emotion === VALUE_ACTION_MESSAGE.FUN && <FunIcon style={styles.emotionIcon} />}
        {information.emotion === VALUE_ACTION_MESSAGE.LIKE && (
          <LikeIcon style={styles.emotionIcon} />
        )}
        {information.emotion === VALUE_ACTION_MESSAGE.DISLIKE && (
          <DislikeIcon style={styles.emotionIcon} />
        )}
        {information.emotion === VALUE_ACTION_MESSAGE.SAD && <SadIcon style={styles.emotionIcon} />}
        {information.tip && (
          <Box sx={styles.emotionTip}>
            <TipIcon style={styles.tipIcon} />
            <Typography sx={styles.tipCount} variant="body2">
              {formatNumber(information.tip)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

ViewerItem.defaultProps = {
  information: {
    avatar: "",
    name: "",
    emotion: "",
  },
};

ViewerItem.propTypes = {
  information: PropTypes.shape({
    avatar: PropTypes.any,
    name: PropTypes.string,
    emotion: PropTypes.string,
  }),
};

export default memo(ViewerItem);
