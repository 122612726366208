import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import styles from "assets/styles/pages/Chat/section/Header";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import { ReactComponent as FillInformationIcon } from "assets/svg/icon/fill_information.svg";
import { ReactComponent as InformationIcon } from "assets/svg/icon/information.svg";
// import { ReactComponent as MoreVertIcon } from "assets/svg/icon/more_vert.svg";
// import { ReactComponent as KenhTV } from "assets/svg/icon/kenh_tv.svg";
import KenhTV from "assets/images/chanel_tv.png";
import KenhTVBlue from "assets/images/chanel_tv_blue.png";
import KenhTVRed from "assets/images/chanel_tv_red.png";
// import { ReactComponent as SearchIcon } from "assets/svg/icon/#406EE2_search.svg";
import { ReactComponent as OpeningKenhTv } from "assets/svg/icon/opening-kenhtv.svg";

import { AdminNames, STATUS_SCORE } from "constant/chat";
import { useTranslation } from "react-i18next";
// import { useState } from "react";
import ChannelTV from "../component/ChannelTV";
import ChatActionSheet from "../component/ChatActionSheet";
import DeletePinMessageDialog from "../component/DeletePinMessageDialog";
import NotificationAddFriend from "../component/NotificationAddFriend";
import NotificationLoading from "../component/NotificationLoading";
import NotifyStatusPinMessage from "../component/NotifyStatusPinMessage";
import PinMessage from "../component/PinMessage";
import PinMessageDialog from "../component/PinMessageDialog";
import LoadingHeader from "../loading/Header";
// import InformationGroupDialog from "../component/InformationGroupDialog";

function Header({
  isMobileSize,
  onBack,
  openSetting,
  // anchor,
  // handleOpenHeaderPopper,
  handleCloseHeaderPopper,
  handleToggleInformation,
  isAddFriend,
  isBlockFriend,
  handleAddFriend,
  isOpenInformation,
  openPinMessage,
  openDialogDeletePinMessage,
  messagePinContent,
  handleDeletePinMessage,
  handleOpenDeletePinMessage,
  openDialogPinMessage,
  handleDialogPinMessage,
  handleOpenListMember,
  detailGroup,
  listPin,
  handleScrollPin,
  isFetchingNewGroup,
  userInformation,
  handleRemoveGroupChat,
  handleMuteGroupChat,
  openDialogNotifyStatusPin,
  handleOpenNotifyPin,
  handleOpenLoadingNotifyPin,
  openDialogNotifyLoadingStatusPin,
  openChanelTv,
  handleOpenChanelTv,
  listChanel,
  detailChanel,
  // setImageInforGroup,
  // setNameInforGroup,
  // setGroupPermissionInforGroup,
  // setIsPrivateInforGroup,
}) {
  // console.log("detetetet", detailGroup);
  const { t } = useTranslation();
  if (isFetchingNewGroup) return <LoadingHeader isOpenInformation={isOpenInformation} />;
  const getScore = () => {
    if (detailChanel) {
      const countBlue = detailChanel?.flight.score.filter(
        (item) => item.status && item.status === STATUS_SCORE.BLUE
      ).length;
      const countRed = detailChanel?.flight.score.filter(
        (item) => item.status && item.status === STATUS_SCORE.RED
      ).length;
      return { countBlue, countRed };
    }
    return null;
  };
  return (
    <AppBar sx={(e) => styles.appBar(e, { isOpenInformation })}>
      <Toolbar sx={styles.toolbar}>
        <Box sx={styles.informationContainer}>
          <Fade in={isMobileSize} unmountOnExit>
            <IconButton onClick={onBack} sx={styles.backButton}>
              <ArrowBackIcon style={styles.backIcon} />
            </IconButton>
          </Fade>
          <Avatar
            src={defaultAvatar}
            srcSet={detailGroup?.detail?.image || defaultAvatar}
            onClick={handleToggleInformation}
            sx={styles.avatar}
          />
          <Box>
            <Typography
              variant="body1"
              color="initial"
              sx={styles.name}
              onClick={handleToggleInformation}
            >
              {detailGroup?.detail?.name || ""}
            </Typography>

            {!AdminNames.includes(detailGroup?.group?.name) && (
              <Typography
                onClick={detailGroup?.detail?.type === "group" ? handleOpenListMember : () => {}}
                variant="body2"
                sx={() => styles.onlineTime({ isGroup: detailGroup?.detail?.type === "group" })}
              >
                {detailGroup?.detail?.type === "group"
                  ? `${detailGroup?.detail?.total_member} ${t("chat.header.member")}`
                  : detailGroup?.detail?.users?.find(
                      (element) => +element.id !== +userInformation.id
                    )?.last_state}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={styles.actionContainer}>
          {/* Search */}
          {detailGroup?.detail?.channel && (
            <IconButton sx={styles.iconKtv} onClick={handleOpenChanelTv}>
              {openChanelTv ? (
                <OpeningKenhTv style={styles.headerIcon} />
              ) : (
                <Box sx={styles.channelContainer}>
                  {detailChanel.is_endding_channel === 1 &&
                    getScore().countBlue === getScore().countRed && (
                      <>
                        <Box component="img" src={KenhTV} />
                        <Box sx={styles.scoreContainer}>
                          <Typography sx={styles.scoreRed} variant="body2">
                            A
                          </Typography>
                          <Typography sx={styles.scoreBlue} variant="body2">
                            S
                          </Typography>
                        </Box>
                      </>
                    )}
                  {detailChanel.is_endding_channel === 1 &&
                    getScore().countBlue > getScore().countRed && (
                      <>
                        <Box component="img" src={KenhTVBlue} />
                        <Box sx={styles.scoreContainer}>
                          <Typography sx={styles.TextScore} variant="caption">
                            {getScore().countBlue - getScore().countRed}
                          </Typography>
                          <Typography sx={styles.TextS} variant="caption">
                            &
                          </Typography>
                          <Typography sx={styles.TextHoleNone} variant="caption">
                            {
                              detailChanel?.flight.score.filter(
                                (item) => item.status === STATUS_SCORE.NONE
                              ).length
                            }
                          </Typography>
                        </Box>
                      </>
                    )}
                  {detailChanel.is_endding_channel === 1 &&
                    getScore().countBlue < getScore().countRed && (
                      <>
                        <Box component="img" src={KenhTVRed} />
                        <Box sx={styles.scoreContainer}>
                          <Typography sx={styles.TextScore} variant="caption">
                            {getScore().countRed - getScore().countBlue}
                          </Typography>
                          <Typography sx={styles.TextS} variant="caption">
                            &
                          </Typography>
                          <Typography sx={styles.TextHoleNone} variant="caption">
                            {
                              detailChanel?.flight.score.filter(
                                (item) => item.status === STATUS_SCORE.NONE
                              ).length
                            }
                          </Typography>
                        </Box>
                      </>
                    )}
                  {detailChanel.is_endding_channel !== 1 && (
                    <>
                      <Box component="img" src={KenhTV} />
                      <Box sx={styles.scoreContainer}>
                        <Typography sx={styles.scoreRed} variant="body2">
                          {getScore()?.countBlue}
                        </Typography>
                        <Typography sx={styles.scoreBlue} variant="body2">
                          {getScore()?.countRed}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </IconButton>
          )}
          {/* <IconButton disabled> */}
          {/*  <SearchIcon style={styles.headerIcon} /> */}
          {/* </IconButton> */}
          <IconButton sx={styles.icon} onClick={handleToggleInformation}>
            {isOpenInformation ? (
              <FillInformationIcon style={styles.headerIcon} />
            ) : (
              <InformationIcon style={styles.headerIcon} />
            )}
          </IconButton>
          {/* Setting */}
          {/* <IconButton onClick={handleOpenHeaderPopper}> */}
          {/*  <MoreVertIcon style={styles.headerIcon} /> */}
          {/* </IconButton> */}
          <ChatActionSheet
            detailGroup={detailGroup}
            handleMuteGroupChat={() => handleMuteGroupChat(detailGroup?.detail?.id)}
            handleRemoveGroupChat={() => handleRemoveGroupChat(detailGroup)}
            openSetting={openSetting}
            // anchor={anchor}
            handleClose={handleCloseHeaderPopper}
          />
        </Box>
      </Toolbar>
      <PinMessage
        handleScrollPin={handleScrollPin}
        listPin={listPin}
        messagePinContent={messagePinContent}
        openPinMessage={openPinMessage}
        openDialogPinMessage={openDialogPinMessage}
        handleDialogPinMessage={handleDialogPinMessage}
        handleOpenDeletePinMessage={handleOpenDeletePinMessage}
      />
      <PinMessageDialog
        handleScrollPin={handleScrollPin}
        listPin={listPin}
        handleOpenDeletePinMessage={handleOpenDeletePinMessage}
        openDialogPinMessage={openDialogPinMessage}
        handleDialogPinMessage={handleDialogPinMessage}
      />
      <DeletePinMessageDialog
        pinContent={messagePinContent}
        handleOpenDeletePinMessage={handleOpenDeletePinMessage}
        openDialogDeletePinMessage={openDialogDeletePinMessage}
        handleDeletePinMessage={handleDeletePinMessage}
      />
      <NotificationAddFriend
        isBlockFriend={isBlockFriend}
        isAddFriend={isAddFriend}
        handleAddFriend={handleAddFriend}
      />
      <NotifyStatusPinMessage
        openDialogNotifyStatusPin={openDialogNotifyStatusPin}
        handleOpenNotifyPin={handleOpenNotifyPin}
      />
      <NotificationLoading
        openDialogNotifyLoadingStatusPin={openDialogNotifyLoadingStatusPin}
        handleOpenLoadingNotifyPin={handleOpenLoadingNotifyPin}
      />
      <ChannelTV detailChanel={detailChanel} listChanel={listChanel} openChanelTv={openChanelTv} />
      {/* <InformationGroupDialog */}
      {/*  isOpen={openUpdateInforGroup} */}
      {/*  onClose={handleCloseUpdateInforGroup} */}
      {/*  detailGroup={detailGroup} */}
      {/* /> */}
    </AppBar>
  );
}

export default Header;
