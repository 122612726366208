import { addGroupChat } from "api/chat";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { encodeID } from "utils/hex";
import { getUserToken, setVIDMapChat } from "utils/storage";
import Box from "@mui/material/Box";
import { isEmpty } from "utils/validate";

function AddChatSingle() {
  const userInformation = getUserToken();
  const navigate = useNavigate();
  const { vID } = useParams();

  useEffect(() => {
    if (isEmpty(userInformation)) {
      setVIDMapChat(vID);
      navigate("/qr");
      return;
    }
    async function addChat() {
      let storeData = {};
      storeData = {
        type: "single",
        member_users: [userInformation?.id, vID],
        is_private: 0,
      };
      addGroupChat(storeData).then((res) => {
        if (+res.error !== 0) return;
        navigate(`/t/${encodeID(res?.group?.id)}`);
      });
    }
    addChat();
  }, [vID]);
  return <Box />;
}
export default AddChatSingle;
