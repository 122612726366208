import Box from "@mui/material/Box";
import styles from "assets/styles/pages/Chat/loading/Footer";
import { Skeleton } from "@mui/material";

export default function LoadingFooter() {
  return (
    <Box>
      <Box>
        <Box sx={styles.container}>
          <Box sx={styles.flexBoxRight}>
            <Box sx={styles.listIcon}>
              <Box sx={styles.positionCenter}>
                <Skeleton variant="circular" sx={styles.actionIcon} />
                <Skeleton variant="circular" sx={styles.actionIcon} />
                <Skeleton variant="circular" sx={styles.actionIcon} />
              </Box>
            </Box>
            <Box sx={styles.flexGrowResponsive}>
              <Skeleton variant="text" sx={styles.input} />
            </Box>
          </Box>
          <Box sx={styles.boxRight}>
            <Box sx={styles.flexEnd}>
              <Skeleton variant="circular" sx={styles.sendButton} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
